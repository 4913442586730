import React, { useState } from 'react'
import Advancedropdownwithdiscription from '../../components/Dropdowns/Advancedropdownwithdiscription'
const AdvanceDDwithDIs = () => {
	const [lists, setLists] = useState([])
	const [items, setItems] = useState([
		{
			id: 'bg-red',
			color: 'white',
			class: 'suggestions-item bg-red',
			h6: '(A0001) SAP ERP',
			p: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo'
		},
		{
			id: 'bg-yellow',
			color: 'black',
			class: 'suggestions-item bg-yellow',
			h6: '(A0002) GRC Toolbox',
			p: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
		},
		{
			id: 'bg-nephritis',
			color: 'white',
			class: 'suggestions-item bg-nephritis',
			h6: '(A0003) Dynamics 365 CRM',
			p: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam'
		},
		{
			id: 'bg-black',
			color: 'white',
			class: 'suggestions-item bg-black',
			h6: '(A0004) CMDB SHW00X',
			p: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt'
		}
	])

	const Select = (item) => {
		// console.log(item)
		setLists([...lists, item])
		setItems(items.filter((items) => items.id !== item.id))
	}

	const removeItem = (item) => {
		console.log(item)
		setLists(lists.filter((i) => i.id !== item.id))
		setItems([...items, item])
	}
	return (
		<Advancedropdownwithdiscription
			labelName={'Advanced Dropdown With Without Description'}
			lists={lists}
			items={items}
			onItemsSelect={Select}
			onItemsRemove={removeItem}
		/>
	)
}

export default AdvanceDDwithDIs

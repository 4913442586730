import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const SimpleDropDown = ({
	label = '',
	options,
	onChange,
	placeholder = 'Select',
	value = '',
	id = '',
	Margin,
	className
}) => {
	const DropdownRef = useRef()
	const [showItems, setShowitems] = useState(false)
	const [filteredItems, setFilteredItems] = useState([])

	const HandleshowItems = () => {
		setShowitems((prev) => !prev)
	}
	// pass the select item to parent component
	const handleValue = (name) => {
		// console.log(name)
		onChange(name)
		setFilteredItems([])
		setShowitems(false)
	}
	// start searching on user input
	const filterNames = (event) => {
		const inputValue = event.target.value
		if (inputValue.length === 0) {
			setFilteredItems([])
		} else {
			setFilteredItems([...options])
		}
	}
	// close dropdown on outside click
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				DropdownRef.current &&
				!DropdownRef.current.contains(event.target)
			) {
				setShowitems(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])
	// paas the userinput to parent component
	const HandleInputChange = (e) => {
		onChange(e.target.value)
		filterNames(e)
	}

	return (
		<div
			className={`form-group relative ${className}`}
			style={Margin === 0 ? { margin: 0 } : null}
		>
			{label && <label className="label">{label}</label>}
			<div className="input-outer">
				<div className="dropsearch" ref={DropdownRef}>
					<div className="dropsearchinput">
						<input
							id={id}
							type="text"
							placeholder={placeholder}
							value={value}
							className="form-control searchInput"
							onChange={HandleInputChange}
							onKeyDown={filterNames}
							onClick={HandleshowItems}
							autoComplete="off"
						/>
						<FontAwesomeIcon
							icon={faAngleDown}
							className="drop-icons"
							onClick={HandleshowItems}
						/>
					</div>
					{showItems && (
						<div className="listbox multiple-range absolute">
							<ul>
								{filteredItems.length === 0 &&
									options.map((list) => (
										<li
											key={list}
											onClick={() => handleValue(list)}
											className="text-[14px]"
										>
											{list}
										</li>
									))}
								{filteredItems.length !== 0 &&
									filteredItems
										.filter((list) =>
											list
												.toLowerCase()
												.includes(value.toLowerCase())
										)
										.map((filteredItem) => (
											<li
												key={filteredItem}
												onClick={() =>
													handleValue(filteredItem)
												}
												className="text-[14px]"
											>
												{filteredItem}
											</li>
										))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default SimpleDropDown

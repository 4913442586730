import React, { useState } from 'react'
import parse from 'html-react-parser'
import IconButton from '../Buttons/Icon-button'
import { faPlus, faClose } from '@fortawesome/free-solid-svg-icons'
export default function ModalAuto({ ...props }) {
	const [extraContent, setExtraContent] = useState('')
	const addComponent = () => {
		setExtraContent(
			extraContent + '<div className="text-sm mr-3">ABC</div>'
		)
	}
	return (
		<>
			<div
				className={`fixed left-2/4 top-2/4 z-[1002] w-auto max-w-[80%]  -translate-x-2/4 -translate-y-2/4 ${props.extraClass ? 'opacity-100' : 'opacity-0'}`}
				style={{
					transitionProperty: 'opacity',
					transitionDuration: '.1s',
					transitionTimingFunction: 'linear'
				}}
			>
				<div
					className={`relative flex flex-col rounded-sm border-0 bg-white shadow-lg outline-none focus:outline-none`}
				>
					<div className="px-4 pt-1">
						<div className="modal-header  box-border ">
							<div className="flex items-center justify-between  border-b">
								<h5 className="">{props.title}</h5>
								<IconButton
									type="button"
									className="relative flex h-5 w-6  items-center justify-center rounded-sm bg-transparent text-sm text-gray-400 hover:bg-transparent hover:text-gray-500"
									onClick={() => props.onClose()}
									children=""
									Icon={faClose}
									size={'lg'}
								/>
							</div>
						</div>
					</div>
					<div
						className="modal-body p-4"
						style={
							props.scrollable
								? { overflowY: 'scroll', maxHeight: '327px' }
								: {}
						}
					>
						<div className="relative flex-auto">
							{parse(props.body)}
						</div>
						{extraContent !== '' ? (
							<div className="flex overflow-auto py-2">
								{parse(extraContent)}
							</div>
						) : null}
					</div>
					{/* <div className="modal-footer p-4">
                    <div className="flex items-center justify-end">
                        <Button className="btn btn-primary btn-large" type="button" children="Add Component" onClick={() => addComponent()}></Button>
                    </div>
                </div> */}

					<div className="px-4  ">
						<div className="modal-footer flex  items-center justify-between border-t">
							<h5 className="font-normal">Additional Text</h5>
							<div className="flex items-center justify-end gap-[3px]">
								<IconButton
									Icon={faPlus}
									type="button"
									className="btn btn-primary btn-large flex gap-1.5"
									children="Add Component"
									onClick={() => addComponent()}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

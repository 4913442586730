import React, { useState, useRef } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
const CropImage = ({ image, cropedImage }) => {
	const [cropData, setCropData] = useState('')
	const cropperRef = useRef(null)
	// create a url of cropped image
	const onCrop = () => {
		const cropper = cropperRef.current?.cropper
		setCropData(cropper.getCroppedCanvas().toDataURL())
	}

	return (
		<div
			className="flex h-[248px] w-[200px] flex-col 
  items-center justify-between  gap-2"
		>
			<div className="h-[200px] w-[200px]  bg-[#f8f8f3] ">
				<Cropper
					ref={cropperRef}
					style={{ height: '100%', width: '100%' }}
					initialAspectRatio={1}
					src={image}
					viewMode={1}
					minCropBoxHeight={5}
					minCropBoxWidth={5}
					background={false}
					responsive={true}
					autoCropArea={1}
					checkOrientation={false}
					guides={true}
					crop={onCrop}
				/>
			</div>
			<div className="w-full ">
				<button
					className="btn btn-large btn-primary w-full  "
					onClick={() => cropedImage(cropData)}
				>
					Crop Image
				</button>
			</div>
		</div>
	)
}
export default CropImage

import React from 'react'
const FormHeader = ({ title, message }) => {
	return (
		<div className="px-[30px] pt-[30px]">
			<a href="../" className=" header-logo mb-8">
				Risk Wise
			</a>
			<h4 className="">{title}</h4>
			<p className=" ">{message}.</p>
			<div className="mt-1.5 h-4">
				<div className="alert alert-success hidden">
					A simple success alert—check it out!
				</div>
				<div className="alert alert-danger hidden">
					A simple danger alert—check it out!
				</div>
			</div>
		</div>
	)
}
export default FormHeader

import React from 'react'
import Accordion from '../../components/ui/Accordion/Accordion'

const AccordionDemo = () => {
	const component = (
		<>
			<p>
				Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia
				et aut placeat! Ad consectetur nam tenetur exercitationem
				reprehenderit excepturi natus rerum eveniet rem odit sequi
				ipsum, aliquam at consequatur in.
			</p>
			<p>
				Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia
				et aut placeat! Ad consectetur nam tenetur exercitationem
				reprehenderit excepturi natus rerum eveniet rem odit sequi
				ipsum, aliquam at consequatur in.
			</p>
			<p>
				Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia
				et aut placeat! Ad consectetur nam tenetur exercitationem
				reprehenderit excepturi natus rerum eveniet rem odit sequi
				ipsum, aliquam at consequatur in.
			</p>
			<p>
				Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia
				et aut placeat! Ad consectetur nam tenetur exercitationem
				reprehenderit excepturi natus rerum eveniet rem odit sequi
				ipsum, aliquam at consequatur in.
			</p>
		</>
	)

	const component2 = (
		<>
			<p>
				Lorem ipsum, dolor sit amet consectetur adipisicing elit.
				Voluptatibus debitis, ad obcaecati ipsa vel beatae accusantium
				quia ipsum cumque! Officia, vero minus eaque rem quaerat
				praesentium rerum numquam ratione sit?
			</p>
			<p>
				Lorem ipsum, dolor sit amet consectetur adipisicing elit.
				Voluptatibus debitis, ad obcaecati ipsa vel beatae accusantium
				quia ipsum cumque! Officia, vero minus eaque rem quaerat
				praesentium rerum numquam ratione sit?
			</p>

			<p>
				Lorem ipsum, dolor sit amet consectetur adipisicing elit.
				Voluptatibus debitis, ad obcaecati ipsa vel beatae accusantium
				quia ipsum cumque! Officia, vero minus eaque rem quaerat
				praesentium rerum numquam ratione sit?
			</p>
			<p>
				Lorem ipsum, dolor sit amet consectetur adipisicing elit.
				Voluptatibus debitis, ad obcaecati ipsa vel beatae accusantium
				quia ipsum cumque! Officia, vero minus eaque rem quaerat
				praesentium rerum numquam ratione sit?
			</p>

			<p>
				Lorem ipsum, dolor sit amet consectetur adipisicing elit.
				Voluptatibus debitis, ad obcaecati ipsa vel beatae accusantium
				quia ipsum cumque! Officia, vero minus eaque rem quaerat
				praesentium rerum numquam ratione sit?
			</p>
			<p>
				Lorem ipsum, dolor sit amet consectetur adipisicing elit.
				Voluptatibus debitis, ad obcaecati ipsa vel beatae accusantium
				quia ipsum cumque! Officia, vero minus eaque rem quaerat
				praesentium rerum numquam ratione sit?
			</p>
		</>
	)
	return (
		<Accordion
			labelName={'Accordions'}
			Component1={component}
			Component2={component2}
			Component3={component}
			Component4={component2}
		/>
	)
}

export default AccordionDemo

import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthLayout from '../../Auth-Layout'
import AlertWrapper from '../../components/ui/Alerts/AlertWrapper'
import FormHeader from '../../components/Common/FormHeader'
import FormBody from '../../components/Common/FormBody'
import FormFooter from '../../components/Common/FormFooter'

import TextBox from '../../components/ui/TextBox'
import Button from '../../components/ui/Buttons/Button'
import Icon from '../../components/ui/icon'
import Alert from '../../components/ui/Alerts'

import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'

const Forgot = () => {
	const navigate = useNavigate()
	const [email, setEmail] = useState(null)

	const [notifications, setNotifications] = useState([])
	const HandleChange = (data) => {
		setEmail(data)
	}
	const addNotification = (type, message, icon) => {
		setNotifications((prevNotifications) => [
			{ id: new Date().getTime(), type, message, icon },
			...prevNotifications
		])
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		if (!email) {
			addNotification(
				'danger',
				'Please enter your email address.',
				faTriangleExclamation
			)
		} else {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			let isValid = emailRegex.test(email)
			if (!isValid) {
				addNotification(
					'danger',
					'Please enter valid email address.',
					faTriangleExclamation
				)
			} else {
				addNotification(
					'success',
					'We send reset password link in your registered email address.',
					faCheck
				)
				setTimeout(() => {
					navigate('/')
				}, 3000)
			}
		}
	}

	return (
		<>
			<AlertWrapper>
				{notifications
					.sort((a, b) => b.id - a.id)
					.map((notification, index) => (
						<div key={notification.id}>
							<Alert
								id={index + 1}
								className={`alert-${notification.type}`}
								message={notification.message}
								Icon={notification.icon}
								autoClose={3000}
								onClose={() => {
									setNotifications((prevNotifications) =>
										prevNotifications.filter(
											(n) => n.id !== notification.id
										)
									)
								}}
							/>
						</div>
					))}
			</AlertWrapper>
			<AuthLayout>
				<FormHeader
					title="Forget Password"
					message="Welcome! Please use below form to reset your account."
				/>
				<FormBody>
					<TextBox
						labelName={'Email Address'}
						type={'email'}
						className={'mb-4'}
						onChange={HandleChange}
					/>

					<div className="mb-4">
						<p className="small-text text-[#6e7985] ">
							By clicking <strong>Reset</strong> below, you agree
							to our Terms and Conditions and Privacy Policy.
						</p>
					</div>
					<Button
						className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
						isIconBtn={true}
						onClick={handleSubmit}
					>
						<Icon
							name="line-lock"
							width={'16px'}
							height={'16px'}
							className={'icon-fill-white'}
							viewBox={'0 0 24 24'}
						/>
						Forgot Password
					</Button>
				</FormBody>
				<FormFooter
					message1="Need assistance or have questions"
					linktext1="Contact Support"
					link1="contact-us"
					message2="Don't have an account"
					linktext2="Create an Account"
					link2="register"
				/>
			</AuthLayout>
		</>
	)
}
export default Forgot

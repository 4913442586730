import React from 'react'
import BannerPolicy from './BannerPolicy'
import Policies from './Policies'

export default function Policy() {
	return (
		<>
			<div
				class="page-sign block h-screen py-0"
				style={{
					backgroundImage:
						'linear-gradient(to right, #0F2C59 40%, #2747b5 100%)'
				}}
			>
				<div class="flex h-full">
					<BannerPolicy />
					<Policies />
				</div>
			</div>
		</>
	)
}

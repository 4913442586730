import React from 'react'
import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import FormHeader from '../../components/Common/FormHeader'
import FormBody from '../../components/Common/FormBody'
import Formfooter from '../../components/Common/FormFooter'

import AuthLayout from '../../Auth-Layout'
import AlertWrapper from '../../components/ui/Alerts/AlertWrapper'
import TextBox from '../../components/ui/TextBox'
import Button from '../../components/ui/Buttons/Button'
import IconButton from '../../components/ui/Buttons/Icon-button'
import Icon from '../../components/ui/icon'
import Alert from '../../components/ui/Alerts'

import { faCheck, faLink } from '@fortawesome/free-solid-svg-icons'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

const Login = () => {
	const [notifications, setNotifications] = useState([])
	const navigate = useNavigate()
	const [userData, setUserData] = useState({
		email: null,
		password: null
	})
	const addNotification = (type, message, icon) => {
		setNotifications((prevNotifications) => [
			{ id: new Date().getTime(), type, message, icon },
			...prevNotifications
		])
	}
	const HandleChange = (key, value) => {
		setUserData((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const HandleSubmit = (event) => {
		event.preventDefault()
		const PSW_REGEXP =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
		const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		let isValidEmail = EMAIL_REGEXP.test(userData.email)
		let isValidPassword = PSW_REGEXP.test(userData.password)
		if (
			!userData.email ||
			!isValidEmail ||
			!userData.password ||
			!isValidPassword
		) {
			if (!userData.email && !userData.password) {
				addNotification(
					'danger',
					'Please enter valid credentials.',
					faTriangleExclamation
				)
			} else if (!userData.email) {
				addNotification(
					'danger',
					'Please enter your email address.',
					faTriangleExclamation
				)
			} else if (!userData.password) {
				addNotification(
					'danger',
					'Please enter your password.',
					faTriangleExclamation
				)
			} else if (!isValidEmail) {
				addNotification(
					'danger',
					'Please enter valid email address.',
					faTriangleExclamation
				)
			} else if (!isValidPassword) {
				addNotification(
					'danger',
					'Please enter a valid credentials.',
					faTriangleExclamation
				)
			} else {
				addNotification(
					'danger',
					'Please enter valid credentials.',
					faTriangleExclamation
				)
			}
		} else {
			addNotification(
				'success',
				'Congratulations! You are login successfully.',
				faCheck
			)
			localStorage.setItem('email', userData.email)
			setTimeout(() => {
				navigate('/users')
			}, 3000)
		}
	}
	return (
		<>
			<AlertWrapper>
				{notifications
					.sort((a, b) => b.id - a.id)
					.map((notification, index) => (
						<div key={notification.id}>
							<Alert
								id={index + 1}
								autoClose={3000}
								className={`alert-${notification.type}`}
								message={notification.message}
								Icon={notification.icon}
								onClose={() => {
									setNotifications((prevNotifications) =>
										prevNotifications.filter(
											(n) => n.id !== notification.id
										)
									)
								}}
							/>
						</div>
					))}
			</AlertWrapper>
			<AuthLayout>
				<FormHeader
					title="Sign In"
					message="Welcome! Please signin to continue."
				/>
				<FormBody>
					<TextBox
						onChange={(data) => HandleChange('email', data)}
						labelName="Email Address"
						type={'text'}
						className={'mb-4'}
						value={userData.email}
					/>
					<div className="relative">
						<Link
							to="/pages/forgot-password"
							className="absolute right-0 top-[5px] text-sm text-[#C70039]"
						>
							Forgot Password
						</Link>
						<TextBox
							type="password"
							labelName="Password"
							className={'mb-4'}
							onChange={(data) => HandleChange('password', data)}
							value={userData.password}
						/>
					</div>

					<div className="mb-3">
						<p className="small-text">
							By clicking <strong>Sign In</strong> below, you
							agree to our Terms and Conditions and Privacy
							Policy.
						</p>
					</div>

					<Button
						className="btn-large btn-primary btn-all flex  w-full gap-2"
						isIconBtn={true}
						onClick={HandleSubmit}
					>
						<Icon
							name="line-signup"
							width={'20px'}
							height={'20px'}
							className={'icon-fill-white'}
							viewbox={'0 0 24 24'}
						/>{' '}
						Sign In
					</Button>

					{/* <div className="divider">
						<span>or sign in with</span>
					</div>

					<div className="flex gap-1">
						<div className="w-[50%]">
							<Link to={'/pages/magiclink'}>
								<IconButton
									className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
									children={'Magic Link'}
									Icon={faLink}
								/>
							</Link>
						</div>

						<div className="w-[50%]">
							<Link to={'/pages'}>
								<Button
									className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
									isIconBtn={true}
								>
									<Icon
										name="line-phone-lock"
										width={'16px'}
										height={'16px'}
										className={'icon-fill-white'}
										viewbox={'0 0 24 24'}
									/>{' '}
									OTP
								</Button>
							</Link>
						</div>
					</div> */}

				</FormBody>

				<Formfooter
					message2="Need assistance or have questions"
					linktext2="Contact Support"
					link2="contact-us"
					message1="Don't have an account"
					linktext1="Create an Account"
					link1="/pages/register"
				/>
			</AuthLayout>
		</>
	)
}
export default Login

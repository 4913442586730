import React, { useState } from 'react'
import PhoneSelector from '../../components/ui/PhoneSelector'

const Phoneselectr = () => {
	const [selectedCountry, setSelectedCountry] = useState({
		countryCode: '+91',
		countryName: 'india',
		countryFlag: 'in',
		Number: ''
	})
	const UpdateFields = (obj) => {
		setSelectedCountry({
			...selectedCountry,
			countryCode: obj.countryCode,
			countryName: obj.countryName,
			countryFlag: obj.countryFlag
		})
	}
	const setuserNumber = (obj) => {
		setSelectedCountry({
			...selectedCountry,
			Number: obj.Number
		})
	}
	return (
		<>
			<PhoneSelector
				labelName={'Phone Number'}
				currentCountry={selectedCountry}
				onCountrySelect={(obj) => UpdateFields(obj)}
				onUSerInputChange={(obj) => setuserNumber(obj)}
			/>
		</>
	)
}

export default Phoneselectr

import React, { useState } from 'react'
import Icon from '../../components/ui/icon'
const Header = ({ onIdChange }) => {
	const [isActive, setisActive] = useState('01')
	const HEADER_DATA = [
		{
			id: '01',
			icon: 'line-profile-card',
			name: 'Personal Information'
		},
		{
			id: '02',
			icon: 'line-brief-case',
			name: 'Business Information'
		},
		{
			id: '03',
			icon: 'social-window',
			name: 'Social Information'
		},
		{
			id: '04',
			icon: 'line-add-square',
			name: 'Additional Information'
		}
	]
	const HandleClick = (id) => {
		setisActive(id)
		onIdChange(id)
		// console.log(id)
	}
	return (
		<>
			<header className="flex min-h-[47px] items-center  justify-between bg-white p-0 shadow-md">
				<ul className="profile-header m-0 flex flex-wrap p-0">
					{HEADER_DATA.map((items) => {
						return (
							<li
								className={`none relative m-0 flex cursor-pointer flex-wrap items-center px-[15px] py-3 text-sm font-semibold text-[#333333] ${isActive === items.id ? 'active' : ''}`}
								onClick={() => HandleClick(items.id)}
							>
								<div className="mr-2.5 flex h-6 w-6 items-center justify-center rounded-[50%] border border-solid border-[#e2e5ec]">
									<Icon
										name={items.icon}
										width={'14px'}
										height={'14px'}
										className="icon-fill-grey200"
										viewbox={'0 0 23 23'}
									/>
								</div>{' '}
								<span>{items.name}</span>
							</li>
						)
					})}
				</ul>
			</header>
		</>
	)
}

export default Header

import React, { useState } from 'react'
import CropImage from './CropImage'
import SelectImage from './SelectImage'
const ImageEditor = ({ labelName, onImageCrop }) => {
	const [selectedImage, setSelectedImage] = useState(null)
	const [cropedImage, setCropedImage] = useState(null)
	// get the uploaded image from SelectImage component,store it in selectedImage state and create its URL
	const handleInputImageChange = (e) => {
		const file = e.target.files[0]
		if (file) {
			const reader = new FileReader()
			reader.onloadend = () => {
				setSelectedImage(reader.result)
			}
			reader.readAsDataURL(file)
		}
	}
	// get the cropped image from CropImage component and store it to the cropedImage state
	const cropHandler = (image) => {
		setCropedImage(image)
		onImageCrop(image)
	}
	return (
		<>
			<div className="form-group">
				{labelName && <label className="label">{labelName}</label>}
				<div className="input-outer">
					<div className="flex flex-wrap gap-3">
						<SelectImage
							onchange={handleInputImageChange}
							image={cropedImage}
						/>
						<CropImage
							image={selectedImage}
							cropedImage={cropHandler}
						/>
					</div>
				</div>
			</div>
		</>
	)
}
export default ImageEditor

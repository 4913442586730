import React from 'react'
import {
	faTrash,
	faEdit,
	faSave,
	faHeart
} from '@fortawesome/free-solid-svg-icons'
import List from '../../components/ui/List'

const FontPage = () => {
	// Static List Items
	const ListItems = [
		{
			text: 'FIRST'
		},
		{
			text: 'SECOND'
		},
		{
			text: 'THIRD'
		}
	]
	const ListItems__Tick = [
		{
			text: 'FIRST',
			type: 'tick'
		},
		{
			text: 'SECOND',
			type: 'tick'
		},
		{
			text: 'THIRD',
			type: 'tick'
		}
	]
	const ListItems__Cross = [
		{
			text: 'FIRST',
			type: 'cross'
		},
		{
			text: 'SECOND',
			type: 'cross'
		},
		{
			text: 'THIRD',
			type: 'cross'
		}
	]
	const ListItems__Icon = [
		{
			text: 'DELETE',
			type: 'icon',
			icon: faTrash
		},
		{
			text: 'EDIT',
			type: 'icon',
			icon: faEdit
		},
		{
			text: 'SAVE',
			type: 'icon',
			icon: faSave
		},
		{
			text: 'LIKE',
			type: 'icon',
			icon: faHeart
		}
	]

	return (
		<div className="p-[30px]">
			<div className="">
				<p className="small-text">TYPEFACES</p>
				<h3>TYPOGRAPHY SCALE</h3>
				<hr />

				{/* Heading H1 */}
				<div className="flex flex-col border-b">
					<h1>The quick brown fox jumps over the lazy dog</h1>
					<div className="mb-3 flex justify-between">
						<h5>
							{'<h1>'} <span className="small-text">OR</span> .h1
						</h5>
						<p>44px/50px Letter-spacing: -0.0141279em</p>
					</div>
				</div>

				{/* Heading H2 */}
				<div className="flex flex-col border-b">
					<h2>The quick brown fox jumps over the lazy dog</h2>
					<div className="mb-3 flex justify-between">
						<h5>
							{'<h2>'} <span className="small-text">OR</span> .h2
						</h5>
						<p>30px/38px Letter-spacing: -0.021em</p>
					</div>
				</div>

				{/* Heading H3 */}
				<div className="flex flex-col border-b">
					<h3>The quick brown fox jumps over the lazy dog</h3>
					<div className="mb-3 flex justify-between">
						<h5>
							{'<h3>'} <span className="small-text">OR</span> .h3
						</h5>
						<p>27px/35px Letter-spacing: -0.0114923em</p>
					</div>
				</div>

				{/* Heading H4 */}
				<div className="flex flex-col border-b">
					<h4>The quick brown fox jumps over the lazy dog</h4>
					<div className="mb-3 flex justify-between">
						<h5>
							{'<h4>'} <span className="small-text">OR</span> .h4
						</h5>
						<p>22px/32px Letter-spacing: -0.00865734em</p>
					</div>
				</div>

				{/* Heading H5 */}
				<div className="flex flex-col border-b">
					<h5>The quick brown fox jumps over the lazy dog</h5>
					<div className="mb-3 flex justify-between">
						<h5>
							{'<h5>'} <span className="small-text">OR</span> .h5
						</h5>
						<p>18px/26px Letter-spacing: -0.00630069em</p>
					</div>
				</div>

				{/* Paragraph */}
				<div className="flex flex-col border-b">
					<p className="mt-5">
						Lorem ipsum dolor sit amet, consectetur adipisicing
						elit. Asperiores saepe mollitia exercitationem
						praesentium corrupti, nemo autem cum ullam voluptatibus.
						Earum dolorum ex porro facilis blanditiis nam quasi
						obcaecati, id inventore! Lorem ipsum dolor sit amet,
						consectetur adipisicing elit. Asperiores saepe mollitia
						exercitationem praesentium corrupti, nemo autem cum
						ullam voluptatibus. Earum dolorum ex porro facilis
						blanditiis nam quasi obcaecati, id inventore! Lorem
						ipsum dolor sit amet, consectetur adipisicing elit.
						Asperiores saepe mollitia exercitationem praesentium
						corrupti, nemo autem cum ullam voluptatibus. Earum
						dolorum ex porro facilis blanditiis nam quasi obcaecati,
						id inventore!
					</p>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing
						elit. Asperiores saepe mollitia exercitationem
						praesentium corrupti, nemo autem cum ullam voluptatibus.
						Earum dolorum ex porro facilis blanditiis nam quasi
						obcaecati, id inventore! Lorem ipsum dolor sit amet,
						consectetur adipisicing elit. Asperiores saepe mollitia
						exercitationem praesentium corrupti, nemo autem cum
						ullam voluptatibus. Earum dolorum ex porro facilis
						blanditiis nam quasi obcaecati, id inventore! Lorem
						ipsum dolor sit amet, consectetur adipisicing elit.
						Asperiores saepe mollitia exercitationem praesentium
						corrupti, nemo autem cum ullam voluptatibus. Earum
						dolorum ex porro facilis blanditiis nam quasi obcaecati,
						id inventore! Lorem ipsum dolor sit amet, consectetur
						adipisicing elit. Asperiores saepe mollitia
						exercitationem praesentium corrupti, nemo autem cum
						ullam voluptatibus. Earum dolorum ex porro facilis
						blanditiis nam quasi obcaecati, id inventore!
					</p>
					<div className="mb-3 flex justify-between">
						<h5>Paragraph</h5>
						<p>14px/21px Letter-spacing: 0.00177646em</p>
					</div>
				</div>

				{/* Small Text */}
				<div className="flex flex-col border-b">
					<p className="small-text mt-5">
						Lorem ipsum dolor sit amet, consectetur adipisicing
						elit. Asperiores saepe mollitia exercitationem
						praesentium corrupti, nemo autem cum ullam voluptatibus.
						Earum dolorum ex porro facilis blanditiis nam quasi
						obcaecati, id inventore! Lorem ipsum dolor sit amet,
						consectetur adipisicing elit. Asperiores saepe mollitia
						exercitationem praesentium corrupti, nemo autem cum
						ullam voluptatibus. Earum dolorum ex porro facilis
						blanditiis nam quasi obcaecati, id inventore! Lorem
						ipsum dolor sit amet, consectetur adipisicing elit.
						Asperiores saepe mollitia exercitationem praesentium
						corrupti, nemo autem cum ullam voluptatibus. Earum
						dolorum ex porro facilis blanditiis nam quasi obcaecati,
						id inventore!
					</p>
					<div className="mb-3 flex justify-between">
						<h5>.small-text</h5>
						<p>12px/18px Letter-spacing: 0.00849077em</p>
					</div>
				</div>

				{/* Smaller Text */}
				<div className="flex flex-col border-b">
					<p className="smaller-text mt-5">
						Lorem ipsum dolor sit amet, consectetur adipisicing
						elit. Asperiores saepe mollitia exercitationem
						praesentium corrupti, nemo autem cum ullam voluptatibus.
						Earum dolorum ex porro facilis blanditiis nam quasi
						obcaecati, id inventore! Lorem ipsum dolor sit amet,
						consectetur adipisicing elit. Asperiores saepe mollitia
						exercitationem praesentium corrupti, nemo autem cum
						ullam voluptatibus. Earum dolorum ex porro facilis
						blanditiis nam quasi obcaecati, id inventore! Lorem
						ipsum dolor sit amet, consectetur adipisicing elit.
						Asperiores saepe mollitia exercitationem praesentium
						corrupti, nemo autem cum ullam voluptatibus. Earum
						dolorum ex porro facilis blanditiis nam quasi obcaecati,
						id inventore!
					</p>
					<div className="mb-3 flex justify-between">
						<h5>.smaller-text</h5>
						<p>10px/16px Letter-spacing: 0.00849077em</p>
					</div>
				</div>

				{/* Highlighet Text */}
				<div className="flex flex-col border-b">
					<p className="mt-5">
						Lorem ipsum dolor sit amet, consectetur adipisicing
						elit. Asperiores saepe mollitia exercitationem
						praesentium corrupti, nemo autem cum ullam voluptatibus.
						Earum dolorum ex porro facilis blanditiis nam quasi
						obcaecati, id inventore! Lorem ipsum dolor sit amet,
						consectetur adipisicing elit. Asperiores saepe mollitia
						exercitationem praesentium corrupti, nemo autem cum
						ullam voluptatibus. Earum dolorum{' '}
						<span className="highlighted-text">
							ex porro facilis blanditiis nam quasi
						</span>{' '}
						obcaecati, id inventore! Lorem ipsum dolor sit amet,
						consectetur adipisicing elit. Asperiores saepe mollitia
						exercitationem praesentium corrupti, nemo autem cum
						ullam voluptatibus. Earum dolorum ex porro facilis
						blanditiis nam quasi obcaecati, id inventore!
					</p>
					<div className="mb-3 flex justify-between">
						<h5>.highlighted-text</h5>
						<p></p>
					</div>
				</div>
			</div>
			<hr className="border-black" />

			<div className="grid grid-cols-5">
				<div className="col-span-1">
					<List
						items={ListItems}
						title="Unordered List"
						description="This is an Unordered List Component."
						listType="ul"
					/>
				</div>

				<div className="col-span-1">
					<List
						items={ListItems}
						title="Ordered List"
						description="This is an Unordered List Component."
						listType="ol"
					/>
				</div>

				<div className="col-span-1">
					<List
						items={ListItems__Tick}
						title="Tick List"
						description="This is a List with Ticks."
						listType="tick"
					/>
				</div>

				<div className="col-span-1">
					<List
						items={ListItems__Cross}
						title="Cross List"
						description="This is a List with Cross."
						listType="cross"
					/>
				</div>

				<div className="col-span-1">
					<List
						items={ListItems__Icon}
						title="Icons List"
						description="This is a List with Icons."
						listType="icon"
					/>
				</div>
			</div>
		</div>
	)
}

export default FontPage

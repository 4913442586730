import React, { useState, useEffect, useLayoutEffect } from 'react'
import Drawer from '../../components/ui/Drawer'
import TextBox from '../../components/ui/TextBox'
import DatePicker from '../../components/ui/Datepicker'
import ElasticTextBox from '../../components/ui/Elastic-textbox'
import IconButton from '../../components/ui/Buttons/Icon-button'
import CountrySelector from '../../components/ui/Countryselctor'
import PhoneSelector from '../../components/ui/PhoneSelector'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
const BusinessinfoDrawer = ({ show, setShow }) => {
	const [showDrawer, setShowDrawer] = useState(false)
	const [drawerPosition, setDrawerPosition] = useState('right')
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(false)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const [currentDate, setCurrentDate] = useState(null)
	const [Formdata, setFormdata] = useState({
		title: '',
		fullname: '',
		dob: '',
		age: ''
	})

	const [selectedCountry, setSelectedCountry] = useState({
		countryCode: '+91',
		countryName: 'india',
		countryFlag: 'in',
		Number: ''
	})
	const UpdateFields = (obj) => {
		setSelectedCountry({
			...selectedCountry,
			countryCode: obj.countryCode,
			countryName: obj.countryName,
			countryFlag: obj.countryFlag
		})
	}
	const setuserNumber = (obj) => {
		setSelectedCountry({
			...selectedCountry,
			Number: obj.Number
		})
	}

	const HANDLEFORMDATA = (field, value) => {
		console.log({ field, value })
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const HandleDateChange = (date) => {
		setCurrentDate(date)
	}
	useLayoutEffect(() => {
		setBody(FORM)
	}, [show])
	const FORM = (
		<>
			<div className="flex w-[350px] flex-col justify-start pr-1">
				<TextBox
					labelName={'Employee ID'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>
				<TextBox
					labelName={'Grade'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>

				<TextBox
					labelName={'Designation'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>

				<TextBox
					labelName={'Role'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>

				<DatePicker
					labelName={'Date of joining'}
					onDateChange={(date) => HandleDateChange(date)}
					currentDate={currentDate}
				/>

				<TextBox
					labelName={'Clearence'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>

				<ElasticTextBox
					onChange={(data) => console.log(data)}
					labelName={'Reporting Manager (Administrative)'}
				/>
				<ElasticTextBox
					onChange={(data) => console.log(data)}
					labelName={'Reporting Manager (Functional)'}
				/>

				<TextBox
					labelName={'OU1 (Group)'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>
				<TextBox
					labelName={'OU2 (Company)'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>
				<TextBox
					labelName={'OU3 (Department)'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>

				<TextBox
					labelName={'Work eMail ID'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>

				<PhoneSelector
					labelName={'Work Phone Number'}
					currentCountry={selectedCountry}
					onCountrySelect={(obj) => UpdateFields(obj)}
					onUSerInputChange={(obj) => setuserNumber(obj)}
				/>

				<ElasticTextBox
					onChange={(data) => console.log(data)}
					labelName={'Work Address'}
				/>
				<ElasticTextBox
					onChange={(data) => console.log(data)}
					labelName={'Business Address'}
				/>

				<TextBox
					labelName={'City'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>
				<TextBox
					labelName={'Pincode'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>
				<TextBox
					labelName={'State'}
					type={'text'}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>
				<CountrySelector
					label="Country"
					onChange={(data) => console.log(data)}
				/>
			</div>
		</>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setShow(false)
	}

	useEffect(() => {
		setShowDrawer(show)
		document.body.classList.toggle('no-scroll', show)
		setScrollable(show)
		setBody(FORM)
	}, [show, selectedCountry])

	useEffect(() => {
		setShowDrawer(show)
		setScrollable(show)
	}, [])

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">Additional Text</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Learn more
				</IconButton>
				<IconButton
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Get access
				</IconButton>
			</div>
		</>
	)
	return (
		<Drawer
			label="Business Info"
			size={size}
			body={body}
			drawerFooterBody={drawerFooterBody}
			scrollable={scrollable}
			onClose={handleDrawerClose}
			overlay={overlay}
			scrollableFooter={scrollableFooter}
			drawerPosition={drawerPosition}
			showDrawer={showDrawer}
		/>
	)
}

export default React.memo(BusinessinfoDrawer)

import React from 'react'
import TextScrollCheck from '../../components/ui/TextScrollCheck'
const TextScrollCHeck = () => {
	const content = (
		<>
			<p className="mb-2">
				Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
				eum tempore nam, sapiente adipisci molestiae sed distinctio
				dolorem modi illum doloribus, nisi perspiciatis reiciendis
				beatae rerum veniam repellat fuga corporis. Lorem ipsum dolor,
				sit amet consectetur adipisicing elit. Nulla eum tempore nam,
				sapiente adipisci molestiae sed distinctio dolorem modi illum
				doloribus, nisi perspiciatis reiciendis beatae rerum veniam
				repellat fuga corporis.
			</p>
			<p className="mb-2">
				Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
				eum tempore nam, sapiente adipisci molestiae sed distinctio
				dolorem modi illum doloribus, nisi perspiciatis reiciendis
				beatae rerum veniam repellat fuga corporis. Lorem ipsum dolor,
				sit amet consectetur adipisicing elit. Nulla eum tempore nam,
				sapiente adipisci molestiae sed distinctio dolorem modi illum
				doloribus, nisi perspiciatis reiciendis beatae rerum veniam
				repellat fuga corporis.
			</p>
			<p className="mb-2">
				Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
				eum tempore nam, sapiente adipisci molestiae sed distinctio
				dolorem modi illum doloribus, nisi perspiciatis reiciendis
				beatae rerum veniam repellat fuga corporis. Lorem ipsum dolor,
				sit amet consectetur adipisicing elit. Nulla eum tempore nam,
				sapiente adipisci molestiae sed distinctio dolorem modi illum
				doloribus, nisi perspiciatis reiciendis beatae rerum veniam
				repellat fuga corporis.
			</p>
			<p className="mb-2">
				Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
				eum tempore nam, sapiente adipisci molestiae sed distinctio
				dolorem modi illum doloribus, nisi perspiciatis reiciendis
				beatae rerum veniam repellat fuga corporis. Lorem ipsum dolor,
				sit amet consectetur adipisicing elit. Nulla eum tempore nam,
				sapiente adipisci molestiae sed distinctio dolorem modi illum
				doloribus, nisi perspiciatis reiciendis beatae rerum veniam
				repellat fuga corporis.
			</p>
			<p className="mb-2">
				Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
				eum tempore nam, sapiente adipisci molestiae sed distinctio
				dolorem modi illum doloribus, nisi perspiciatis reiciendis
				beatae rerum veniam repellat fuga corporis. Lorem ipsum dolor,
				sit amet consectetur adipisicing elit. Nulla eum tempore nam,
				sapiente adipisci molestiae sed distinctio dolorem modi illum
				doloribus, nisi perspiciatis reiciendis beatae rerum veniam
				repellat fuga corporis.
			</p>
			<p className="mb-2">
				Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
				eum tempore nam, sapiente adipisci molestiae sed distinctio
				dolorem modi illum doloribus, nisi perspiciatis reiciendis
				beatae rerum veniam repellat fuga corporis. Lorem ipsum dolor,
				sit amet consectetur adipisicing elit. Nulla eum tempore nam,
				sapiente adipisci molestiae sed distinctio dolorem modi illum
				doloribus, nisi perspiciatis reiciendis beatae rerum veniam
				repellat fuga corporis.
			</p>
		</>
	)
	return (
		<TextScrollCheck
			labelName={'TextArea(scroll check)'}
			Content={content}
			checkBoxLabelName={'I Accept Disclaimer'}
		/>
	)
}

export default TextScrollCHeck

import React, { useState } from 'react'

import AuthLayout from '../../Auth-Layout'

import FormHeader from '../../components/Common/FormHeader'
import FormBody from '../../components/Common/FormBody'
import FormFooter from '../../components/Common/FormFooter'

import TextBox from '../../components/ui/TextBox'
import Icon from '../../components/ui/icon'
import Button from '../../components/ui/Buttons/Button'
import Alert from '../../components/ui/Alerts'

import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'

const ChangePassword = () => {
	const [notifications, setNotifications] = useState([])

	const [userData, setUserData] = useState({
		email: null,
		password: null,
		confirmpassword: null
	})

	const HandleChange = (key, value) => {
		setUserData((prevData) => ({
			...prevData,
			[key]: value
		}))
	}
	const addNotification = (type, message, icon) => {
		setNotifications((prevNotifications) => [
			{ id: new Date().getTime(), type, message, icon },
			...prevNotifications
		])
	}
	const HandleSubmit = (event) => {
		event.preventDefault()
		const PSW_REGEXP =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
		const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

		let isValidEmail = EMAIL_REGEXP.test(userData.email)
		let isValidPassword = PSW_REGEXP.test(userData.password)

		if (
			!userData.email ||
			!isValidEmail ||
			!userData.password ||
			!isValidPassword ||
			!userData.confirmpassword ||
			userData.password !== userData.confirmpassword
		) {
			if (!userData.email) {
				addNotification(
					'danger',
					'Please enter your email address.',
					faTriangleExclamation
				)
			} else if (!isValidEmail) {
				addNotification(
					'danger',
					'Please enter valid email address.',
					faTriangleExclamation
				)
			} else if (!userData.password) {
				addNotification(
					'danger',
					'Please enter your password.',
					faTriangleExclamation
				)
			} else if (!isValidPassword) {
				addNotification(
					'danger',
					'Please enter a valid password.',
					faTriangleExclamation
				)
			} else if (!userData.confirmpassword) {
				addNotification(
					'danger',
					'Please enter your confirm password.',
					faTriangleExclamation
				)
			} else if (userData.password !== userData.confirmpassword) {
				addNotification(
					'danger',
					'Both password should match.',
					faTriangleExclamation
				)
			} else {
				addNotification(
					'danger',
					'Something went wrong. Please try again after sometime.',
					faTriangleExclamation
				)
			}
		} else {
			addNotification(
				'success',
				'Your password changed successfully.',
				faCheck
			)
		}
	}

	return (
		<>
			<div className="alert-wrapper" data-position="top">
				{notifications
					.sort((a, b) => b.id - a.id)
					.map((notification, index) => (
						<div key={notification.id}>
							<Alert
								id={index + 1}
								className={`alert-${notification.type}`}
								message={notification.message}
								Icon={notification.icon}
								autoClose={3000}
								onClose={() => {
									setNotifications((prevNotifications) =>
										prevNotifications.filter(
											(n) => n.id !== notification.id
										)
									)
								}}
							/>
						</div>
					))}
			</div>
			<AuthLayout>
				<FormHeader
					title={'Change password'}
					message={'Welcome! Please change password to continue'}
				/>
				<FormBody>
					<TextBox
						onChange={(data) => HandleChange('email', data)}
						labelName="Email address"
						type="text"
						placeholder=""
						className="mb-4"
					/>

					<TextBox
						onChange={(data) => HandleChange('password', data)}
						labelName="Password"
						type="password"
						placeholder=""
						className="mb-4"
					/>

					<TextBox
						onChange={(data) =>
							HandleChange('confirmpassword', data)
						}
						labelName="Verify Password"
						type="password"
						placeholder=""
						className="mb-4"
					/>

					<div className="mb-4">
						<p className="small-text text-[#6e7985] ">
							By clicking <strong>Change Password</strong> below,
							you agree to our Terms and Conditions and Privacy
							Policy.
						</p>
					</div>
					<Button
						className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
						isIconBtn={true}
						onClick={HandleSubmit}
					>
						<Icon
							name="line-lock"
							width={'16px'}
							height={'16px'}
							className={'icon-fill-white'}
							viewbox={'0 0 24 24'}
						/>
						Change Password
					</Button>
				</FormBody>
				<FormFooter
					message1="Need assistance or have questions"
					linktext1="Contact Support"
					link1="contact-us"
					message2="Don't have an account"
					linktext2="Create an Account"
					link2="register"
				/>
			</AuthLayout>
		</>
	)
}

export default ChangePassword

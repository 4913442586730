import React, { useState, useEffect, useRef } from 'react'
import { PhotoshopPicker } from 'react-color'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
const ColorPicker = ({ labelName, getColor }) => {
	const [color, setColor] = useState('#319f97') //this will be for the initial selected color
	const [show, setShow] = useState(false)
	const [displayColor, setDisplayColor] = useState(color)
	const [previousColor, setPreviousColor] = useState(color)
	const [inputValue, setInputValue] = useState(color)
	const colorPickerRef = useRef()

	// this function gets called when we move drag icon
	const handleChangeComplete = (newColor) => {
		setColor(newColor.hex)
	}

	useEffect(() => {
		setInputValue(color)
		setDisplayColor(color)
	}, [color])

	const showHandler = () => {
		setShow((prev) => !prev)
	}

	// cancel the selected color
	const cancelHandler = () => {
		setDisplayColor(previousColor)
		setShow((prev) => !prev)
	}

	// store the selected color
	const chooseHandler = () => {
		getColor(color)
		setDisplayColor(color)
		setPreviousColor(color)
		setShow((prev) => !prev)
	}

	// set color on user input value
	const handleInputChange = (e) => {
		setColor(e.target.value)
		setDisplayColor(e.target.value)
		setInputValue(e.target.value)
	}

	// close the colorpicker on outside click
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				colorPickerRef.current &&
				!colorPickerRef.current.contains(event.target)
			) {
				setShow(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div className="form-group ">
			{labelName && <label className="label">{labelName}</label>}
			<div className="input-outer">
				<div
					className="relative h-[30px] w-[50px]"
					ref={colorPickerRef}
				>
					<div
						className="flex h-[30px] w-[50px] items-center justify-between border-[1px] border-solid border-[#ccd2da] p-1 
       hover:border-[#9ea7b1]   "
					>
						<input
							type="text"
							readOnly
							style={{ backgroundColor: displayColor }}
							onClick={showHandler}
							className="h-[18px] w-6 cursor-pointer border-[1px] border-solid border-black"
						/>
						<FontAwesomeIcon
							icon={faCaretDown}
							onClick={showHandler}
							className="cursor-pointer"
						/>
					</div>
					{show && (
						<>
							<PhotoshopPicker
								color={color}
								onChange={handleChangeComplete}
							/>
							<div className="custom-labels absolute mt-1 gap-2 pl-1">
								<input
									type="text"
									className="form-control form-control-1 color-custom-input m-0 ml-[2px] min-w-[110px] !max-w-[110px] border-[#000] py-2 focus:!border-[#ffa500]"
									value={inputValue}
									onChange={handleInputChange}
								/>
								<button
									className="btn btn-primary btn-large ml-2 min-h-[30px] px-5 py-1"
									onClick={cancelHandler}
								>
									Cancel
								</button>
								<button
									className="btn btn-primary btn-large mr-[-1px] min-h-[30px] px-5 py-1"
									onClick={chooseHandler}
								>
									Choose
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
export default ColorPicker

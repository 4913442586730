export const DATA = [
	{
		id: 4,
		alpha2: 'af',
		alpha3: 'afg',
		name: 'Afghanistan',
		phoneCode: '+93'
	},
	{
		id: 248,
		alpha2: 'ax',
		alpha3: 'ala',
		name: 'Åland Islands',
		phoneCode: '+358'
	},
	{ id: 8, alpha2: 'al', alpha3: 'alb', name: 'Albania', phoneCode: '+355' },
	{ id: 12, alpha2: 'dz', alpha3: 'dza', name: 'Algeria', phoneCode: '+213' },
	{
		id: 16,
		alpha2: 'as',
		alpha3: 'asm',
		name: 'American Samoa',
		phoneCode: '+1'
	},
	{ id: 20, alpha2: 'ad', alpha3: 'and', name: 'Andorra', phoneCode: '+376' },
	{ id: 24, alpha2: 'ao', alpha3: 'ago', name: 'Angola', phoneCode: '+244' },
	{ id: 660, alpha2: 'ai', alpha3: 'aia', name: 'Anguilla', phoneCode: '+1' },
	{ id: 10, alpha2: 'aq', alpha3: 'ata', name: 'Antarctica' },
	{
		id: 28,
		alpha2: 'ag',
		alpha3: 'atg',
		name: 'Antigua and Barbuda',
		phoneCode: '+1'
	},
	{
		id: 32,
		alpha2: 'ar',
		alpha3: 'arg',
		name: 'Argentina',
		phoneCode: '+54'
	},
	{ id: 51, alpha2: 'am', alpha3: 'arm', name: 'Armenia', phoneCode: '+374' },
	{ id: 533, alpha2: 'aw', alpha3: 'abw', name: 'Aruba', phoneCode: '+297' },
	{
		id: 36,
		alpha2: 'au',
		alpha3: 'aus',
		name: 'Australia',
		phoneCode: '+61'
	},
	{ id: 40, alpha2: 'at', alpha3: 'aut', name: 'Austria', phoneCode: '+43' },
	{
		id: 31,
		alpha2: 'az',
		alpha3: 'aze',
		name: 'Azerbaijan',
		phoneCode: '+994'
	},
	{ id: 44, alpha2: 'bs', alpha3: 'bhs', name: 'Bahamas', phoneCode: '+1' },
	{ id: 48, alpha2: 'bh', alpha3: 'bhr', name: 'Bahrain', phoneCode: '+973' },
	{
		id: 50,
		alpha2: 'bd',
		alpha3: 'bgd',
		name: 'Bangladesh',
		phoneCode: '+880'
	},
	{ id: 52, alpha2: 'bb', alpha3: 'brb', name: 'Barbados', phoneCode: '+1' },
	{
		id: 112,
		alpha2: 'by',
		alpha3: 'blr',
		name: 'Belarus',
		phoneCode: '+375'
	},
	{ id: 56, alpha2: 'be', alpha3: 'bel', name: 'Belgium', phoneCode: '+32' },
	{ id: 84, alpha2: 'bz', alpha3: 'blz', name: 'Belize', phoneCode: '+501' },
	{ id: 204, alpha2: 'bj', alpha3: 'ben', name: 'Benin', phoneCode: '+229' },
	{ id: 60, alpha2: 'bm', alpha3: 'bmu', name: 'Bermuda', phoneCode: '+1' },
	{ id: 64, alpha2: 'bt', alpha3: 'btn', name: 'Bhutan', phoneCode: '+975' },
	{
		id: 68,
		alpha2: 'bo',
		alpha3: 'bol',
		name: 'Bolivia (Plurinational State of)',
		phoneCode: '+591'
	},
	{
		id: 535,
		alpha2: 'bq',
		alpha3: 'bes',
		name: 'Bonaire, Sint Eustatius and Saba',
		phoneCode: '+599'
	},
	{
		id: 70,
		alpha2: 'ba',
		alpha3: 'bih',
		name: 'Bosnia and Herzegovina',
		phoneCode: '+387'
	},
	{
		id: 72,
		alpha2: 'bw',
		alpha3: 'bwa',
		name: 'Botswana',
		phoneCode: '+267'
	},
	{ id: 74, alpha2: 'bv', alpha3: 'bvt', name: 'Bouvet Island' },
	{ id: 76, alpha2: 'br', alpha3: 'bra', name: 'Brazil', phoneCode: '+55' },
	{
		id: 86,
		alpha2: 'io',
		alpha3: 'iot',
		name: 'British Indian Ocean Territory',
		phoneCode: '+246'
	},
	{
		id: 96,
		alpha2: 'bn',
		alpha3: 'brn',
		name: 'Brunei Darussalam',
		phoneCode: '+673'
	},
	{
		id: 100,
		alpha2: 'bg',
		alpha3: 'bgr',
		name: 'Bulgaria',
		phoneCode: '+359'
	},
	{
		id: 854,
		alpha2: 'bf',
		alpha3: 'bfa',
		name: 'Burkina Faso',
		phoneCode: '+226'
	},
	{
		id: 108,
		alpha2: 'bi',
		alpha3: 'bdi',
		name: 'Burundi',
		phoneCode: '+257'
	},
	{
		id: 132,
		alpha2: 'cv',
		alpha3: 'cpv',
		name: 'Cabo Verde',
		phoneCode: '+238'
	},
	{
		id: 116,
		alpha2: 'kh',
		alpha3: 'khm',
		name: 'Cambodia',
		phoneCode: '+855'
	},
	{
		id: 120,
		alpha2: 'cm',
		alpha3: 'cmr',
		name: 'Cameroon',
		phoneCode: '+237'
	},
	{ id: 124, alpha2: 'ca', alpha3: 'can', name: 'Canada', phoneCode: '+1' },
	{
		id: 136,
		alpha2: 'ky',
		alpha3: 'cym',
		name: 'Cayman Islands',
		phoneCode: '+1'
	},
	{
		id: 140,
		alpha2: 'cf',
		alpha3: 'caf',
		name: 'Central African Republic',
		phoneCode: '+236'
	},
	{ id: 148, alpha2: 'td', alpha3: 'tcd', name: 'Chad', phoneCode: '+235' },
	{ id: 152, alpha2: 'cl', alpha3: 'chl', name: 'Chile', phoneCode: '+56' },
	{ id: 156, alpha2: 'cn', alpha3: 'chn', name: 'China', phoneCode: '+86' },
	{
		id: 162,
		alpha2: 'cx',
		alpha3: 'cxr',
		name: 'Christmas Island',
		phoneCode: '+61'
	},
	{
		id: 166,
		alpha2: 'cc',
		alpha3: 'cck',
		name: 'Cocos (Keeling) Islands',
		phoneCode: '+61'
	},
	{
		id: 170,
		alpha2: 'co',
		alpha3: 'col',
		name: 'Colombia',
		phoneCode: '+57'
	},
	{
		id: 174,
		alpha2: 'km',
		alpha3: 'com',
		name: 'Comoros',
		phoneCode: '+269'
	},
	{ id: 178, alpha2: 'cg', alpha3: 'cog', name: 'Congo', phoneCode: '+242' },
	{
		id: 180,
		alpha2: 'cd',
		alpha3: 'cod',
		name: 'Congo, Democratic Republic of the',
		phoneCode: '+243'
	},
	{
		id: 184,
		alpha2: 'ck',
		alpha3: 'cok',
		name: 'Cook Islands',
		phoneCode: '+682'
	},
	{
		id: 188,
		alpha2: 'cr',
		alpha3: 'cri',
		name: 'Costa Rica',
		phoneCode: '+506'
	},
	{
		id: 384,
		alpha2: 'ci',
		alpha3: 'civ',
		name: "Côte d'Ivoire",
		phoneCode: '+225'
	},
	{
		id: 191,
		alpha2: 'hr',
		alpha3: 'hrv',
		name: 'Croatia',
		phoneCode: '+385'
	},
	{ id: 192, alpha2: 'cu', alpha3: 'cub', name: 'Cuba', phoneCode: '+53' },
	{
		id: 531,
		alpha2: 'cw',
		alpha3: 'cuw',
		name: 'Curaçao',
		phoneCode: '+599'
	},
	{ id: 196, alpha2: 'cy', alpha3: 'cyp', name: 'Cyprus', phoneCode: '+357' },
	{
		id: 203,
		alpha2: 'cz',
		alpha3: 'cze',
		name: 'Czechia',
		phoneCode: '+420'
	},
	{ id: 208, alpha2: 'dk', alpha3: 'dnk', name: 'Denmark', phoneCode: '+45' },
	{
		id: 262,
		alpha2: 'dj',
		alpha3: 'dji',
		name: 'Djibouti',
		phoneCode: '+253'
	},
	{ id: 212, alpha2: 'dm', alpha3: 'dma', name: 'Dominica', phoneCode: '+1' },
	{
		id: 214,
		alpha2: 'do',
		alpha3: 'dom',
		name: 'Dominican Republic',
		phoneCode: '+1'
	},
	{
		id: 218,
		alpha2: 'ec',
		alpha3: 'ecu',
		name: 'Ecuador',
		phoneCode: '+593'
	},
	{ id: 818, alpha2: 'eg', alpha3: 'egy', name: 'Egypt', phoneCode: '+20' },
	{
		id: 222,
		alpha2: 'sv',
		alpha3: 'slv',
		name: 'El Salvador',
		phoneCode: '+503'
	},
	{
		id: 226,
		alpha2: 'gq',
		alpha3: 'gnq',
		name: 'Equatorial Guinea',
		phoneCode: '+240'
	},
	{
		id: 232,
		alpha2: 'er',
		alpha3: 'eri',
		name: 'Eritrea',
		phoneCode: '+291'
	},
	{
		id: 233,
		alpha2: 'ee',
		alpha3: 'est',
		name: 'Estonia',
		phoneCode: '+372'
	},
	{
		id: 748,
		alpha2: 'sz',
		alpha3: 'swz',
		name: 'Eswatini',
		phoneCode: '+268'
	},
	{
		id: 231,
		alpha2: 'et',
		alpha3: 'eth',
		name: 'Ethiopia',
		phoneCode: '+251'
	},
	{
		id: 238,
		alpha2: 'fk',
		alpha3: 'flk',
		name: 'Falkland Islands (Malvinas)',
		phoneCode: '+500'
	},
	{
		id: 234,
		alpha2: 'fo',
		alpha3: 'fro',
		name: 'Faroe Islands',
		phoneCode: '+298'
	},
	{ id: 242, alpha2: 'fj', alpha3: 'fji', name: 'Fiji', phoneCode: '+679' },
	{
		id: 246,
		alpha2: 'fi',
		alpha3: 'fin',
		name: 'Finland',
		phoneCode: '+358'
	},
	{ id: 250, alpha2: 'fr', alpha3: 'fra', name: 'France', phoneCode: '+33' },
	{
		id: 254,
		alpha2: 'gf',
		alpha3: 'guf',
		name: 'French Guiana',
		phoneCode: '+594'
	},
	{
		id: 258,
		alpha2: 'pf',
		alpha3: 'pyf',
		name: 'French Polynesia',
		phoneCode: '+689'
	},
	{
		id: 260,
		alpha2: 'tf',
		alpha3: 'atf',
		name: 'French Southern Territories'
	},
	{ id: 266, alpha2: 'ga', alpha3: 'gab', name: 'Gabon', phoneCode: '+241' },
	{ id: 270, alpha2: 'gm', alpha3: 'gmb', name: 'Gambia', phoneCode: '+220' },
	{
		id: 268,
		alpha2: 'ge',
		alpha3: 'geo',
		name: 'Georgia',
		phoneCode: '+995'
	},
	{ id: 276, alpha2: 'de', alpha3: 'deu', name: 'Germany', phoneCode: '+49' },
	{ id: 288, alpha2: 'gh', alpha3: 'gha', name: 'Ghana', phoneCode: '+233' },
	{
		id: 292,
		alpha2: 'gi',
		alpha3: 'gib',
		name: 'Gibraltar',
		phoneCode: '+350'
	},
	{ id: 300, alpha2: 'gr', alpha3: 'grc', name: 'Greece', phoneCode: '+30' },
	{
		id: 304,
		alpha2: 'gl',
		alpha3: 'grl',
		name: 'Greenland',
		phoneCode: '+299'
	},
	{ id: 308, alpha2: 'gd', alpha3: 'grd', name: 'Grenada', phoneCode: '+1' },
	{
		id: 312,
		alpha2: 'gp',
		alpha3: 'glp',
		name: 'Guadeloupe',
		phoneCode: '+590'
	},
	{ id: 316, alpha2: 'gu', alpha3: 'gum', name: 'Guam', phoneCode: '+1' },
	{
		id: 320,
		alpha2: 'gt',
		alpha3: 'gtm',
		name: 'Guatemala',
		phoneCode: '+502'
	},
	{
		id: 831,
		alpha2: 'gg',
		alpha3: 'ggy',
		name: 'Guernsey',
		phoneCode: '+44'
	},
	{ id: 324, alpha2: 'gn', alpha3: 'gin', name: 'Guinea', phoneCode: '+224' },
	{
		id: 624,
		alpha2: 'gw',
		alpha3: 'gnb',
		name: 'Guinea-Bissau',
		phoneCode: '+245'
	},
	{ id: 328, alpha2: 'gy', alpha3: 'guy', name: 'Guyana', phoneCode: '+592' },
	{ id: 332, alpha2: 'ht', alpha3: 'hti', name: 'Haiti', phoneCode: '+509' },
	{
		id: 334,
		alpha2: 'hm',
		alpha3: 'hmd',
		name: 'Heard Island and McDonald Islands'
	},
	{
		id: 336,
		alpha2: 'va',
		alpha3: 'vat',
		name: 'Holy See',
		phoneCode: '+379'
	},
	{
		id: 340,
		alpha2: 'hn',
		alpha3: 'hnd',
		name: 'Honduras',
		phoneCode: '+504'
	},
	{
		id: 344,
		alpha2: 'hk',
		alpha3: 'hkg',
		name: 'Hong Kong',
		phoneCode: '+852'
	},
	{ id: 348, alpha2: 'hu', alpha3: 'hun', name: 'Hungary', phoneCode: '+36' },
	{
		id: 352,
		alpha2: 'is',
		alpha3: 'isl',
		name: 'Iceland',
		phoneCode: '+354'
	},
	{ id: 356, alpha2: 'in', alpha3: 'ind', name: 'India', phoneCode: '+91' },
	{
		id: 360,
		alpha2: 'id',
		alpha3: 'idn',
		name: 'Indonesia',
		phoneCode: '+62'
	},
	{
		id: 364,
		alpha2: 'ir',
		alpha3: 'irn',
		name: 'Iran (Islamic Republic of)',
		phoneCode: '+98'
	},
	{ id: 368, alpha2: 'iq', alpha3: 'irq', name: 'Iraq', phoneCode: '+964' },
	{
		id: 372,
		alpha2: 'ie',
		alpha3: 'irl',
		name: 'Ireland',
		phoneCode: '+353'
	},
	{
		id: 833,
		alpha2: 'im',
		alpha3: 'imn',
		name: 'Isle of Man',
		phoneCode: '+44'
	},
	{ id: 376, alpha2: 'il', alpha3: 'isr', name: 'Israel', phoneCode: '+972' },
	{ id: 380, alpha2: 'it', alpha3: 'ita', name: 'Italy', phoneCode: '+39' },
	{ id: 388, alpha2: 'jm', alpha3: 'jam', name: 'Jamaica', phoneCode: '+1' },
	{ id: 392, alpha2: 'jp', alpha3: 'jpn', name: 'Japan', phoneCode: '+81' },
	{ id: 832, alpha2: 'je', alpha3: 'jey', name: 'Jersey', phoneCode: '+44' },
	{ id: 400, alpha2: 'jo', alpha3: 'jor', name: 'Jordan', phoneCode: '+962' },
	{
		id: 398,
		alpha2: 'kz',
		alpha3: 'kaz',
		name: 'Kazakhstan',
		phoneCode: '+7'
	},
	{ id: 404, alpha2: 'ke', alpha3: 'ken', name: 'Kenya', phoneCode: '+254' },
	{
		id: 296,
		alpha2: 'ki',
		alpha3: 'kir',
		name: 'Kiribati',
		phoneCode: '+686'
	},
	{
		id: 408,
		alpha2: 'kp',
		alpha3: 'prk',
		name: "Korea (Democratic People's Republic of)",
		phoneCode: '+850'
	},
	{
		id: 410,
		alpha2: 'kr',
		alpha3: 'kor',
		name: 'Korea, Republic of',
		phoneCode: '+82'
	},
	{ id: 414, alpha2: 'kw', alpha3: 'kwt', name: 'Kuwait', phoneCode: '+965' },
	{
		id: 417,
		alpha2: 'kg',
		alpha3: 'kgz',
		name: 'Kyrgyzstan',
		phoneCode: '+996'
	},
	{
		id: 418,
		alpha2: 'la',
		alpha3: 'lao',
		name: "Lao People's Democratic Republic",
		phoneCode: '+856'
	},
	{ id: 428, alpha2: 'lv', alpha3: 'lva', name: 'Latvia', phoneCode: '+371' },
	{
		id: 422,
		alpha2: 'lb',
		alpha3: 'lbn',
		name: 'Lebanon',
		phoneCode: '+961'
	},
	{
		id: 426,
		alpha2: 'ls',
		alpha3: 'lso',
		name: 'Lesotho',
		phoneCode: '+266'
	},
	{
		id: 430,
		alpha2: 'lr',
		alpha3: 'lbr',
		name: 'Liberia',
		phoneCode: '+231'
	},
	{ id: 434, alpha2: 'ly', alpha3: 'lby', name: 'Libya', phoneCode: '+218' },
	{
		id: 438,
		alpha2: 'li',
		alpha3: 'lie',
		name: 'Liechtenstein',
		phoneCode: '+423'
	},
	{
		id: 440,
		alpha2: 'lt',
		alpha3: 'ltu',
		name: 'Lithuania',
		phoneCode: '+370'
	},
	{
		id: 442,
		alpha2: 'lu',
		alpha3: 'lux',
		name: 'Luxembourg',
		phoneCode: '+352'
	},
	{ id: 446, alpha2: 'mo', alpha3: 'mac', name: 'Macao', phoneCode: '+853' },
	{
		id: 450,
		alpha2: 'mg',
		alpha3: 'mdg',
		name: 'Madagascar',
		phoneCode: '+261'
	},
	{ id: 454, alpha2: 'mw', alpha3: 'mwi', name: 'Malawi', phoneCode: '+265' },
	{
		id: 458,
		alpha2: 'my',
		alpha3: 'mys',
		name: 'Malaysia',
		phoneCode: '+60'
	},
	{
		id: 462,
		alpha2: 'mv',
		alpha3: 'mdv',
		name: 'Maldives',
		phoneCode: '+960'
	},
	{ id: 466, alpha2: 'ml', alpha3: 'mli', name: 'Mali', phoneCode: '+223' },
	{ id: 470, alpha2: 'mt', alpha3: 'mlt', name: 'Malta', phoneCode: '+356' },
	{
		id: 584,
		alpha2: 'mh',
		alpha3: 'mhl',
		name: 'Marshall Islands',
		phoneCode: '+692'
	},
	{
		id: 474,
		alpha2: 'mq',
		alpha3: 'mtq',
		name: 'Martinique',
		phoneCode: '+596'
	},
	{
		id: 478,
		alpha2: 'mr',
		alpha3: 'mrt',
		name: 'Mauritania',
		phoneCode: '+222'
	},
	{
		id: 480,
		alpha2: 'mu',
		alpha3: 'mus',
		name: 'Mauritius',
		phoneCode: '+230'
	},
	{ id: 175, alpha2: 'yt', alpha3: 'myt', name: 'Mayotte' },
	{ id: 484, alpha2: 'mx', alpha3: 'mex', name: 'Mexico', phoneCode: '+52' },
	{
		id: 583,
		alpha2: 'fm',
		alpha3: 'fsm',
		name: 'Micronesia (Federated States of)',
		phoneCode: '+691'
	},
	{
		id: 498,
		alpha2: 'md',
		alpha3: 'mda',
		name: 'Moldova, Republic of',
		phoneCode: '+373'
	},
	{ id: 492, alpha2: 'mc', alpha3: 'mco', name: 'Monaco', phoneCode: '+377' },
	{
		id: 496,
		alpha2: 'mn',
		alpha3: 'mng',
		name: 'Mongolia',
		phoneCode: '+976'
	},
	{
		id: 499,
		alpha2: 'me',
		alpha3: 'mne',
		name: 'Montenegro',
		phoneCode: '+382'
	},
	{
		id: 500,
		alpha2: 'ms',
		alpha3: 'msr',
		name: 'Montserrat',
		phoneCode: '+1'
	},
	{
		id: 504,
		alpha2: 'ma',
		alpha3: 'mar',
		name: 'Morocco',
		phoneCode: '+212'
	},
	{
		id: 508,
		alpha2: 'mz',
		alpha3: 'moz',
		name: 'Mozambique',
		phoneCode: '+258'
	},
	{ id: 104, alpha2: 'mm', alpha3: 'mmr', name: 'Myanmar', phoneCode: '+95' },
	{
		id: 516,
		alpha2: 'na',
		alpha3: 'nam',
		name: 'Namibia',
		phoneCode: '+264'
	},
	{ id: 520, alpha2: 'nr', alpha3: 'nru', name: 'Nauru', phoneCode: '+674' },
	{ id: 524, alpha2: 'np', alpha3: 'npl', name: 'Nepal', phoneCode: '+977' },
	{
		id: 528,
		alpha2: 'nl',
		alpha3: 'nld',
		name: 'Netherlands',
		phoneCode: '+31'
	},
	{
		id: 540,
		alpha2: 'nc',
		alpha3: 'ncl',
		name: 'New Caledonia',
		phoneCode: '+687'
	},
	{
		id: 554,
		alpha2: 'nz',
		alpha3: 'nzl',
		name: 'New Zealand',
		phoneCode: '+64'
	},
	{
		id: 558,
		alpha2: 'ni',
		alpha3: 'nic',
		name: 'Nicaragua',
		phoneCode: '+505'
	},
	{ id: 562, alpha2: 'ne', alpha3: 'ner', name: 'Niger', phoneCode: '+227' },
	{
		id: 566,
		alpha2: 'ng',
		alpha3: 'nga',
		name: 'Nigeria',
		phoneCode: '+234'
	},
	{ id: 570, alpha2: 'nu', alpha3: 'niu', name: 'Niue', phoneCode: '+683' },
	{ id: 574, alpha2: 'nf', alpha3: 'nfk', name: 'Norfolk Island' },
	{
		id: 807,
		alpha2: 'mk',
		alpha3: 'mkd',
		name: 'North Macedonia',
		phoneCode: '+389'
	},
	{
		id: 580,
		alpha2: 'mp',
		alpha3: 'mnp',
		name: 'Northern Mariana Islands',
		phoneCode: '+1'
	},
	{ id: 578, alpha2: 'no', alpha3: 'nor', name: 'Norway', phoneCode: '+47' },
	{ id: 512, alpha2: 'om', alpha3: 'omn', name: 'Oman', phoneCode: '+968' },
	{
		id: 586,
		alpha2: 'pk',
		alpha3: 'pak',
		name: 'Pakistan',
		phoneCode: '+92'
	},
	{ id: 585, alpha2: 'pw', alpha3: 'plw', name: 'Palau', phoneCode: '+680' },
	{
		id: 275,
		alpha2: 'ps',
		alpha3: 'pse',
		name: 'Palestine, State of',
		phoneCode: '+970'
	},
	{ id: 591, alpha2: 'pa', alpha3: 'pan', name: 'Panama', phoneCode: '+507' },
	{
		id: 598,
		alpha2: 'pg',
		alpha3: 'png',
		name: 'Papua New Guinea',
		phoneCode: '+675'
	},
	{
		id: 600,
		alpha2: 'py',
		alpha3: 'pry',
		name: 'Paraguay',
		phoneCode: '+595'
	},
	{ id: 604, alpha2: 'pe', alpha3: 'per', name: 'Peru', phoneCode: '+51' },
	{
		id: 608,
		alpha2: 'ph',
		alpha3: 'phl',
		name: 'Philippines',
		phoneCode: '+63'
	},
	{ id: 612, alpha2: 'pn', alpha3: 'pcn', name: 'Pitcairn' },
	{ id: 616, alpha2: 'pl', alpha3: 'pol', name: 'Poland', phoneCode: '+48' },
	{
		id: 620,
		alpha2: 'pt',
		alpha3: 'prt',
		name: 'Portugal',
		phoneCode: '+351'
	},
	{
		id: 630,
		alpha2: 'pr',
		alpha3: 'pri',
		name: 'Puerto Rico',
		phoneCode: '+1'
	},
	{ id: 634, alpha2: 'qa', alpha3: 'qat', name: 'Qatar', phoneCode: '+974' },
	{
		id: 638,
		alpha2: 're',
		alpha3: 'reu',
		name: 'Réunion',
		phoneCode: '+262'
	},
	{ id: 642, alpha2: 'ro', alpha3: 'rou', name: 'Romania', phoneCode: '+40' },
	{
		id: 643,
		alpha2: 'ru',
		alpha3: 'rus',
		name: 'Russian Federation',
		phoneCode: '+7'
	},
	{ id: 646, alpha2: 'rw', alpha3: 'rwa', name: 'Rwanda', phoneCode: '+250' },
	{
		id: 652,
		alpha2: 'bl',
		alpha3: 'blm',
		name: 'Saint Barthélemy',
		phoneCode: '+590'
	},
	{
		id: 654,
		alpha2: 'sh',
		alpha3: 'shn',
		name: 'Saint Helena, Ascension and Tristan da Cunha',
		phoneCode: '+290'
	},
	{
		id: 659,
		alpha2: 'kn',
		alpha3: 'kna',
		name: 'Saint Kitts and Nevis',
		phoneCode: '+1'
	},
	{
		id: 662,
		alpha2: 'lc',
		alpha3: 'lca',
		name: 'Saint Lucia',
		phoneCode: '+1'
	},
	{
		id: 663,
		alpha2: 'mf',
		alpha3: 'maf',
		name: 'Saint Martin (French part)',
		phoneCode: '+590'
	},
	{
		id: 666,
		alpha2: 'pm',
		alpha3: 'spm',
		name: 'Saint Pierre and Miquelon',
		phoneCode: '+508'
	},
	{
		id: 670,
		alpha2: 'vc',
		alpha3: 'vct',
		name: 'Saint Vincent and the Grenadines',
		phoneCode: '+1'
	},
	{ id: 882, alpha2: 'ws', alpha3: 'wsm', name: 'Samoa', phoneCode: '+685' },
	{
		id: 674,
		alpha2: 'sm',
		alpha3: 'smr',
		name: 'San Marino',
		phoneCode: '+378'
	},
	{
		id: 678,
		alpha2: 'st',
		alpha3: 'stp',
		name: 'Sao Tome and Principe',
		phoneCode: '+239'
	},
	{
		id: 682,
		alpha2: 'sa',
		alpha3: 'sau',
		name: 'Saudi Arabia',
		phoneCode: '+966'
	},
	{
		id: 686,
		alpha2: 'sn',
		alpha3: 'sen',
		name: 'Senegal',
		phoneCode: '+221'
	},
	{ id: 688, alpha2: 'rs', alpha3: 'srb', name: 'Serbia', phoneCode: '+381' },
	{
		id: 690,
		alpha2: 'sc',
		alpha3: 'syc',
		name: 'Seychelles',
		phoneCode: '+248'
	},
	{
		id: 694,
		alpha2: 'sl',
		alpha3: 'sle',
		name: 'Sierra Leone',
		phoneCode: '+232'
	},
	{
		id: 702,
		alpha2: 'sg',
		alpha3: 'sgp',
		name: 'Singapore',
		phoneCode: '+65'
	},
	{
		id: 534,
		alpha2: 'sx',
		alpha3: 'sxm',
		name: 'Sint Maarten (Dutch part)',
		phoneCode: '+1'
	},
	{
		id: 703,
		alpha2: 'sk',
		alpha3: 'svk',
		name: 'Slovakia',
		phoneCode: '+421'
	},
	{
		id: 705,
		alpha2: 'si',
		alpha3: 'svn',
		name: 'Slovenia',
		phoneCode: '+386'
	},
	{
		id: 90,
		alpha2: 'sb',
		alpha3: 'slb',
		name: 'Solomon Islands',
		phoneCode: '+677'
	},
	{
		id: 706,
		alpha2: 'so',
		alpha3: 'som',
		name: 'Somalia',
		phoneCode: '+252'
	},
	{
		id: 710,
		alpha2: 'za',
		alpha3: 'zaf',
		name: 'South Africa',
		phoneCode: '+27'
	},
	{
		id: 239,
		alpha2: 'gs',
		alpha3: 'sgs',
		name: 'South Georgia and the South Sandwich Islands'
	},
	{
		id: 728,
		alpha2: 'ss',
		alpha3: 'ssd',
		name: 'South Sudan',
		phoneCode: '+211'
	},
	{ id: 724, alpha2: 'es', alpha3: 'esp', name: 'Spain', phoneCode: '+34' },
	{
		id: 144,
		alpha2: 'lk',
		alpha3: 'lka',
		name: 'Sri Lanka',
		phoneCode: '+94'
	},
	{ id: 729, alpha2: 'sd', alpha3: 'sdn', name: 'Sudan', phoneCode: '+249' },
	{
		id: 740,
		alpha2: 'sr',
		alpha3: 'sur',
		name: 'Suriname',
		phoneCode: '+597'
	},
	{ id: 744, alpha2: 'sj', alpha3: 'sjm', name: 'Svalbard and Jan Mayen' },
	{ id: 752, alpha2: 'se', alpha3: 'swe', name: 'Sweden', phoneCode: '+46' },
	{
		id: 756,
		alpha2: 'ch',
		alpha3: 'che',
		name: 'Switzerland',
		phoneCode: '+41'
	},
	{
		id: 760,
		alpha2: 'sy',
		alpha3: 'syr',
		name: 'Syrian Arab Republic',
		phoneCode: '+963'
	},
	{
		id: 158,
		alpha2: 'tw',
		alpha3: 'twn',
		name: 'Taiwan, Province of China',
		phoneCode: '+886'
	},
	{
		id: 762,
		alpha2: 'tj',
		alpha3: 'tjk',
		name: 'Tajikistan',
		phoneCode: '+992'
	},
	{
		id: 834,
		alpha2: 'tz',
		alpha3: 'tza',
		name: 'Tanzania, United Republic of',
		phoneCode: '+255'
	},
	{
		id: 764,
		alpha2: 'th',
		alpha3: 'tha',
		name: 'Thailand',
		phoneCode: '+66'
	},
	{
		id: 626,
		alpha2: 'tl',
		alpha3: 'tls',
		name: 'Timor-Leste',
		phoneCode: '+670'
	},
	{ id: 768, alpha2: 'tg', alpha3: 'tgo', name: 'Togo', phoneCode: '+228' },
	{
		id: 772,
		alpha2: 'tk',
		alpha3: 'tkl',
		name: 'Tokelau',
		phoneCode: '+690'
	},
	{ id: 776, alpha2: 'to', alpha3: 'ton', name: 'Tonga', phoneCode: '+676' },
	{
		id: 780,
		alpha2: 'tt',
		alpha3: 'tto',
		name: 'Trinidad and Tobago',
		phoneCode: '+1'
	},
	{
		id: 788,
		alpha2: 'tn',
		alpha3: 'tun',
		name: 'Tunisia',
		phoneCode: '+216'
	},
	{ id: 792, alpha2: 'tr', alpha3: 'tur', name: 'Türkiye', phoneCode: '+90' },
	{
		id: 795,
		alpha2: 'tm',
		alpha3: 'tkm',
		name: 'Turkmenistan',
		phoneCode: '+993'
	},
	{
		id: 796,
		alpha2: 'tc',
		alpha3: 'tca',
		name: 'Turks and Caicos Islands',
		phoneCode: '+1'
	},
	{ id: 798, alpha2: 'tv', alpha3: 'tuv', name: 'Tuvalu', phoneCode: '+688' },
	{ id: 800, alpha2: 'ug', alpha3: 'uga', name: 'Uganda', phoneCode: '+256' },
	{
		id: 804,
		alpha2: 'ua',
		alpha3: 'ukr',
		name: 'Ukraine',
		phoneCode: '+380'
	},
	{
		id: 784,
		alpha2: 'ae',
		alpha3: 'are',
		name: 'United Arab Emirates',
		phoneCode: '+971'
	},
	{
		id: 826,
		alpha2: 'gb',
		alpha3: 'gbr',
		name: 'United Kingdom of Great Britain ',
		phoneCode: '+44'
	},
	{
		id: 840,
		alpha2: 'us',
		alpha3: 'usa',
		name: 'United States of America',
		phoneCode: '+1'
	},
	{
		id: 581,
		alpha2: 'um',
		alpha3: 'umi',
		name: 'United States Minor Outlying Islands'
	},
	{
		id: 858,
		alpha2: 'uy',
		alpha3: 'ury',
		name: 'Uruguay',
		phoneCode: '+598'
	},
	{
		id: 860,
		alpha2: 'uz',
		alpha3: 'uzb',
		name: 'Uzbekistan',
		phoneCode: '+998'
	},
	{
		id: 548,
		alpha2: 'vu',
		alpha3: 'vut',
		name: 'Vanuatu',
		phoneCode: '+678'
	},
	{
		id: 862,
		alpha2: 've',
		alpha3: 'ven',
		name: 'Venezuela (Bolivarian Republic of)',
		phoneCode: '+58'
	},
	{
		id: 704,
		alpha2: 'vn',
		alpha3: 'vnm',
		name: 'Viet Nam',
		phoneCode: '+84'
	},
	{
		id: 92,
		alpha2: 'vg',
		alpha3: 'vgb',
		name: 'Virgin Islands (British)',
		phoneCode: '+1'
	},
	{
		id: 850,
		alpha2: 'vi',
		alpha3: 'vir',
		name: 'Virgin Islands (U.S.)',
		phoneCode: '+1'
	},
	{
		id: 876,
		alpha2: 'wf',
		alpha3: 'wlf',
		name: 'Wallis and Futuna',
		phoneCode: '+681'
	},
	{
		id: 732,
		alpha2: 'eh',
		alpha3: 'esh',
		name: 'Western Sahara',
		phoneCode: '+212'
	},
	{ id: 887, alpha2: 'ye', alpha3: 'yem', name: 'Yemen', phoneCode: '+967' },
	{ id: 894, alpha2: 'zm', alpha3: 'zmb', name: 'Zambia', phoneCode: '+260' },
	{
		id: 716,
		alpha2: 'zw',
		alpha3: 'zwe',
		name: 'Zimbabwe',
		phoneCode: '+263'
	}
]

import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Modals from './components/ui/Modals'
import Drawers from './components/ui/Drawers'
import Forgot from './pages/forgotpassword'
import ChangePassword from './pages/changepassword'
import FormPage from './pages/components/Form'
import Contact from './pages/contact'
import SendMagicLink from './pages/sendmagiclink'
import Login from './pages/login'
import Register from './pages/register/Register'
import Policy from './pages/policy'
import Menus from './pages/components/Menus'
import Pages from './pages/components/Menus/Pages'
import './index.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/themes/tailwind-light/theme.css'
import 'primeicons/primeicons.css'
import CheckmarkPage from './pages/components/CheckmarkPage'
import ButtonPage from './pages/components/ButtonPage'
import SvgIconPage from './pages/components/SvgIconPage'
import ColorPage from './pages/components/ColorPage'
import BadgePage from './pages/components/BadgePage'
import NotificationEmoji from './pages/components/NotificationsEmoji'
import NotificationSimple from './pages/components/NotificationsSimple'
import NotificationTimer from './pages/components/NotificationTimer'
import DataTableDemo from './pages/DataTableDemo'
import ViewProfile from './pages/viewprofile'
import FontPage from './pages/components/FontPage'
import UserList from './pages/UserList'
import { Helmet } from 'react-helmet'
import './index.css'
import './App.css'
const App = () => {
	return (
		<>
			<Helmet>
				<title>
					{
						'TRM - Next Generation Blockchain Intelligence | Monitor, Investigate, and Mitigate Crypto Fraud and Financial Crime'
					}
				</title>
				<meta name="description" content={'bussiness app'} />
				<meta property="og:title" content={'Title of your page'} />
				<meta
					property="og:description"
					content={'Description of your page'}
				/>
				<meta
					property="og:image"
					content={'URL of the image you want to use'}
				/>
				<meta property="og:url" content={'URL of your page'} />
				<meta property="og:type" content="website" />
			</Helmet>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/pages/login" element={<Login />} />
				<Route
					path="/pages/sendmagiclink"
					element={<SendMagicLink />}
				/>
				<Route path="/pages/register" element={<Register />} />
				<Route path="/pages/forgot-password" element={<Forgot />} />
				<Route path="/pages/policy" element={<Policy />} />
				<Route
					path="/pages/change-password"
					element={<ChangePassword />}
				/>
				<Route path="/pages/contact-us" element={<Contact />} />
				<Route path="/users" element={<Menus />}>
					<Route path="/users/fonts" element={<FontPage />} />
					<Route path="/users/colors" element={<ColorPage />} />
					<Route path="/users/buttons" element={<ButtonPage />} />
					<Route path="/users/svgs" element={<SvgIconPage />} />
					<Route
						path="/users/checkmarks"
						element={<CheckmarkPage />}
					/>
					<Route path="/users/badges" element={<BadgePage />} />
					<Route
						path="/users/notification-simple"
						element={<NotificationSimple />}
					/>
					<Route
						path="/users/notification-timer"
						element={<NotificationTimer />}
					/>
					<Route
						path="/users/notification-emoji"
						element={<NotificationEmoji />}
					/>
					<Route path="/users/form" element={<FormPage />} />
					<Route
						path="/users/data-tables"
						element={<DataTableDemo />}
					/>
					<Route path="/users/drawers" element={<Drawers />} />
					<Route path="/users/modals" element={<Modals />} />
					<Route
						path="/users/view-profile"
						element={<ViewProfile />}
					/>
					<Route path="/users/pages" element={<Pages />} />
					<Route path="/users/userlist" element={<UserList />} />
				</Route>
			</Routes>
		</>
	)
}

export default App

import React, { useState, useRef, useEffect } from 'react'
// import Icon from "../../assets/icons";
import Icon from '../ui/icon'
import Badges from '../ui/Badges'
const Notifications = () => {
	const [showNotifications, setShowNotifications] = useState(false)
	const closeref = useRef()
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (closeref.current && !closeref.current.contains(event.target)) {
				setShowNotifications(false)
			}
		}
		document.addEventListener('mousedown', handleOutsideClick)
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [])
	return (
		<>
			<div
				class="dropdown dropdown-notification ms-xl-4 relative ms-3 cursor-pointer"
				ref={closeref}
			>
				<div
					href=""
					className="relative"
					data-bs-toggle="dropdown"
					data-bs-auto-close="outside"
					onClick={() => setShowNotifications((prev) => !prev)}
				>
					<Badges
						text="41"
						className="badge-important badge-tiny"
						positionClass="absolute -top-0.5 left-2"
					/>
					{/* <small>321</small> */}
					<Icon
						name="line-notification-list"
						width="24px"
						height="24px"
						className="icon-fill-primary300"
					/>
				</div>
				{showNotifications && (
					<div class="dropdown-menu dropdown-menu-end !right-[-8px] !top-0 !z-auto !bg-[#fff]">
						<div class="dropdown-menu-header">
							<h6 class="dropdown-menu-title">Notifications</h6>
						</div>
						<ul class="list-group">
							<li class="list-group-item">
								<div class="list-group-body ">
									<span class="badge-success px-[7.15px] pb-1 pt-[2.5px]">
										Budges
									</span>
									<p className="!text-[13px]">
										Lorem ipsum dolor sit amet, consectetur
										adipisicing elit, sed do eiusmod tempor
										incididunt ut labore et dolore magna
										aliqua.
									</p>
									<span>Aug 20 08:55am</span>
								</div>
							</li>
							<li class="list-group-item">
								<div class="list-group-body">
									<span className="badge-important px-[7.15px] pb-1 pt-[2.5px]">
										Budges
									</span>
									<p className="!text-[13px]">
										Lorem ipsum dolor sit amet, consectetur
										adipisicing elit, sed do eiusmod tempor
										incididunt ut labore et dolore magna
										aliqua.
									</p>
									<span>Aug 20 08:55am</span>
								</div>
							</li>
							<li class="list-group-item">
								<div class="list-group-body">
									<span class="badge-info px-[7.15px] pb-1 pt-[2.5px]">
										Budges
									</span>
									<p className="!text-[13px]">
										Lorem ipsum dolor sit amet, consectetur
										adipisicing elit, sed do eiusmod tempor
										incididunt ut labore et dolore magna
										aliqua.
									</p>
									<span>Aug 20 08:55am</span>
								</div>
							</li>
							<li class="list-group-item">
								<div class="list-group-body">
									<span class="badge-warning px-[7.15px] pb-1 pt-[2.5px]">
										Budges
									</span>
									<p className="!text-[13px]">
										Lorem ipsum dolor sit amet, consectetur
										adipisicing elit, sed do eiusmod tempor
										incididunt ut labore et dolore magna
										aliqua.
									</p>
									<span>Aug 20 08:55am</span>
								</div>
							</li>
							<li class="list-group-item">
								<div class="list-group-body">
									<span class="badge-inverse px-[7.15px] pb-1 pt-[2.5px]">
										Budges
									</span>
									<p className=' !text-[13px]'>
										Lorem ipsum dolor sit amet, consectetur
										adipisicing elit, sed do eiusmod tempor
										incididunt ut labore et dolore magna
										aliqua.
									</p>
									<span>Aug 20 08:55am</span>
								</div>
							</li>
						</ul>
						<div class="dropdown-menu-footer">
							<a href="">Show all Notifications</a>
						</div>
					</div>
				)}
			</div>
		</>
	)
}
export default Notifications

import React, { useEffect, useState } from 'react'
import DrawerHeader from './drawers/DrawerHeader'
import DrawerFooter from './drawers/DrawerFooter'
import DrawerBody from './drawers/DrawerBody'
import { auto } from '@popperjs/core'
export default function Drawer({ ...props }) {
	const leftClassNames = `fixed top-0 z-[1001] h-screen px-4 pt-1 bg-white ${props.size === 'full' ? 'min-w-' + props.size : props.size === '40rem' ? 'w-[40rem]' : props.size === '25%' ? 'w-1/4' : 'max-w-' + props.size}`
	const rightClassNames = `fixed top-0 right-0 z-[1001] h-screen px-4 pt-1  bg-white ${props.size === 'full' ? 'min-w-' + props.size : props.size === '40rem' ? 'w-[40rem]' : props.size === '25%' ? 'w-1/4' : 'max-w-' + props.size}`
	const bottomClassNames = `fixed bottom-0 left-0 z-[1001] h-80 px-4 pt-1  overflow-y-auto bg-white w-full h-80`
	const topClassNames = `fixed top-0 left-0 z-[1001] h-80 px-4 pt-1  overflow-y-auto bg-white w-full h-80`
	const [openDrawer, setOpenDrawer] = useState(false)
	const [extraClass, setExtraClass] = useState(false)

	useEffect(() => {
		if (props.showDrawer === true) {
			setOpenDrawer(props.showDrawer)
			setTimeout(() => {
				setExtraClass(true)
			}, 200)
		} else {
			setExtraClass(false)
			setTimeout(() => {
				setOpenDrawer(props.showDrawer)
			}, 500)
		}

		return () => {
			setExtraClass(false)
		}
	}, [props.showDrawer])

	return openDrawer ? (
		<>
			{props.drawerPosition === 'left' ? (
				<>
					<div
						id="drawer-example"
						className={
							leftClassNames +
							(extraClass ? ' left-0 ' : ' left-[-35%]') +
							''
						}
						style={
							props.scrollableFooter
								? {
										transitionProperty: 'left',
										transitionDuration: '0.2s',
										transitionTimingFunction: 'linear'
									}
								: {
										transitionProperty: 'left',
										transitionDuration: '0.2s',
										transitionTimingFunction: 'linear'
									}
						}
					>
						<div className="relative h-full ">
							<DrawerHeader {...props} />
							{props.scrollableFooter ? (
								<div
									style={{
										overflow: auto,
										maxHeight: '90vh'
									}}
								>
									<DrawerBody {...props} />
									<DrawerFooter {...props} />
								</div>
							) : (
								<>
									<DrawerBody {...props} />
									<DrawerFooter {...props} />
								</>
							)}
						</div>
					</div>
				</>
			) : null}
			{props.drawerPosition === 'right' ? (
				<>
					<div
						id="drawer-example"
						className={
							rightClassNames +
							(extraClass ? ' right-0' : ' right-[-35%]')
						}
						style={{
							transform: 'translateZ(0)',
							transitionProperty: 'right',
							transitionDuration: '0.2s',
							transitionTimingFunction: 'linear'
						}}
					>
						<div className="relative h-full">
							<DrawerHeader {...props} />
							<DrawerBody {...props} />
							<DrawerFooter {...props} />
						</div>
					</div>
				</>
			) : null}
			{props.drawerPosition === 'top' ? (
				<>
					<div
						id="drawer-example"
						className={
							topClassNames +
							(extraClass ? ' top-0' : ' top-[-35%]')
						}
						style={{
							transitionProperty: 'top',
							transitionDuration: '0.2s',
							transitionTimingFunction: 'linear',
							overflow: 'hidden'
						}}
					>
						<div className="relative h-full">
							<DrawerHeader {...props} />
							<DrawerBody {...props} />
							<DrawerFooter {...props} />
						</div>
					</div>
				</>
			) : null}
			{props.drawerPosition === 'bottom' ? (
				<>
					<div
						id="drawer-example"
						className={
							bottomClassNames +
							(extraClass ? ' bottom-0' : ' bottom-[-35%]')
						}
						style={{
							transitionProperty: 'bottom',
							transitionDuration: '0.2s',
							transitionTimingFunction: 'linear',
							overflow: 'hidden'
						}}
					>
						<div className="relative h-full">
							<DrawerHeader {...props} />
							<DrawerBody {...props} />
							<DrawerFooter {...props} />
						</div>
					</div>
				</>
			) : null}

			{props.overlay === 'custom' ? (
				<>
					<div
						className="fixed inset-0 opacity-100"
						style={{
							backdropFilter: `${extraClass ? 'blur(3px)' : 'blur(0px)'}`,
							backgroundColor: `${extraClass ? 'rgba(0,0,0,0.55)' : 'rgba(0,0,0,0)'}`,
							transitionProperty: 'opacity',
							transitionDuration: '200ms',
							transitionTimingFunction: 'ease'
						}}
						onClick={() => props.onClose()}
					></div>
				</>
			) : (
				<>
					<div
						className={
							'fixed inset-0 bg-black ' +
							(extraClass ? 'opacity-50' : 'opacity-0')
						}
						style={{
							transitionProperty: 'opacity',
							transitionDuration: '0.2s',
							transitionTimingFunction: 'linear'
						}}
						onClick={() => props.onClose()}
					></div>
				</>
			)}
		</>
	) : null
}

import DataTableWrapper from '../../components/ui/DataTables'
import IconButton from '../../components/ui/Buttons/Icon-button'
import NEWUSERDATA from './USERSJSON'
import {
	faTrash,
	faFileExport,
	faPlus,
	faPen,
	faEyeSlash,
	faKey,
	faFileArrowDown
} from '@fortawesome/free-solid-svg-icons'
const UserList = () => {
	// print the user selected data
	const HandleData = (data) => {
		console.log(data)
	}

	const HeaderButtons = ({ exportCSV }) => (
		<div className="flex flex-wrap items-center justify-start  gap-[3px]">
			<IconButton
				Icon={faPlus}
				size="sm"
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Add User
			</IconButton>
			<IconButton
				Icon={faPlus}
				size={'sm'}
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Create Duplicate Record
			</IconButton>
			<IconButton
				Icon={faPen}
				size={'sm'}
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Edit User
			</IconButton>
			<IconButton
				Icon={faTrash}
				size={'sm'}
				className="btn btn-large btn-danger flex gap-1.5"
			>
				Delete User
			</IconButton>
			<IconButton
				Icon={faEyeSlash}
				size={'sm'}
				className="btn btn-large btn-edit flex gap-1.5"
			>
				Disable User
			</IconButton>
			<IconButton
				Icon={faKey}
				size={'sm'}
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Password Reset
			</IconButton>
			<IconButton
				Icon={faFileExport}
				size={'sm'}
				className="btn btn-large btn-primary flex gap-1.5"
				onClick={() => exportCSV()}
			>
				Export List
			</IconButton>
			<IconButton
				Icon={faFileArrowDown}
				size={'sm'}
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Import User
			</IconButton>
		</div>
	)
	const dopdownOptions = [
		'All',
		'Full Name',
		'OU1 (Group)',
		'OU2 (Company)',
		'OU3 (Department)'
	]
	let COLUMNS = [
		{
			selectionMode: 'multiple',
			headerStyle: { width: '3rem !important' },
			style: { width: '3rem !important' },
			className: 'chk-dt chk-primary',
			id: 'red'
		},
		{
			sortable: true,
			header: 'Full Name',
			field: 'full name',
			style: { width: '16%' },
			headerStyle: { width: '16%' }
		},
		{
			sortable: true,
			header: 'OU1 (Group)',
			field: 'ou1 (group)',
			style: { width: '16%' },
			headerStyle: { width: '16%' }
		},
		{
			sortable: true,
			header: 'OU2 (Company)',
			field: 'ou2 (company)',
			style: { width: '16%' },
			headerStyle: { width: '16%' }
		},
		{
			sortable: true,
			header: 'OU3 (Department) ',
			field: 'ou3 (department)',
			style: { width: '16%' },
			headerStyle: { width: '16%' }
		},
		{
			sortable: true,
			header: 'Role',
			field: 'role',
			style: { width: '16%' },
			headerStyle: { width: '16%' }
		},
		{
			sortable: true,
			header: 'Status',
			field: 'status',
			style: { width: '16%' },
			headerStyle: { width: '16%' }
		}
	]
	return (
		<DataTableWrapper
			products={NEWUSERDATA}
			HeaderButtons={HeaderButtons}
			options={dopdownOptions}
			columns={COLUMNS}
			onDataSelection={HandleData}
		/>
	)
}
export default UserList

import React from 'react'
const Input = ({
	id,
	value,
	name,
	onChange,
	placeholder,
	type,
	className,
	forgotPassword,
	readOnly,
	...props
}) => {
	return (
		<input
			type={type}
			name={name}
			id={id}
			className={className}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			readOnly={readOnly}
			{...props}
		/>
	)
}
export default Input

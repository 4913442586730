import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import tarun from '../../../assets/img/finger_print.jpg'
import abhay from '../../../assets/img/community_login_cover_c.jpg'
import vikram from '../../../assets/img/authenticate-bg.png'
const SelectSiganture = ({ onChange }) => {
	const [selectedImage, setSelectedImage] = useState(null)
	const [show, setShow] = useState(false)
	const [userName, setUserName] = useState('')
	const [userInput, setUserInput] = useState('')
	const [croppedImage, setCroppedImage] = useState(null)
	const [imageCoardinates, setImageCoardinates] = useState({
		width: '',
		height: ''
	})
	// collect user input
	const handleInputChange = (e) => {
		setUserInput(e.target.value)
		setUserName(e.target.value)
	}
	const DropDownData = [
		{
			name: 'Tarun',
			url: tarun
		},
		{
			name: 'Vikram',
			url: vikram
		},
		{
			name: 'Abhay',
			url: abhay
		}
	]
	const ShowHandler = () => {
		setShow((prev) => !prev)
	}
	// store the selected signature
	const DropDownHandler = (user) => {
		setUserName(user.name)
		setSelectedImage(user.url)
		ShowHandler()
	}
	// crop and create url of selected signature
	const HandleCrop = () => {
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')

		if (selectedImage) {
			const img = new Image()
			img.onload = () => {
				const aspectRatio = img.width / img.height

				// Calculate new dimensions to fit within the canvas while maintaining aspect ratio
				let newWidth = canvas.width
				let newHeight = canvas.width / aspectRatio

				if (newHeight > canvas.height) {
					newHeight = canvas.height
					newWidth = canvas.height * aspectRatio
				}

				canvas.width = newWidth
				canvas.height = newHeight

				ctx.clearRect(0, 0, canvas.width, canvas.height)
				ctx.drawImage(img, 0, 0, newWidth, newHeight)

				// Access width and height of the new image
				const newImageWidth = newWidth
				const newImageHeight = newHeight
				setImageCoardinates({
					width: newImageWidth,
					height: newImageHeight
				})

				const dataUrl = canvas.toDataURL()
				// console.log('dataUrl')
				onChange(dataUrl)
				setCroppedImage(dataUrl)
			}
			img.src = selectedImage
		}
	}
	// clear the selected signature
	const CancelHandler = () => {
		setSelectedImage(null)
		setCroppedImage(null)
	}
	return (
		<>
			<div className="flex min-h-[329px] flex-col justify-between gap-4">
				<div className="width-full flex h-[250px] items-center  justify-center bg-white">
					{selectedImage && (
						<div className="h-[150px] w-[190px]">
							<img
								src={selectedImage}
								alt="Selected"
								style={{ maxWidth: '100%', maxHeight: '100%' }}
							/>
						</div>
					)}
				</div>
				<div className="flex items-end justify-between gap-1 ">
					<div className="form-group relative mb-0">
						<label className="label">Select Image</label>
						<input
							className="form-control w-[170px]  rounded-[1px] 
              focus:border-[#6d6d6d] focus:outline-none"
							type="text "
							placeholder="Select Signature"
							onClick={ShowHandler}
							value={userName}
							onChange={handleInputChange}
						/>
						<FontAwesomeIcon
							className="absolute right-[6px] top-[41px] cursor-pointer text-[#555]"
							icon={faAngleDown}
							onClick={ShowHandler}
						/>
						{show && !userInput && (
							<div
								className="bg-dark absolute 
              z-10 w-[170px] overflow-auto rounded-b rounded-t-none bg-[#fff]
              shadow-md "
							>
								<ul>
									{DropDownData.map((users) => (
										<li
											className="px-[7.5px] py-[8px] text-[14px] 
                  hover:bg-[#e1dfdd] "
											onClick={() =>
												DropDownHandler(users)
											}
										>
											<img
												src={users.url}
												style={{ height: '30px' }}
											/>
										</li>
									))}
								</ul>
							</div>
						)}
						{show && userInput && (
							<div
								className="bg-dark absolute 
                z-10 w-[170px] overflow-auto rounded-b rounded-t-none bg-[#fff]
                shadow-md "
							>
								<ul>
									{DropDownData.filter((user) =>
										user.name
											.toLocaleLowerCase()
											.includes(
												userInput.toLocaleLowerCase()
											)
									).map((users) => (
										<li
											className="px-[7.5px] py-[8px] text-[14px] 
                    hover:bg-[#e1dfdd] "
											onClick={() =>
												DropDownHandler(users)
											}
										>
											<img
												src={users.url}
												style={{ height: '30px' }}
											/>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
					<div className="flex flex-wrap-reverse gap-[3px]">
						<button
							className="btn btn-primary btn-large "
							onClick={HandleCrop}
						>
							Crop
						</button>
						<button
							className="btn btn-large btn-default"
							onClick={CancelHandler}
						>
							Clear
						</button>
					</div>
				</div>
			</div>
			{/* {croppedImage && <img src={croppedImage} alt='cropped-Image' style={{width:imageCoardinates.width,height:imageCoardinates.height}}/>} */}
		</>
	)
}
export default React.memo(SelectSiganture)

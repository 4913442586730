import React from 'react'
import Badge from '../../components/ui/Badges'
const BadgePage = () => {
	return (
		<div className="p-[30px]">
			<p className="small-text">COMPONENT</p>
			<h3>BADGE</h3>
			<hr />
			{/* Common Icons */}
			<div className="mt-5 flex flex-wrap items-center gap-[5px]">
				<div className="small-text">DEFAULT BADGES</div>
			</div>
			<div className="mt-5 flex flex-wrap items-center gap-[5px]">
				<Badge text="2" />
				<Badge text="99+" />
				<Badge text="PRIMARY" />
				<Badge text="COMPLETE" />
				<Badge text="SUCCESS" />
				<Badge text="WARNING" />
				<Badge text="DANGER" />
				<Badge text="GREY" />
			</div>

			{/* Color Badges */}
			<div className="mt-5 flex flex-wrap items-center gap-[5px]">
				<div className="small-text">COLOR BADGES</div>
			</div>
			<div className="small-text mt-1">
				.badge-primary300, .badge-success300 .badge-warning300
				.badge-danger300 .badge-complete300 .badge-grey300
			</div>
			<div className="mt-4 flex flex-wrap items-center gap-[5px]">
				<Badge text="99+" className="badge-primary300" />
				<Badge text="SUCCESS" className="badge-success300" />
				<Badge text="WARNING" className="badge-warning300" />
				<Badge text="DANGER" className="badge-danger300" />
				<Badge text="COMPLETE" className="badge-complete300" />
				<Badge text="SOMETHING" className="badge-grey300" />
			</div>

			{/* Size Badges */}
			<div className="mt-5 flex flex-wrap items-center gap-[5px]">
				<div className="small-text">SIZE BADGES</div>
			</div>
			<div className="small-text mt-1">.badge-default .badge-large</div>
			<div className="mt-[5px] flex flex-wrap items-center gap-[5px]">
				<Badge text="99+" className="badge-primary300 badge-default" />
				<Badge
					text="SUCCESS"
					className="badge-success300 badge-default"
				/>
				<Badge
					text="WARNING"
					className="badge-warning300 badge-default"
				/>
				<Badge
					text="DANGER"
					className="badge-danger300 badge-default"
				/>
				<Badge
					text="COMPLETE"
					className="badge-complete300 badge-default"
				/>
				<Badge
					text="SOMETHING"
					className="badge-grey300 badge-default"
				/>
			</div>
			<div className="mt-2 flex flex-wrap items-center gap-[5px]">
				<Badge text="99+" className="badge-primary300 badge-large" />
				<Badge
					text="SUCCESS"
					className="badge-success300 badge-large"
				/>
				<Badge
					text="WARNING"
					className="badge-warning300 badge-large"
				/>
				<Badge text="DANGER" className="badge-danger300 badge-large" />
				<Badge
					text="COMPLETE"
					className="badge-complete300 badge-large"
				/>
				<Badge text="SOMETHING" className="badge-grey300 badge-large" />
			</div>

			<div className="mt-2 flex flex-wrap items-center gap-[5px]">
				<Badge text="99+" className="badge-primary300 badge-tiny" />
				<Badge text="SUCCESS" className="badge-success300 badge-tiny" />
				<Badge text="WARNING" className="badge-warning300 badge-tiny" />
				<Badge text="DANGER" className="badge-danger300 badge-tiny" />
				<Badge
					text="COMPLETE"
					className="badge-complete300 badge-tiny"
				/>
				<Badge text="SOMETHING" className="badge-grey300 badge-tiny" />
			</div>
		</div>
	)
}

export default BadgePage

import React from 'react'
import image from '../../assets/img/avtar.jpg'
import Icon from '../../components/ui/icon'
const Profile = () => {
	const SOCIALICONS = [
		{
			href: '',
			className: 'bg-[#212830] text-[#fff] ',
			iconname: 'social-github'
		},
		{
			href: '',
			className: 'bg-[#1c96e9] text-[#fff] ',
			iconname: 'social-twitter'
		},
		{
			href: '',
			className: 'bg-[#0077b0] text-[#fff] ',
			iconname: 'social-linkedin'
		},
		{
			href: '',
			className: 'bg-[#007ef7] text-[#fff] ',
			iconname: 'social-messenger'
		},
		{
			href: '',
			className: 'bg-[#e80071] text-[#fff] ',
			iconname: 'social-instagram'
		}
	]
	return (
		<>
			<div className="flex  w-full">
				<div className="min-w-[140px] max-w-[140px]">
					<img
						src={image}
						alt=""
						style={{ width: '100% !important' }}
					/>
				</div>
				<div className="flex flex-1 flex-col pl-[50px] ">
					<div className="profile-top-info  flex-1">
						<div className="mb-6 flex justify-between ">
							<div>
								<h5 className="mb-1 mt-0 font-semibold">
									{' '}
									John Fernandis
								</h5>
								<p className="m-0 p-0 ">
									Senior Software Engineer, Google{' '}
								</p>
							</div>
							<div className="mt-[3px] ">
								<ul className="m-0 flex items-center pl-8">
									{SOCIALICONS.map((items) => {
										return (
											<li className="list none ml-[5px] p-0 ">
												<a
													href={items.href}
													className={`flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-sm ${items.className}`}
												>
													<Icon
														name={items.iconname}
														width={'20px'}
														height={'20px'}
														className="icon-fill-white"
														fillColor={'grey'}
														viewbox={'0 0 23 23'}
													/>
												</a>
											</li>
										)
									})}
								</ul>
							</div>
						</div>
					</div>
					<div className="row grid grid-cols-12">
						<div className="col-span-12 md:col-span-12  lg:col-span-8 lg:w-full xl:col-span-6 ">
							<div className="pr-10 ">
								<div>
									<h3 className="mb-2 mt-0 text-[15px] font-semibold text-[#212830]">
										About
									</h3>
								</div>
								<div>
									<p className="mb-4 mt-0">
										Lorem ipsum dolor sit amet, consectetur
										adipisicing elit, sed do eiusmod tempor
										incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim veniam, quis
										nostrud exercitation ullamco laboris
										nisi ut aliquip ex ea commodo consequat.
										Duis aute irure dolor in reprehenderit
										in voluptate velit esse cillum dolore eu
										fugiat nulla. Duis aute irure dolor in
										reprehenderit in voluptate velit esse
										cillum dolore eu fugiat nulla. Duis aute
										irure dolor in reprehenderit in
										voluptate velit esse cillum dolore eu
										fugiat nulla. Duis aute irure dolor in
										elit cillum dolore eu fugiat nulla.
									</p>
								</div>
							</div>
						</div>
						<div className=" col-span-12 md:col-span-6 lg:col-span-2 xl:col-span-3">
							<div className="mb-9">
								<div>
									<h3 className="mb-2 mt-0 text-[15px] font-semibold text-[#212830]">
										Employee ID
									</h3>
								</div>
								<div>
									<p className="mb-4 mt-0">A-366620</p>
								</div>
							</div>
							<div className="mb-0">
								<div>
									<h3 className="mb-2 mt-0 text-[15px] font-semibold text-[#212830]">
										Date of Joining
									</h3>
								</div>
								<div>
									<p className="mb-4 mt-0">A-366620</p>
								</div>
							</div>
						</div>
						<div className="col-span-12 md:col-span-6  lg:col-span-3">
							<div className="mb-9 ">
								<div>
									<h3 className="mb-2 mt-0 text-[15px] font-semibold text-[#212830]">
										OU3(Department)
									</h3>
								</div>
								<div>
									<p className="mb-4 mt-0">Finance</p>
								</div>
							</div>
							<div className="mb-0">
								<div>
									<h3 className="mb-2 mt-0 text-[15px] font-semibold text-[#212830]">
										Security Clearence
									</h3>
								</div>
								<div>
									<p className="mb-4 mt-0">Genral</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Profile

import React, { useEffect } from 'react'
import parse from 'html-react-parser'
import IconButton from '../Buttons/Icon-button'
import { faXmark, faSave, faClose } from '@fortawesome/free-solid-svg-icons'
export default function ModalLg({ ...props }) {
	return (
		<>
			<div
				className={`fixed left-2/4 top-2/4 z-[1002] max-w-xl -translate-x-2/4 -translate-y-2/4 ${props.extraClass ? 'opacity-100' : 'opacity-0'}`}
				style={{
					transitionProperty: 'opacity',
					transitionDuration: '.1s',
					transitionTimingFunction: 'linear'
				}}
			>
				<div
					className={`relative flex flex-col rounded-sm border-0 bg-white shadow-lg outline-none focus:outline-none`}
				>
					<div className="px-4 pt-1">
						<div className="modal-header  box-border ">
							<div className="flex items-center justify-between border-b">
								<h5 className="">{props.title}</h5>
								<IconButton
									type="button"
									className="relative flex h-5 w-6  items-center justify-center rounded-sm bg-transparent text-sm text-gray-400 hover:bg-transparent hover:text-gray-500"
									onClick={() => props.onClose()}
									children=""
									Icon={faClose}
									size={'lg'}
								/>
							</div>
						</div>
					</div>
					<div
						className="modal-body p-4"
						style={
							props.scrollable
								? { overflowY: 'scroll', maxHeight: '327px' }
								: {}
						}
					>
						<div className="relative flex-auto">
							{parse(props.body)}
						</div>
					</div>

					<div className="px-4">
						<div className="modal-footer flex  items-center justify-between border-t">
							<h5 className="font-normal">Additional Text</h5>
							<div className="flex items-center justify-end gap-[3px]">
								<IconButton
									Icon={faXmark}
									className="btn-default btn-large flex gap-1.5"
									// className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
									type="button"
									onClick={() => props.onClose()}
									children="Close"
								/>
								<IconButton
									Icon={faSave}
									// size={'sm'}
									className="btn-success btn-large flex gap-1.5"
									// className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
									type="button"
									onClick={() => props.onClose()}
									children="Save Changes"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

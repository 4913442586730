import React, { useState } from 'react'
import MultiSelectdropdown from '../../components/Dropdowns/MultiSelectdropdown'
const data = [
	{ id: 0, name: '(A0001) SAP ERP' },
	{ id: 1, name: '(A0002) GRC Toolbox' },
	{ id: 2, name: '(A0003) Dynamics 365 CRM' },
	{ id: 3, name: '(A0004) CMDB SHW00X' }
]
const MultiSelectDD = () => {
	const [lists, setLists] = useState([])
	const [items, setItems] = useState(data)
	const add = (data) => {
		setLists([...lists, data])
		setItems(items.filter((item) => item.id !== data.id))
		// setShowDropDown(prev => !prev)
	}
	const removeItem = (item) => {
		setLists((prevLists) =>
			prevLists.filter((listItem) => listItem.id !== item.id)
		)
		setItems([...items, item])
	}

	return (
		<MultiSelectdropdown
			Items={items}
			lists={lists}
			onItemsAdd={(item) => add(item)}
			onItemsRemove={(item) => removeItem(item)}
		/>
	)
}

export default MultiSelectDD

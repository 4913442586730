import React, { useState, useEffect } from 'react'
import Drawer from '../../components/ui/Drawer'
import TextBox from '../../components/ui/TextBox'
import SimpleDropDown from '../../components/Dropdowns/Simpledropdown'
import ElasticTextBox from '../../components/ui/Elastic-textbox'
import SearchableDatepicker from '../../components/ui/Searchable-Datepicker'
import IconButton from '../../components/ui/Buttons/Icon-button'
import CountrySelector from '../../components/ui/Countryselctor'
// import PhoneSelector from "../../components/ui/PhoneSelector";
import PhoneSelector from '../../components/ui/PhoneSelector'
import DatePicker from '../../components/ui/Datepicker'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
const PersonalInfoDrawer = ({ show, setShow }) => {
	const [showDrawer, setShowDrawer] = useState(false)
	const [drawerPosition, setDrawerPosition] = useState('right')
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(false)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)

	const [Formdata, setFormdata] = useState({
		title: '',
		fullname: '',
		dob: '',
		age: '',
		status: '',
		gender: '',
		personalemailid: '',
		personalmobilenumber: '',
		citizenship: '',
		residency: '',
		dualcitizenship: '',
		secondcitizenship: '',
		identificationdocument: '',
		idnumber: '',
		expirydate: '',
		issuelocation: '',
		homeaddress: '',
		permenantaddress: '',
		city: '',
		pincode: '',
		relation: ''
	})

	const [currentDate, setCurrentDate] = useState(null)
	const [selectedDate, setSelectedDate] = useState({
		//searchable date picker
		day: '',
		month: '',
		year: ''
	})
	const [selectedCountry, setSelectedCountry] = useState({
		countryCode: '+91',
		countryName: 'india',
		countryFlag: 'in',
		Number: ''
	})
	const UpdateFields = (obj) => {
		setSelectedCountry({
			...selectedCountry,
			countryCode: obj.countryCode,
			countryName: obj.countryName,
			countryFlag: obj.countryFlag
		})
	}
	const setuserNumber = (obj) => {
		setSelectedCountry({
			...selectedCountry,
			Number: obj.Number
		})
	}

	const [selectedEmergencyCountry, setSelectedEmergencyCountry] = useState({
		countryCode: '+86',
		countryName: 'china',
		countryFlag: 'cn',
		Number: ''
	})

	const UpdateEmergencyCountryFields = (obj) => {
		setSelectedEmergencyCountry({
			...selectedEmergencyCountry,
			countryCode: obj.countryCode,
			countryName: obj.countryName,
			countryFlag: obj.countryFlag
		})
	}
	const setEmergencyuserNumber = (obj) => {
		setSelectedEmergencyCountry({
			...selectedEmergencyCountry,
			Number: obj.Number
		})
	}
	const HandleFieldChanges = (fieldname, value) => {
		setSelectedDate((prevState) => {
			return {
				...prevState,
				[fieldname]: value
			}
		})
	}
	const HandleDateChange = (date) => {
		setCurrentDate(date)
	}
	const HANDLEFORMDATA = (field, value) => {
		setFormdata({
			...Formdata,
			[field]: value
		})
	}

	useEffect(() => {
		setBody(FORM)
	}, [Formdata, selectedDate, selectedCountry, selectedEmergencyCountry])

	const lists = ['Mr.', 'Mrs.']
	const identitfication_documents = [
		'Passport',
		'National ID',
		'Tax Number',
		'Corporate Registration'
	]

	const FORM = (
		<>
			<div className="flex w-[350px] flex-col justify-start pr-1">
				<SimpleDropDown
					label="Title"
					value={Formdata.title}
					options={lists}
					onChange={(value) => HANDLEFORMDATA('title', value)}
				/>
				<TextBox
					labelName={'Full Name'}
					type={'text'}
					value={Formdata.fullname}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>
				<SearchableDatepicker
					HeadingLabel="DOB"
					day={selectedDate.day}
					month={selectedDate.month}
					year={selectedDate.year}
					onChange={(fieldname, value) =>
						HandleFieldChanges(fieldname, value)
					}
				/>
				<TextBox
					labelName={'Age'}
					type={'number'}
					value={Formdata.age}
					onChange={(data) => HANDLEFORMDATA('age', data)}
				/>
				<SimpleDropDown
					label="Status"
					onChange={(value) => HANDLEFORMDATA('status', value)}
					value={Formdata.status}
					options={['Single', 'Married']}
				/>
				<SimpleDropDown
					label="Gender"
					onChange={(value) => HANDLEFORMDATA('gender', value)}
					value={Formdata.gender}
					options={['Male', 'Female', 'Other']}
				/>
				<TextBox
					labelName={'Personal Email ID'}
					onChange={() => HANDLEFORMDATA()}
					type={'email'}
				/>
				<PhoneSelector
					labelName={'Personal Mobile Number'}
					currentCountry={selectedCountry}
					onCountrySelect={(obj) => UpdateFields(obj)}
					onUSerInputChange={(obj) => setuserNumber(obj)}
				/>
				<CountrySelector
					label="Citizenship"
					onChange={(data) => console.log(data)}
				/>
				<TextBox
					labelName={'Residency'}
					onChange={(data) => HANDLEFORMDATA()}
					type={'text'}
				/>
				<SimpleDropDown
					label="Dual Citizenship"
					onChange={(data) => HANDLEFORMDATA('dualcitizenship', data)}
					value={Formdata.dualcitizenship}
					options={['Yes', 'No']}
				/>
				<CountrySelector
					label="Second Citizenship"
					onChange={(data) => console.log(data)}
				/>
				<SimpleDropDown
					label="Identification Document"
					value={Formdata.identificationdocument}
					onChange={(value) =>
						HANDLEFORMDATA('identificationdocument', value)
					}
					options={identitfication_documents}
				/>
				<TextBox
					labelName={'ID Number'}
					onChange={(data) => HANDLEFORMDATA()}
					type={'text'}
				/>
				<DatePicker
					labelName="Expiry Date"
					onDateChange={(date) => HandleDateChange(date)}
					currentDate={currentDate}
				/>
				<CountrySelector
					label="Issue Location"
					onChange={(data) => console.log(data)}
				/>
				<ElasticTextBox
					onChange={(data) => HANDLEFORMDATA()}
					labelName={'Home Address '}
				/>
				<ElasticTextBox
					onChange={(data) => HANDLEFORMDATA()}
					labelName={'Permenant Address '}
				/>
				<TextBox
					labelName={'City'}
					onChange={(data) => HANDLEFORMDATA()}
					type={'text'}
				/>
				<TextBox
					labelName={'Pin Code'}
					onChange={(data) => HANDLEFORMDATA()}
					type={'text'}
				/>
				<TextBox
					labelName={'State'}
					onChange={(data) => HANDLEFORMDATA()}
					type={'text'}
				/>
				<CountrySelector
					label="Country"
					onChange={(data) => console.log(data)}
				/>
				<TextBox
					labelName={'Emergency Contact Name'}
					onChange={(data) => HANDLEFORMDATA()}
					type={'text'}
				/>
				<SimpleDropDown
					label="Relationship"
					onChange={(data) => HANDLEFORMDATA('relation', data)}
					value={Formdata.relation}
					options={['Single', 'Married']}
				/>

				<PhoneSelector
					labelName={'Emergency Contact  Number'}
					currentCountry={selectedEmergencyCountry}
					onCountrySelect={(obj) => UpdateEmergencyCountryFields(obj)}
					onUSerInputChange={(obj) => setEmergencyuserNumber(obj)}
				/>
				<TextBox
					labelName={'Emergency Contact Email'}
					onChange={(data) => HANDLEFORMDATA()}
					type={'text'}
				/>
			</div>
		</>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setShow(false)
	}

	useEffect(() => {
		setShowDrawer(show)
		document.body.classList.toggle('no-scroll', show)
		setScrollable(show)
		setBody(FORM)
	}, [show])

	useEffect(() => {
		setShowDrawer(show)
		setScrollable(show)
	}, [])

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "></h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Learn more
				</IconButton>
				<IconButton
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)
	return (
		<Drawer
			label="Personal Info"
			size={size}
			body={body}
			drawerFooterBody={drawerFooterBody}
			scrollable={scrollable}
			onClose={handleDrawerClose}
			overlay={overlay}
			scrollableFooter={scrollableFooter}
			drawerPosition={drawerPosition}
			showDrawer={showDrawer}
		/>
	)
}
export default PersonalInfoDrawer

import React from 'react'
import parse from 'html-react-parser'

export default function DrawerBody(props) {
	return (
		<div
			className="drawer-body z-50 py-4  "
			style={
				props.scrollable
					? { height: 'calc(100% - 94px)', overflowY: 'auto' }
					: null
			}
		>
			{typeof props.body === 'string' ? parse(props.body) : props.body}
		</div>
	)
}

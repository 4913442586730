import React, { useRef, useEffect } from 'react'
import autosize from 'autosize'
const ElasticTextBox = ({ labelName, placeHolder, className, onChange }) => {
	const textareaRef = useRef(null)
	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.focus()
			autosize(textareaRef.current) //this is for increasing the height when text is too large
		}
	}, [])

	// send the user input to callback function (onChange)
	const HandleChange = (event) => {
		onChange(event.target.value)
	}
	return (
		<div className="form-group">
			{labelName && <label className="label">{labelName}</label>}
			<div className="input-outer">
				<textarea
					onChange={(e) => HandleChange(e)}
					type="text"
					className={`form-control ${className}`}
					placeholder={placeHolder}
					ref={textareaRef}
				/>
			</div>
		</div>
	)
}

export default ElasticTextBox

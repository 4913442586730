import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DrawSignature from './DrawSignature'
import SelectSiganture from './SelectSiganture'
import UploadSignature from './UploadSignature'
import TypeSignature from './TypeSignature'
const Signature = ({
	labelName,
	getTypedSignatureUrl,
	getDrawnSignatureUrl,
	getSelectedSignatureUrl,
	getUploadedSignatureUrl
}) => {
	const [active, setActive] = useState(0)
	const [route, setRoute] = useState('drawsignature')

	const links = [
		{
			id: 0,
			name: 'Draw Signature',
			path: 'drawsignature'
		},
		{
			id: 1,
			name: 'Type Signature',
			path: 'typesiganture'
		},
		{
			id: 2,
			name: 'Upload Signature',
			path: 'uploadsignature'
		},
		{
			id: 3,
			name: 'Select Signature',
			path: 'selectsignature'
		}
	]
	const activeHandler = (id) => {
		setActive(id)
	}
	const LinkClickHandler = (link) => {
		activeHandler(link.id)
		setRoute(link.path)
	}

	const activeLink = '!bg-primary-300 text-[#fff] border-b-[#7252d3]'
	const classname =
		'w-full flex items-center justify-center py-[6px] px-[16px] border-b-[1px] hover:border-b-[#7252d3] text-[#201f1e] text-[14px] hover:!text-white'

	return (
		<div className="form-group mt-4">
			{labelName && <div className="label"> {labelName} </div>}
			<div className="input-outer">
				<div className="flex min-h-[346px] w-full border-[1px] border-solid border-[#d2d0ce] bg-[#f2f2f0] ">
					<div className="min-w-[160px] border-r-[1px]">
						<ul className="">
							{links.map((link) => (
								<li
									className={`hover:!bg-primary-300 hover:border-[#506fd9]${active === link.id ? activeLink : ''}`}
									onClick={() => LinkClickHandler(link)}
									key={link.id}
								>
									<Link
										className={`${classname} ${
											active === link.id ? activeLink : ''
										}`}
									>
										{link.name}
									</Link>
								</li>
							))}
						</ul>
					</div>
					<div className="w-full px-5 py-4 ">
						{route === 'drawsignature' && (
							<DrawSignature
								onChange={(url) => getDrawnSignatureUrl(url)}
							/>
						)}
						{route === 'typesiganture' && (
							<TypeSignature
								onChange={(url) => getTypedSignatureUrl(url)}
							/>
						)}
						{route === 'selectsignature' && (
							<SelectSiganture
								onChange={(url) => {
									getSelectedSignatureUrl(url)
								}}
							/>
						)}
						{route === 'uploadsignature' && (
							<UploadSignature
								onChange={(url) => {
									getUploadedSignatureUrl(url)
								}}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
export default Signature

import React, { useEffect, useState } from 'react'
import Button from '../../components/ui/Buttons/Button'
import Icon from '../../components/ui/icon'
import PersonalInfoDrawer from './PersonalInfoDrawer'
import BusinessinfoDrawer from './BusinessinfoDrawer'
import AdditionalInfoDrawer from './AdditionalInfoDrawer'
import SocialInfoDrawer from './SocialInfoDrawer'
const FormHeader = ({ heading, onEditProfile, setdrawer }) => {
	const [show, setShow] = useState(false)
	const [drawerType, setDrawerType] = useState(setdrawer)
	const HandleDrawerShowClick = () => {
		console.log(drawerType)
		setShow((prev) => !prev)
	}

	return (
		<>
			{' '}
			{drawerType == 'personalinfoedit' && (
				<PersonalInfoDrawer show={show} setShow={setShow} />
			)}
			{drawerType == 'socialinfoedit' && (
				<SocialInfoDrawer show={show} setShow={setShow} />
			)}
			{drawerType == 'busnessinfoedit' && (
				<BusinessinfoDrawer show={show} setShow={setShow} />
			)}
			{drawerType == 'additionalinfoedit' && (
				<AdditionalInfoDrawer show={show} setShow={setShow} />
			)}
			<div className="mb-6 flex flex-wrap items-center justify-between">
				<h5 className="">{heading} </h5>
				<div className="flex flex-wrap gap-1 ">
					<Button
						className="btn-large btn-primary  flex gap-1 px-3"
						isIconBtn={true}
					>
						<Icon
							name="line-download-file"
							width={'16px'}
							height={'15px'}
							className="icon-fill-white"
							viewbox={'0 0 23 23'}
						/>
						Download
					</Button>
					<Button
						className="btn-large btn-primary  flex gap-1 px-3"
						onClick={HandleDrawerShowClick}
						isIconBtn={true}
					>
						<Icon
							name="line-edit-file"
							width={'16px'}
							height={'15px'}
							className="icon-fill-white"
							viewbox={'0 0 23 23'}
						/>
						Edit Profile
					</Button>
				</div>
			</div>
		</>
	)
}
export default FormHeader

import React, { useEffect, useState } from 'react'
import Icon from '../ui/icon'
import { Link } from 'react-router-dom'
const SidebarBody = ({ DATA, onClick }) => {
	const [itemName, setItemName] = useState(null)
	const [showLinks, setShowLinks] = useState({})
	const [showParents, setShowParents] = useState({})
	useEffect(() => {
		DATA.map((items) => {
			setShowParents((prev) => {
				return {
					...prev,
					[items.id]: false
				}
			})
			items.children.map((items) => {
				if (items.id) {
					{
						setShowLinks((prev) => {
							return {
								...prev,
								[items.id]: false
							}
						})
					}
				}
			})
		})
	}, [])
	const toggleParents = (id) => {
		// console.log(id)
		setShowParents((prev) => {
			return {
				...prev,
				[id]: !prev[id]
			}
		})
	}
	const HandleChildClick = () => {
		console.log('clicked')
	}
	const Headings =
		' relative  text-[14px] font-inter-ui grey300 nav-link px-5 py-2 flex justify-start gap-3 items-center hover:bg-contrast-lower hover:cursor-pointer'
	const ListClass =
		'block px-5 py-1.5 grey300 text-[13px] relative indent-[30.5px] before:absolute before:top-[50%] before:left-[26px] before:w-[5px] before:h-[1.5px] before:bg-[#9ea7b1] before:transition-all before:hover:transition-all  before:hover:duration-250 hover:transition-all hover:before:w-[16px] before:hover:left-[20.5px]'
	const HandleClick = (title) => {
		console.log(title)
		setShowLinks((prev) => ({
			...prev,
			[title]: !prev[title]
		}))
	}
	const RESULT = (
		<>
			{DATA.map((parent) => {
				return (
					<div
						className="nav-group show border-b border-b-[#f3f5f9] "
						key={parent.id}
					>
						<span
							onClick={() => toggleParents(parent.id)}
							className="grey300 side-arrow-down relative block  px-5 py-3 text-[11px] uppercase tracking-[.7px] hover:cursor-pointer hover:bg-contrast-lower"
						>
							{parent.Heading}
						</span>
						<ul
							className={`nav nav-sidebar transitions ${showParents[parent.id] ? 'open' : ''}`}
						>
							{parent.children.map((child) => (
								<div
									className="nav-item grey300 w-full"
									key={child.title}
								>
									<li
										className={Headings}
										onClick={() => HandleClick(child.id)}
									>
										<Icon
											name={child.icon}
											width={'20px'}
											height={'20px'}
											className="icon-fill-grey200"
											viewbox={'0 0 25 25'}
											fillColor="grey"
										/>
										<a className="sidebar-arrow-right !grey3000">
											<span>{child.title}</span>
										</a>
									</li>

									{child.data &&
										showLinks[child.id] &&
										child.data.map((lists) => {
											return (
												<li
													className="nav-item"
													onClick={() =>
														console.log(lists.name)
													}
												>
													<nav className="nav nav-sub">
														<Link
															to={lists.href}
															className={
																ListClass
															}
															onClick={() =>
																console.log(
																	lists.href
																)
															}
														>
															{lists.name}
														</Link>
													</nav>
												</li>
											)
										})}
								</div>
							))}
						</ul>
					</div>
				)
			})}
		</>
	)
	return (
		<div id="sidebarMenu" className="sidebar-body relative h-auto ">
			<div className="w-[240px]">{RESULT}</div>
		</div>
	)
}
export default React.memo(SidebarBody)

import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../components/ui/Buttons/Button'
const Pages = () => {
	return (
		<div>
			<div className="row mt-2">
				<div className="flex gap-2">
					<Link to="login">
						{' '}
						<Button
							link=""
							className="btn-xlarge btn-complete"
							onClick={() => console.log('')}
						>
							Log In
						</Button>
					</Link>
					<Link to="forgot-password">
						<Button
							link=""
							className="btn-xlarge btn-complete"
							onClick={() => console.log('')}
						>
							Forgot Password Page
						</Button>
					</Link>
					<Link to="change-password">
						{' '}
						<Button
							link=""
							className="btn-xlarge btn-complete"
							onClick={() => console.log('')}
						>
							Change Password
						</Button>
					</Link>
					<Link to="contact-us">
						{' '}
						<Button
							link=""
							className="btn-xlarge btn-complete"
							onClick={() => console.log('')}
						>
							Contact page
						</Button>
					</Link>
					<Link to="register">
						{' '}
						<Button
							link=""
							className="btn-xlarge btn-complete"
							onClick={() => console.log('')}
						>
							Register page
						</Button>
					</Link>

					<Link to="policy">
						<Button
							link=""
							className="btn-xlarge btn-complete"
							onClick={() => console.log('')}
						>
							Policy
						</Button>
					</Link>
					<Link to="sendmagiclink">
						<Button
							link=""
							className="btn-xlarge btn-complete"
							onClick={() => console.log('')}
						>
							Send Magic Link
						</Button>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default Pages

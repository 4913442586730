import React from 'react'
import Input from '../Inputfield'

const TextBox = ({
	labelName,
	value,
	onChange,
	placeholder,
	type,
	className,
	forgotPassword,
	readOnly,
	...props
}) => {
	// pass the user input to callback function
	const HandleChange = (event) => {
		onChange(event.target.value)
	}
	return (
		<div className={`form-group ${className}`}>
			{labelName && <label className="label">{labelName}</label>}
			<div className="input-outer">
				<Input
					type={type}
					placeholder={placeholder}
					className="form-control"
					onChange={HandleChange}
					value={value}
					readOnly={readOnly}
					{...props}
				/>
			</div>
		</div>
	)
}

export default TextBox

import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Input from '../Inputfield'
const Attachments = ({ labelName, files, onSelect, onRemove }) => {
	const [selectedFiles, setSelectedFiles] = useState(files)

	// selected files will be  passed to the callback function(onSelect)
	const handleFileChange = (event) => {
		const files = event.target.files
		onSelect(files)
	}

	// pass the file to callback function (onRemove)
	const handleRemove = (file) => {
		onRemove(file)
	}

	//remain in sync with data
	useEffect(() => {
		setSelectedFiles(files)
	}, [files])

	return (
		<div className="form-group">
			{labelName && <label className="label"> {labelName} </label>}
			<div className="input-outer">
				<label
					htmlFor="add-attachment"
					className="btn btn-large btn-primary"
				>
					Choose File
				</label>
				<Input
					id="add-attachment"
					type="file"
					className="hidden"
					onChange={handleFileChange}
					multiple
				/>
			</div>
			{selectedFiles.length > 0 && (
				<div>
					{selectedFiles.map((file, index) => (
						<div
							key={index}
							className="mt-[5px] flex items-center justify-between border border-solid border-[#eee] bg-[#f5f5f5]"
						>
							<div className="px-2 py-0.5">{file.name}</div>
							<div
								className="flex h-[28PX] w-[28px] cursor-pointer items-center justify-center bg-[#dc3545] text-[#fff]"
								onClick={() => handleRemove(file)}
							>
								<FontAwesomeIcon icon={faTimes} />
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	)
}
export default Attachments

import React from 'react'

const AdditionalInfo = () => {
	return (
		<div>
			<div className="bg-[#f7f7f7] px-[15px] py-[10px]">
				<div className="multipleinputs ">
					<div className="grid grid-cols-1  md:grid-cols-12 ">
						<div className="col-span-6">
							<div className="form-group">
								<label className="label">Field1</label>
								<div className="text-display">
									<p>demo text</p>
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1  md:grid-cols-12 ">
						<div className="col-span-6">
							<div className="form-group">
								<label className="label">Field2</label>
								<div className="text-display">
									<p>demo text</p>
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1  md:grid-cols-12 ">
						<div className="col-span-6">
							<div className="form-group">
								<label className="label">Field3</label>
								<div className="text-display">
									<p>demo text</p>
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1  md:grid-cols-12 ">
						<div className="col-span-6">
							<div className="form-group">
								<label className="label">Field4</label>
								<div className="text-display">
									<p>demo text</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AdditionalInfo

import { useState, useEffect } from 'react'
import {
	faXmark,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core'
const Alert = ({
	className,
	autoClose = null,
	onClose,
	message,
	Icon = null
}) => {
	const [currentDate, setCurrentDate] = useState(new Date())
	const [timer, setTimer] = useState(autoClose / 1000)
	const [spinnerColor, setSpinnerColor] = useState({
		'alert-warning': '#aa7918',
		'alert-default': '#4b4b4b',
		'alert-danger': '#900f17',
		'alert-info': '#00318e',
		'alert-success': '#04733e'
	})

	//this section is for getting the current day,month and year form the currentDate state which holds the date object
	let monthName = currentDate.toLocaleString('en-US', { month: 'short' })
	let Day = currentDate.getDate()
	let Year = currentDate.getFullYear()
	let FINALDATE = ` ${Day} ${monthName} ${Year}`
	let hours = currentDate.getHours()
	let Minutes = currentDate.getMinutes()
	let Seconds = currentDate.getSeconds()
	let AmPm = hours >= 12 ? 'PM' : 'AM'
	hours = hours % 12 || 12
	hours = hours < 10 ? '0' + hours : hours
	Minutes = Minutes < 10 ? '0' + Minutes : Minutes
	Seconds = Seconds < 10 ? '0' + Seconds : Seconds
	let CURRENTTIME = `${hours}:${Minutes}:${Seconds} ${AmPm}`
	// CURRENTTIME is the final variable that is created by combining above variables and renedering it  on the UI

	useEffect(() => {
		let interval
		if (autoClose !== null) {
			interval = setInterval(() => {
				// Sets up an interval that runs every 1000 milliseconds (1 second)
				setTimer((prevTimer) => prevTimer - 1)
				// Updates the `timer` state by decrementing the previous value by 1
			}, 1000)
		}
		return () => {
			// clear the previous interval to prevent memory leakage
			clearInterval(interval)
		}
	}, [autoClose])

	useEffect(() => {
		if (timer === 0 && autoClose !== null) {
			onClose()
		}
	}, [timer, onClose])

	return (
		<>
			<>
				<div className="alert-annimate">
					<div className={`alerts ${className}`}>
						<div className="flex gap-1">
							{Icon && (
								<div className="text-[13px]">
									<FontAwesomeIcon icon={Icon} />
								</div>
							)}
							<div>{FINALDATE}</div>
							<div>{CURRENTTIME}</div>
							<div>
								<span className="flex-2">{message}</span>
							</div>
						</div>
						<div className="flex items-center gap-2.5">
							<button className="" onClick={onClose}>
								{' '}
								<FontAwesomeIcon icon={faXmark} size="lg" />
							</button>
							<div className="relative flex ">
								{autoClose && (
									<div className="timer rotating-border relative h-5 w-5 flex justify-center items-center px-2.5 py-2.5">
									 {timer}
									</div>
								 
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	)
}

export default Alert

import React, { useState, useRef } from 'react'
import Checkbox from '../Form/Checkbox'
const TextScrollCheck = ({ labelName, Content, checkBoxLabelName }) => {
	const [isChecked, setisChecked] = useState(false)
	const [isdisabled, setisDisabled] = useState(true)
	const scrollRef = useRef()
	//keep track the scrolling position and determine whether the user has reached the bottom of the scrollable area
	const scrollTracker = () => {
		const { scrollTop, scrollHeight, clientHeight } = scrollRef.current
		const threshold = 1
		if (scrollTop + clientHeight + threshold >= scrollHeight) {
			setisDisabled(false)
		}
	}
	return (
		<>
			<div className="form-group">
				{labelName && <label className="label">{labelName} </label>}
				<div className="input-outer flex flex-col gap-5">
					<div
						className="h-[300px] overflow-x-hidden overflow-y-scroll
      border border-solid border-[#d2d0ce]  px-5 py-3.5"
						ref={scrollRef}
						onScroll={() => scrollTracker()}
					>
						{Content}
					</div>
					<Checkbox
						id={checkBoxLabelName}
						disabled={isdisabled}
						label={checkBoxLabelName}
						onChange={() => setisChecked((prev) => !prev)}
						checked={isChecked}
						className="simple-check-square"
						IconName="simple-checkmark"
					/>
				</div>
			</div>
		</>
	)
}

export default TextScrollCheck

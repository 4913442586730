import React from 'react'
import PropTypes from 'prop-types'
/**
 * Badge Component
 *
 * Displays a badge with customizable text (number), styling, and position.
 *
 * Props:
 *   - positionClass: String (optional) - Used for absolute positioning (e.g., 'top-0 right-0').
 *   - className: String (optional) - Additional CSS classes for custom styling.
 *   - text: String | Number (required) - The content to display inside the badge.
 *
 * Usage:
 *   <Badge text="99+" className="custom-class" position="top-0 right-0" />
 *
 * Note: The 'text' prop can accept both numbers and strings.
 */
const Badge = ({
	positionClass = '',
	className = 'badge-primary300 badge-default',
	text
}) => {
	return (
		<div className={`mr-1 inline-block text-center ${positionClass}`}>
			<span
				className={`badge flex items-center justify-center ${className}`}
			>
				<span>{text}</span>
			</span>
		</div>
	)
}
// PropTypes for type checking and documentation
Badge.propTypes = {
	positionClass: PropTypes.string, // String for absolute positioning
	className: PropTypes.string, // String for additional styling classes
	text: PropTypes.oneOfType([
		// Accepts both string and number types
		PropTypes.string,
		PropTypes.number
	]).isRequired
}

export default Badge

import React from 'react'
import parse from 'html-react-parser'
import IconButton from '../Buttons/Icon-button'
import { faXmark, faSave, faClose } from '@fortawesome/free-solid-svg-icons'
export default function ModalXs({ ...props }) {
	return (
		<>
			<div
				className={`fixed left-2/4 top-2/4 z-[1002] max-w-xs -translate-x-2/4 -translate-y-2/4 ${props.extraClass ? 'opacity-100' : 'opacity-0'}`}
				style={{
					transitionProperty: 'opacity',
					transitionDuration: '.1s',
					transitionTimingFunction: 'linear'
				}}
			>
				<div
					className={`relative flex flex-col rounded-sm border-0 bg-white shadow-lg outline-none focus:outline-none`}
				>
					<div className="px-4 pt-1">
						<div className="modal-header ">
							<div className="flex items-center justify-between border-b">
								<h5 className="">{props.title}</h5>
								<IconButton
									type="button"
									className="relative flex h-5 w-6  items-center justify-center rounded-sm bg-transparent text-sm text-gray-400 hover:bg-transparent hover:text-gray-500"
									onClick={() => props.onClose()}
									children=""
									Icon={faClose}
									size={'lg'}
								/>
							</div>
						</div>
					</div>
					<div className="modal-body p-4">
						<div className="relative flex-auto p-0">
							{parse(props.body)}
						</div>
					</div>
					<div className="border-t  px-4 py-2 ">
						<div className="modal-footer   flex items-center justify-end">
							{/* <h5 className='font-normal'>Additional Text</h5> */}
							<div className="flex items-center justify-end gap-[3px]">
								<IconButton
									Icon={faXmark}
									className="btn-default btn-large flex gap-1.5"
									type="button"
									onClick={() => props.onClose()}
									children="Close"
								/>
								<IconButton
									Icon={faSave}
									className="btn-success btn-large flex gap-1.5"
									type="button"
									onClick={() => props.onClose()}
									children="Save Changes"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

import React from 'react'
import Sidebar from '../components/sidebar/Sidebar'
import GeneralFooter from '../components/sidebar/GeneralFooter'
import GeneralHeader from '../components/sidebar/GeneralHeader'
const Layout = ({ children, menudata, onClick, ...props }) => {
	return (
		<div className="">
			<Sidebar data={menudata} onClick={onClick} />
			<div className="main main-app p-6 pb-0 ">
				<div className="main-outer pb-2">
					<GeneralHeader {...props} />
					<div class="card card-one">
						<div class="card-body">{children}</div>
					</div>
				</div>
				<GeneralFooter />
			</div>
		</div>
	)
}

export default Layout

import React, { useEffect, useRef } from 'react'
import SidebarHeader from './SidebarHeader'
import SidebarBody from './SidebarBody'
import SidebarFooter from './SidebarFooter'
import Header from '../Header/Header'
import DATA from './MENUDATA'
const Sidebar = ({ data, onClick }) => {
	const psSidebar = useRef(null)
	useEffect(() => {
		const navLabels = document.querySelectorAll('.sidebar .nav-label')
		const handleNavLabelClick = (e) => {
			e.preventDefault()
			const target = e.currentTarget.nextElementSibling
			target.style.display =
				target.style.display === 'none' ? 'block' : 'none'
			if (psSidebar.current) {
				psSidebar.current.update()
			}
		}
		navLabels.forEach((element) => {
			element.addEventListener('click', handleNavLabelClick)
		})
		return () => {
			navLabels.forEach((element) => {
				element.removeEventListener('click', handleNavLabelClick)
			})
		}
	}, [])

	let RenderData
	if (data) {
		RenderData = data
	} else {
		RenderData = DATA
	}
	return (
		<div className="">
			<div className="sidebar relative overflow-auto">
				<SidebarHeader />
				<SidebarBody DATA={RenderData} onClick={onClick} />
				<SidebarFooter />
			</div>
			<Header />
		</div>
	)
}
export default React.memo(Sidebar)

const MENU_DATA = [
	{
		id: 'user-dashboard',
		Heading: 'Dashboard',
		children: [
			{
				id: 'profile',
				title: 'User Data',
				icon: 'line-bar-chart',
				data: [
					{ href: '/users/view-profile', name: 'View Profile' },
					{ href: '/users/userlist', name: 'User List' }
					// { href: "/users/colors", name: "color" },
				]
			}
		]
	},
	{
		id: 'Components',
		Heading: 'Components',
		children: [
			{
				id: 'Typography',
				title: 'Typography',
				icon: 'line-profile-card',
				data: [
					{ href: '/users/fonts', name: 'Fonts' },
					{ href: '/users/colors', name: 'color' },
					{ href: '/users/svgs', name: 'Svgs' },
					{ href: '/users/badges', name: 'Badges' },
					{ href: '/users/checkmarks', name: 'Checkmarks' },
					{ href: '/users/buttons', name: 'Buttons' }
				]
			},
			{
				id: 'Notifications',
				title: 'Notifications',
				icon: 'line-bell',
				data: [
					{
						href: '/users/notification-simple',
						name: 'Notification Simple'
					},
					{
						href: '/users/notification-timer',
						name: 'Notification Timer'
					},
					{
						href: '/users/notification-emoji',
						name: 'Notification Emoji'
					}
				]
			},
			{
				id: 'Other Components',
				title: 'Other Components',
				icon: 'line-brief-case',
				data: [
					{ href: '/users/form', name: 'Form' },
					{ href: '/users/data-tables', name: 'Data Tables' },
					{ href: '/users/drawers', name: 'Drawers' },
					{ href: '/users/modals', name: 'Modals' }
					// { href: "/users/accordian", name: "Accordian" },
					// { href: "/view-profile", name: "View Profile" },
					// { href: "/pages", name: "Pages" },
				]
			}
		]
	},
	{
		id: 'pages',
		Heading: 'Pages',
		children: [
			{
				id: 'Pages',
				title: 'Pages',
				icon: 'line-download-file',
				data: [
					{ href: '/pages/login', name: 'Login' },
					{ href: '/pages/forgot-password', name: 'Forgot Password' },
					{ href: '/pages/change-password', name: 'Change Password' },
					{ href: '/pages/contact-us', name: 'Contact' },
					{ href: '/pages/register', name: 'Register' },
					{ href: '/pages/policy', name: 'Policy' },

					{ href: '/pages/sendmagiclink', name: 'Send Magiclink' }
				]
			}
		]
	}
]
export default MENU_DATA

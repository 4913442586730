import React from 'react'
import Button from '../Buttons/Button'
import { useState } from 'react'
const ToggleButtons = ({
	classFirst,
	classSecond,
	classThird,
	child1,
	child2,
	child3
}) => {
	return (
		<div className="flex items-center py-1">
			<Button
				onClick={() => console.log('toggle button')}
				key={'1'}
				className={`btn-toggle ${classFirst}`}
			>
				{child1}
			</Button>
			<Button
				onClick={() => console.log('toggle button')}
				key={'2'}
				className={`btn-toggle ${classSecond}`}
			>
				{child2}
			</Button>
			<Button
				onClick={() => console.log('toggle button')}
				key={'3'}
				className={`btn-toggle ${classThird}`}
			>
				{child3}
			</Button>
		</div>
	)
}
export default ToggleButtons

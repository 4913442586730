import React from 'react'
import Editor from './Editor'
const RichTextbox = ({ onChange, value, labelName }) => {
	return (
		<div className="form-group">
			{labelName && <label className="label">{labelName}</label>}
			<div className="input-outer">
				<div>
					<Editor onChange={onChange} value={value} />
				</div>
			</div>
		</div>
	)
}

export default RichTextbox

import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'
import { classNames } from 'primereact/utils'
import { FilterMatchMode } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import Button from '../Buttons/Button'
import SimpleDropDown from '../../Dropdowns/Simpledropdown'
export default function DataTableWrapper({
	products,
	HeaderButtons,
	options,
	columns,
	onDataSelection
}) {
	const [selectedColumn, setSelectedColumn] = useState('All')
	const [dropDownVal, setDropDownVal] = useState('')
	const [selectedProducts, setSelectedProducts] = useState(null)
	const [expandedRows, setExpandedRows] = useState(null)
	const toast = useRef(null)
	const dt = useRef(null)
	// search the columns on user input
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS }
	})
	const [globalFilterValue, setGlobalFilterValue] = useState('')
	const allowExpansion = (rowData) => {
		return Object.keys(rowData.test).length > 0
	}
	// download columns data
	const exportCSV = (selectionOnly) => {
		console.log(dt.current.exportCSV(selectionOnly))
	}
	// search items on user input
	const onGlobalFilterChange = (event) => {
		const value = event.target.value
		let _filters = { ...filters }
		_filters['global'].value = value
		setFilters(_filters)
		setGlobalFilterValue(value)
	}
	// filter fields on selected DropDown Value
	const filterColumn = (value, field) => {
		if (field !== 'All') {
			dt.current.filter(
				value.toLowerCase(),
				field.toLowerCase(),
				'contains'
			)
		}
	}
	// this is for css
	useEffect(() => {
		const svgElement = document.querySelector(
			'.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon svg'
		)
		if (svgElement) {
			svgElement.setAttribute('stroke-width', '2')
		}
	}, [])
	// search the value in dropdown
	const handleChange = (value) => {
		setDropDownVal(value)
		setSelectedColumn(value)
	}
	// pass data to callback function
	const HandleProduct = (event) => {
		setSelectedProducts(event.value)
		onDataSelection(event.value)
	}
	const renderHeader = () => {
		return (
			<div className="flex flex-wrap justify-between gap-y-4 md:flex-col lg:flex-row">
				<div className="w-full md:w-full md:flex-wrap lg:w-auto">
					<HeaderButtons exportCSV={exportCSV} />
				</div>
				<div className="table-search flex w-full items-center md:w-full lg:w-auto">
					<label className="label" style={{ margin: '0 10px 0 0' }}>
						Search :
					</label>
					<div className="search-textbox">
						<div className="input-outer">
							<InputText
								type="search"
								className="form-control"
								placeholder="Search..."
								name=""
								value={globalFilterValue}
								onChange={onGlobalFilterChange}
								onInput={(e) =>
									filterColumn(e.target.value, selectedColumn)
								}
							/>
						</div>
					</div>
					<div className="search-dropdown">
						<div className="input-outer">
							<SimpleDropDown
								label=""
								options={options}
								Margin={0}
								onChange={handleChange}
								value={dropDownVal}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}

	// this function is for rowExpansion
	const rowExpansionTemplate = (data) => {
		return (
			<div className="p-3">
				<DataTable value={data.test} tableStyle={{ minWidth: '100%' }}>
					<Column
						sortable
						field="quantity"
						header="Quantity"
						style={{ width: '25%' }}
						headerStyle={{ width: '25%' }}
					></Column>
					<Column
						sortable
						field="test"
						header="test"
						style={{ width: '25%' }}
						headerStyle={{ width: '25%' }}
					></Column>
					<Column
						sortable
						field="asca"
						header="asca"
						style={{ width: '25%' }}
						headerStyle={{ width: '25%' }}
					></Column>
					<Column
						sortable
						field="aaa"
						header="aaa"
						style={{ width: '25%' }}
						headerStyle={{ width: '25%' }}
					></Column>
				</DataTable>
			</div>
		)
	}

	// pagination
	const template1 = {
		layout: 'CustomPaginator PrevPageLink PageLinks NextPageLink',
		CustomPaginator: (options) => {
			return (
				<span style={{ marginRight: 'auto' }}>
					Showing {options.first + 1} to{' '}
					{options.first + options.rows} of {options.totalRecords}{' '}
					entries
				</span>
			)
		},
		PrevPageLink: (options) => {
			options.className = classNames(options.className).replace(
				'p-disabled',
				''
			)
			return (
				<Button
					type={'button'}
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={options.onClick}
					disabled={options.disabled}
					children={'Previous'}
				/>
			)
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={options.onClick}
					disabled={options.disabled}
					children={'Next'}
				/>
			)
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					'p-disabled': true
				})

				return (
					<span className={className} style={{ userSelect: 'none' }}>
						...
					</span>
				)
			}
			const isCurrentPage = options.page === options.currentPage
			const linkClassName = classNames(options.className, {
				p: isCurrentPage
			})
			const linkStyle = isCurrentPage
				? {
						backgroundColor: '#7252d3',
						borderRadius: '0',
						color: '#fff'
					}
				: {
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: '#007bff'
					}
			return (
				<Button
					type="button"
					className={linkClassName}
					style={linkStyle}
					onClick={options.onClick}
					children={`${options.page + 1}`}
				/>
			)
		}
	}
	const paginatorLeft = (options) => {
		return (
			<span style={{ marginRight: 'auto' }}>
				Showing {options.first + 1} to{' '}
				{Math.min(options.first + options.rows, options.totalRecords)}{' '}
				entries from {options.totalRecords}
			</span>
		)
	}

	return (
		<div className="border">
			<Toast ref={toast} />
			<Tooltip target=".export-buttons>button" position="bottom" />
			<DataTable
				ref={dt}
				expandedRows={expandedRows}
				onRowToggle={(e) => setExpandedRows(e.data)}
				rowExpansionTemplate={rowExpansionTemplate}
				paginatorTemplate={template1}
				paginatorLeft={paginatorLeft}
				paginator
				rows={8}
				value={products}
				selection={selectedProducts}
				onSelectionChange={(e) => HandleProduct(e)}
				header={renderHeader}
				dataKey="id"
				tableStyle={{ minWidth: '100%' }}
				scrollable
				filters={filters}
			>
				{columns.map((column, index) => (
					<Column
						key={index}
						expander={column.expander && allowExpansion}
						selectionMode={column.selectionMode}
						sortable={column.sortable}
						header={column.header}
						field={column.field}
						style={column.style}
						headerStyle={column.headerStyle}
					/>
				))}
			</DataTable>
		</div>
	)
}

import React, { useState } from 'react'

import AuthLayout from '../../Auth-Layout'

import FormHeader from '../../components/Common/FormHeader'
import FormBody from '../../components/Common/FormBody'
import Formfooter from '../../components/Common/FormFooter'

import Button from '../../components/ui/Buttons/Button'
import Icon from '../../components/ui/icon'
import ElasticTextBox from '../../components/ui/Elastic-textbox'
import TextBox from '../../components/ui/TextBox'
import Alert from '../../components/ui/Alerts'
import AlertWrapper from '../../components/ui/Alerts/AlertWrapper'

import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'

const Contact = () => {
	const [notifications, setNotifications] = useState([])
	const [userData, setUserData] = useState({
		fullname: null,
		email: null,
		subject: null,
		message: null
	})
	const addNotification = (type, message, icon) => {
		setNotifications((prevNotifications) => [
			{ id: new Date().getTime(), type, message, icon },
			...prevNotifications
		])
	}
	const HandleChange = (key, value) => {
		setUserData((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}

	const HandleSubmit = (event) => {
		event.preventDefault()

		const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		let isValidEmail = EMAIL_REGEXP.test(userData.email)

		if (
			!userData.fullname ||
			!userData.email ||
			!isValidEmail ||
			!userData.subject ||
			!userData.message
		) {
			if (!userData.fullname) {
				addNotification(
					'danger',
					'Please enter your full name.',
					faTriangleExclamation
				)
			} else if (!userData.email) {
				addNotification(
					'danger',
					'Please enter your email address.',
					faTriangleExclamation
				)
			} else if (!isValidEmail) {
				addNotification(
					'danger',
					'Please enter valid email address.',
					faTriangleExclamation
				)
			} else if (!userData.subject) {
				addNotification(
					'danger',
					'Please enter your subject.',
					faTriangleExclamation
				)
			} else if (!userData.message) {
				addNotification(
					'danger',
					'Please enter your message.',
					faTriangleExclamation
				)
			} else {
				addNotification(
					'danger',
					'Something went wrong. Please try again after sometime.',
					faTriangleExclamation
				)
			}
		} else {
			addNotification(
				'success',
				'Your request shared successfully. We will connect with you soon.',
				faCheck
			)
		}
	}
	return (
		<>
			<AlertWrapper>
				{notifications
					.sort((a, b) => b.id - a.id)
					.map((notification, index) => (
						<div key={notification.id}>
							<Alert
								autoClose={3000}
								id={index + 1}
								className={`alert-${notification.type}`}
								message={notification.message}
								Icon={notification.icon}
								onClose={() => {
									setNotifications((prevNotifications) =>
										prevNotifications.filter(
											(n) => n.id !== notification.id
										)
									)
								}}
							/>
						</div>
					))}
			</AlertWrapper>
			<AuthLayout>
				<FormHeader
					title="ContactSupport"
					message="Welcome! Please use below form to contact."
				/>
				<FormBody>
					<TextBox
						onChange={(data) => HandleChange('fullname', data)}
						labelName="Full Name"
						type={'text'}
						className="mb-4"
					/>

					<TextBox
						onChange={(data) => HandleChange('email', data)}
						labelName="Email Address"
						type={'text'}
						className={'mb-4'}
					/>

					<TextBox
						onChange={(data) => HandleChange('subject', data)}
						labelName="Subject"
						type={'text'}
						className={'mb-4'}
					/>

					<ElasticTextBox
						labelName="Message"
						type={'text'}
						className="!mb-4"
						onChange={(data) => HandleChange('message', data)}
					/>

					<div className="mb-3">
						<p className="small-text">
							By clicking <strong>Submit</strong> below, you agree
							to our Terms and Conditions and Privacy Policy.
						</p>
					</div>
					<Button
						className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
						onClick={HandleSubmit}
					>
						<Icon
							name="line-user-contact"
							width={'16px'}
							height={'16px'}
							className={'icon-fill-white'}
							viewbox={'0 0 24 24'}
						/>{' '}
						Submit
					</Button>
				</FormBody>
				<Formfooter
					message1="Already have an account"
					linktext1="Sign In"
					link1="login"
					message2="Don't have an account"
					linktext2="Create an Account"
					link2="register"
				/>
			</AuthLayout>
		</>
	)
}

export default Contact

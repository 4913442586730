import React from 'react'
import image from '../../assets/img/bg1.jpg'
import { Link } from 'react-router-dom'
export default function BannerPolicy() {
	return (
		<>
			<div class="hidden w-[25%] lg:block">
				<div class="autho-banner relative h-full">
					<img
						src={image}
						className="auth-img h-full w-full object-cover opacity-30"
						style={{ mixBlendMode: 'luminosity' }}
						alt="Auth Images"
					/>
					<div class="content-left">
						<a
							href="../"
							class="header-logo mb-4 text-white"
							style={{ color: 'white' }}
						>
							Risk Wise
						</a>
						<h4 class="main-title mb-3 text-white">
							Unlock the Power of Governance, Risk, Compliance,
							and Assurance with Risk Wise.
						</h4>
						<p className="text-white">
							<b>Seamless Integration</b>: Risk Wise seamlessly
							integrates all facets of GRC into a unified
							platform, enhancing collaboration and boosting
							efficiency across your organization.
						</p>
						<p className="text-white">
							<b>Real-time Insights</b>: Gain actionable insights
							with our comprehensive analytics, providing the
							foresight needed to make informed decisions.
						</p>
						<p className="text-white">
							<b>Assured Compliance</b>: Navigate the complex
							regulatory landscape effortlessly while ensuring
							adherence to industry standards.
						</p>
						<p className="text-white">
							<b>Risk Mitigation</b>: Identify and mitigate risks
							proactively, protecting your organization from
							potential threats.
						</p>
						<p className="text-white">
							<b>Audits Made Easy</b>: Streamline audit processes
							and achieve compliance with ease.
						</p>
						<p className="text-white">
							<b>User-friendly Interface</b>: Our intuitive design
							ensures that everyone in your organization can
							harness the power of Risk Wise.
						</p>
						<div className="content-footer">
							<p className="text-white">
								&copy;2019-2023 All Rights Reserved. Risk Wise
								&reg; is a registered trademark of ThinkFirm.
							</p>
							<ul className="flex flex-wrap gap-2">
								<li>
									<Link
										className="text-sm  underline"
										to="/pages/policy"
									>
										Privacy Policy
									</Link>
								</li>
								<li>
									<Link
										className="text-sm underline"
										to='/'
									>
										Terms and Conditions
									</Link>
								</li>
								<li>
									<Link
										className="text-sm underline"
										to='/'
									>
										About ThinkFirm
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

import React from 'react'

const AlertWrapper = ({ children }) => {
	//this component will be used as a wrapper componenet for alerts for styling purpose
	return (
		<div className="alert-wrapper" data-position="top">
			{children}{' '}
		</div>
	)
}
export default AlertWrapper

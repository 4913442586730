import React from 'react'
import Verified from '../../assets/img/check.png'
const BusinessInfo = () => {
	return (
		<div>
			<div className="bg-[#f7f7f7] px-[15px] py-[10px]">
				<div className="multipleinputs grid grid-cols-12">
					<div className="col-span-12 sm:col-span-3  md:col-span-2 lg:w-full">
						<div className="form-group">
							<label className="label">Employee ID</label>
							<div className="text-display">
								<p>demo text</p>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-3  md:col-span-2 lg:w-full">
						<div className="form-group">
							<label className="label">Grade</label>
							<div className="text-display">
								<p>demo text</p>
							</div>
						</div>
					</div>
					<div className=" col-span-12 sm:col-span-6  md:col-span-4">
						<div className="form-group">
							<label className="label">Designation</label>
							<div className="text-display">
								<p>demo text</p>
							</div>
						</div>
					</div>

					<div className="col-span-12 sm:col-span-6  md:col-span-4">
						<div className="form-group">
							<label className="label">Role</label>
							<div className="text-display">
								<p>demo text</p>
							</div>
						</div>
					</div>

					<div className="col-span-12 sm:col-span-3  md:col-span-2 lg:w-full">
						<div className="form-group">
							<label className="label">Date of Joining</label>
							<div className="text-display">
								<p>10 Jan 2023</p>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-3  md:col-span-2 lg:w-full">
						<div className="form-group">
							<label className="label">Clearence</label>
							<div className="text-display">
								<p>General</p>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-6  md:col-span-4">
						<div className="form-group">
							<label className="label">
								Reporting Manager (Administrative)
							</label>
							<div className="text-display">
								<p>demo text</p>
							</div>
						</div>
					</div>

					<div className="col-span-12 sm:col-span-6  md:col-span-4">
						<div className="form-group">
							<label className="label">
								Reporting Manager (Functional)
							</label>
							<div className="text-display">
								<p>demo text</p>
							</div>
						</div>
					</div>

					<div className="col-span-12 sm:col-span-4  md:col-span-4">
						<div className="form-group">
							<label className="label">OU1 (Group)</label>
							<div className="text-display">
								<p>demo text</p>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-4  md:col-span-4">
						<div className="form-group">
							<label className="label">OU2 (Company)</label>
							<div className="text-display">
								<p>demo text</p>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-4  md:col-span-4">
						<div className="form-group">
							<label className="label">OU3 (Department)</label>
							<div className="text-display">
								<p>demo text</p>
							</div>
						</div>
					</div>

					<div className="col-span-12 sm:col-span-6  md:col-span-6">
						<div className="form-group">
							<label className="label">Work eMail ID</label>
							<div className="text-display">
								<p>johnfernandis@gmail.com</p>
								<div className="verfied">
									<img src={Verified} alt="" />
									<span>Verified</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-6  md:col-span-6">
						<div className="form-group">
							<label className="label">Work Phone Number</label>
							<div className="text-display">
								<p>+91 9999848369</p>
								<div className="verfied">
									<img src={Verified} alt="" />
									<span>Verified</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-6  md:col-span-6">
						<div className="form-group">
							<label className="label">Work Address</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>H, No-6, Panchsheel Shopping Center</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-6  md:col-span-6">
						<div className="form-group">
							<label className="label">Business Address</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>H, No-6, Panchsheel Shopping Center</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-4  md:col-span-3">
						<div className="form-group">
							<label className="label">City</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-4  md:col-span-3">
						<div className="form-group">
							<label className="label">Pin Code</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-12 sm:col-span-4  md:col-span-3">
						<div className="form-group">
							<label className="label">City</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-4  md:col-span-3">
						<div className="form-group">
							<label className="label">Pin Code</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-12 sm:col-span-4  md:col-span-3">
						<div className="form-group">
							<label className="label">State</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-4  md:col-span-3">
						<div className="form-group">
							<label className="label">Country</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>India</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-12 sm:col-span-4  md:col-span-3">
						<div className="form-group">
							<label className="label">State</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-4  md:col-span-3">
						<div className="form-group">
							<label className="label">Country</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>India</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BusinessInfo

import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
const MultiSelectWithGroup = ({
	labelName,
	items,
	onItemsRemove,
	onItemsAdd,
	lists
}) => {
	const [show, setShow] = useState(false)
	const [value, setValue] = useState('')
	const DropDownRef = useRef()

	const handleShow = () => {
		setShow((prev) => !prev)
	}
	// pass the item to parent compoent where it will be stored
	const HandleSelectedItems = (items) => {
		onItemsAdd(items)
		setShow((prev) => !prev)
	}
	// pass the item to parent where it will be removed
	const HandleRemovedItems = (items) => {
		onItemsRemove(items)
	}
	const HandleInputChange = (event) => {
		setValue(event.target.value)
	}
	// close the drop down on outside click
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				DropDownRef.current &&
				!DropDownRef.current.contains(event.target)
			) {
				setShow(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])
	return (
		<div className="form-group">
			{labelName && <label className="label">{labelName}</label>}
			<div className="input-outer" ref={DropDownRef}>
				<div className="multiDrop">
					<div className="multiDropInput">
						{lists.length > 0 &&
							lists.map((i) => (
								<div key={i.id} className="TagItem">
									<span>{i.name}</span>
									<p
										onClick={() => HandleRemovedItems(i)}
										className="TagItemRemove"
									>
										<FontAwesomeIcon icon={faXmark} />
									</p>
								</div>
							))}
						<input
							type="text"
							className="form-control form-control-1 multiInput"
							placeholder="Select"
							onClick={handleShow}
							onChange={(e) => HandleInputChange(e)}
							value={value}
						/>
					</div>
					{show && (
						<div className="dropBox">
							<div className="listitem">
								{items[0].length > 0 && (
									<span className="list-dropdown-headings">
										Application
									</span>
								)}
								{value.length === 0 &&
									items.length > 0 &&
									items[0].map((i) => (
										<div
											key={i.id}
											className="ms-Suggestions-item"
											onClick={() =>
												HandleSelectedItems(i)
											}
										>
											{i.name}
										</div>
									))}
								{value.length !== 0 &&
									items[0]
										.filter((item) =>
											item.name
												.toLowerCase()
												.includes(value.toLowerCase())
										)
										.map((filteredItem) => (
											<div
												key={filteredItem.id}
												className="ms-Suggestions-item"
												onClick={() =>
													HandleSelectedItems(
														filteredItem
													)
												}
											>
												{filteredItem.name}
											</div>
										))}
							</div>
							<div className="listitem">
								{items[1].length > 0 && (
									<span className="list-dropdown-headings">
										System
									</span>
								)}
								{value.length === 0 &&
									items.length > 0 &&
									items[1].map((i) => (
										<div
											key={i.id}
											className="ms-Suggestions-item"
											data-id="0"
											onClick={() =>
												HandleSelectedItems(i)
											}
										>
											{i.name}
										</div>
									))}
								{value.length !== 0 &&
									items[1]
										.filter((item) =>
											item.name
												.toLowerCase()
												.includes(value.toLowerCase())
										)
										.map((filteredItem) => (
											<div
												key={filteredItem.id}
												className="ms-Suggestions-item"
												onClick={() =>
													HandleSelectedItems(
														filteredItem
													)
												}
											>
												{filteredItem.name}
											</div>
										))}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
export default MultiSelectWithGroup

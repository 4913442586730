import React, { useState } from 'react'
import SimpleDropDown from '../../Dropdowns/Simpledropdown'
const SearchableDatepicker = ({
	HeadingLabel = '',
	label1 = '',
	label2 = '',
	label3 = '',
	onChange,
	day,
	month,
	year
}) => {
	const YEARS = []
	const DAYS = []
	const MONTHS = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	]
	const startYear = 1910
	const endYear = 2050
	for (let year = startYear; year <= endYear; year++) {
		YEARS.push(year.toString())
	}
	for (let day = 1; day <= 31; day++) {
		DAYS.push(day.toString())
	}

	// pass the selected fields data to the component where it has been used
	const onDateChange = (field, value) => {
		// console.log(value)
		if (field === 'month') {
			if (/^[A-Za-z]+$/.test(value) || value === '') {
				onChange(field, value)
			}
		} else {
			onChange(field, value)
		}
	}

	return (
		<div className="form-group mb-0">
			{HeadingLabel && <label className="label">{HeadingLabel}</label>}
			<div className="flex w-1/2 gap-[3px]">
				<SimpleDropDown
					className="w-[58px] min-w-[58px]"
					label={label1}
					options={DAYS}
					onChange={(value) => onDateChange('day', value)}
					placeholder="DD"
					value={day}
					id="dayDropdown"
				/>
				<SimpleDropDown
					className="w-[70px] min-w-[70px] "
					label={label2}
					options={MONTHS}
					onChange={(value) => onDateChange('month', value)}
					placeholder="MM"
					value={month}
					id="monthDropdown"
				/>
				<SimpleDropDown
					className="w-[85px] min-w-[85px]"
					label={label3}
					options={YEARS}
					onChange={(value) => onDateChange('year', value)}
					placeholder="YYYY"
					value={year}
					id="yearDropdown"
				/>
			</div>
		</div>
	)
}

export default SearchableDatepicker

import React, { useState } from 'react'
import MultiSelectWithGroup from '../../components/Dropdowns/MultiSelectdropdownwitgroups'
const MultiSelectWithGroups = () => {
	const [lists, setLists] = useState([])
	const [items, setItems] = useState([
		[
			{ id: 0, name: '(A0001) SAP ERP' },
			{ id: 1, name: '(A0002) GRC Toolbox' },
			{ id: 2, name: '(A0003) Dynamics 365 CRM' }
		],
		[{ id: 3, name: '(A0004) CMDB SHW00X' }]
	])

	const add = (data) => {
		setLists([...lists, data])
		setItems([
			[...items[0].filter((item) => item.id !== data.id)],
			[...items[1].filter((item) => item.id !== data.id)]
		])
		// setShow(prev => !prev)
	}

	const removeItem = (item) => {
		setLists((prevLists) =>
			prevLists.filter((listItem) => listItem.id !== item.id)
		)
		if (item.id < 3) {
			setItems([[...items[0], item], [...items[1]]])
		} else {
			setItems([[...items[0]], [...items[1], item]])
		}
	}
	return (
		<MultiSelectWithGroup
			labelName={'Multi Select DropDown with groups'}
			items={items}
			onItemsAdd={(item) => add(item)}
			onItemsRemove={(item) => removeItem(item)}
			lists={lists}
		/>
	)
}

export default MultiSelectWithGroups

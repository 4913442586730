import React, { useState } from 'react'
import RangeDropDownPage from './RangeDropDown'
import SimpleDropDown from '../../components/Dropdowns/Simpledropdown'
import MultiSelectWithGroups from './MultiSelectWithGroups'
import DatePicker from '../../components/ui/Datepicker'
import Phoneselectr from './PhoneSelectro'
import AdvanceDD from './AdvanceDD'
import CountrySelector from '../../components/ui/Countryselctor'
import ColorPicker from '../../components/ui/ColorPicker'
import TextBox from '../../components/ui/TextBox'
import ElasticTextBox from '../../components/ui/Elastic-textbox'
import RichTextbox from '../../components/ui/Rich-textbox'
import FileUploader from '../../components/ui/Attachments'
import SearchableDatepicker from '../../components/ui/Searchable-Datepicker'
import MultiSelectDD from './MultiSelectDD'
import AdvanceDDwithDIs from './AdvanceDDwithDIs'
import RangeDropDownWithBtns from './RangeDropDownWithBtns'
import TextScrollCHeck from './TextScrollCHeck'
import AccordionDemo from './AccordionDemo'
import ImageCropper from './ImageCropper'
import Signatures from './Signatures'
const FormPage = () => {
	const [simpleDropDownInput, setSimpleDropDownInput] = useState('')
	const [currentDate, setCurrentDate] = useState(null) //simple date picker
	const [selectedDate, setSelectedDate] = useState({
		//searchable date picker
		day: '',
		month: '',
		year: ''
	})
	const [Files, setFiles] = useState([])
	const HandleSeletedFiles = (files) => {
		// console.log('testing')
		console.log(files)
		setFiles((prevFiles) => [...prevFiles, ...files])
	}
	const RemoveFile = (file) => {
		console.log(file)
		let filename = file.name
		setFiles((prevFiles) =>
			prevFiles.filter((files) => files.name !== filename)
		)
	}
	const HandleDateChange = (date) => {
		setCurrentDate(date)
	}
	const handleChange = (data) => {
		setSimpleDropDownInput(data)
	}
	const HandleFieldChanges = (fieldname, value) => {
		setSelectedDate((prevState) => {
			return {
				...prevState,
				[fieldname]: value
			}
		})
	}
	const [RichTextboxState, setRichtextBoxState] = useState(null)
	const lists = ['Tarun Gupta', 'Vikram Dodiya', 'Abhay Josi']
	return (
		<div className="page-sign d-block px-4 py-4">
			<div className="row">
				<div className="small-text">ELEMENTS</div>
				<h3>FORM ELEMENTS</h3>
			</div>
			<div className="row mb-4">
				<hr />
			</div>
			<div className="row mb-4">
				<div className="grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-3">
					<div className="col-span-1">
						<span className="small-text">TEXT-FIELD DESIGN</span>
						<div className="flex flex-col gap-2">
							<TextBox
								placeholder={'Name'}
								type={'text'}
								labelName={'TextBox'}
								onChange={(data) => console.log(data)}
							/>
							<ElasticTextBox
								labelName={'Elastic TextBox'}
								onChange={(data) => console.log(data)}
							/>
							<RichTextbox
								value={RichTextboxState}
								onChange={(data) => {
									setRichtextBoxState(data)
								}}
								labelName={'Rich TextBox'}
							/>
						</div>
					</div>
					<div className="col-span-1">
						<span className="small-text">DROPDOWN DESIGN</span>
						<div className="flex flex-col gap-2">
							<RangeDropDownPage />
							<RangeDropDownWithBtns />
							<SimpleDropDown
								label="Simple Dropdown"
								options={lists}
								onChange={(value) => handleChange(value)}
								value={simpleDropDownInput}
								id="test-names"
							/>
							<MultiSelectDD />
							<DatePicker
								labelName={'Date Picker'}
								onDateChange={(date) => HandleDateChange(date)}
								currentDate={currentDate}
							/>
							<SearchableDatepicker
								// HeadingLabel="Calender"
								label1="Day"
								label2="Month"
								label3="Year"
								day={selectedDate.day}
								month={selectedDate.month}
								year={selectedDate.year}
								onChange={(fieldname, value) =>
									HandleFieldChanges(fieldname, value)
								}
							/>
						</div>
					</div>
					<div className="col-span-1">
						<span className="small-text">
							ADVANCE PICKER DROPDOWN
						</span>
						<div className="flex flex-col gap-2">
							<MultiSelectWithGroups />
							<AdvanceDD />
							<AdvanceDDwithDIs />

							{/* <PhoneNumber /> */}
							<Phoneselectr />
							<CountrySelector
								label={'Country'}
								onChange={(data) => console.log(data)}
							/>
							<ColorPicker
								labelName={'Color Picker'}
								getColor={(color) => console.log(color)}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-2 gap-8 md:grid-cols-7 lg:grid-cols-9">
				<div className="col-span-1 md:col-span-4 lg:col-span-5">
					<div className="col-span-1">
						<span className="small-text">SIGNATURE COMPONENTS</span>
						<div className="flex flex-col gap-2">
							<Signatures />
						</div>
					</div>
				</div>
				<div className="col-span-1 md:col-span-4 lg:col-span-4 ">
					<span className="small-text">TEXT COMPONENTS</span>
					<div className="flex flex-col gap-2">
						{/* <TextScrollCheck /> */}
						<TextScrollCHeck />
					</div>
				</div>
			</div>
			<div className="row mb-4">
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
					<div className="col-span-1">
						<span className="small-text">
							ADDITIONAL COMPONENTS
						</span>
						<div className="flex flex-col gap-2">
							<ImageCropper />
						</div>
					</div>
					<div className="col-span-2">
						<span className="small-text">
							ADDITIONAL COMPONENTS
						</span>

						<div className="flex flex-col gap-2">
							<AccordionDemo />
						</div>
					</div>
				</div>
			</div>
			<div className="row mb-4">
				<div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2">
					<div className="col-span-1">
						<span className="small-text"></span>
						<div className="flex flex-col gap-2">
							<FileUploader
								labelName={'Attachments'}
								files={Files}
								onSelect={(files) => HandleSeletedFiles(files)}
								onRemove={(file) => RemoveFile(file)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FormPage

import React from 'react'
import Modal from './Modal'
import Button from './Buttons/Button'
export default function Modals() {
	const [showModal, setShowModal] = React.useState(false)
	const [modalSize, setModalSize] = React.useState('')
	const [overLay, setOverLay] = React.useState('')
	const [scrollable, setScrollable] = React.useState(false)
	const xlBody = `
    <p className="text-blueGray-500 text-lg leading-relaxed">
      I always felt like I could do anything. That’s the main
      thing people are controlled by! Thoughts- their perception
      of themselves! They're slowed down by their perception of
      themselves. If you're taught you can’t do anything, you
      won’t do anything. I was taught I could do everything.
    </p>`
	const handleModalClose = () => {
		setShowModal(false)
		setScrollable(false)
		setOverLay('false')
	}

	return (
		<>
			<div className="p-[30px]">
				<div>
					<Button
						className="btn btn-primary btn-large mb-1 mr-1"
						type="button"
						children="Open small modal"
						onClick={function () {
							setShowModal(true)
							setModalSize('sm')
						}}
					/>
					<Button
						className="btn btn-primary btn-large mb-1 mr-1"
						type="button"
						children="Open regular modal"
						onClick={function () {
							setShowModal(true)
							setModalSize('xl')
						}}
					/>
					<button
						className="btn btn-primary btn-large mb-1 mr-1"
						type="button"
						children="Open large modal"
						onClick={function () {
							setShowModal(true)
							setModalSize('3xl')
						}}
					/>
				</div>
				<div>
					<Button
						className="btn btn-primary btn-large mb-1 mr-1"
						type="button"
						children="xs"
						onClick={function () {
							setModalSize('xs')
							setShowModal(true)
						}}
					/>
					<Button
						className="btn btn-primary btn-large mb-1 mr-1"
						type="button"
						children="sm"
						onClick={function () {
							setModalSize('sm')
							setShowModal(true)
						}}
					/>
					<Button
						className="btn btn-primary btn-large mb-1 mr-1"
						type="button"
						children="md"
						onClick={function () {
							setModalSize('md')
							setShowModal(true)
						}}
					/>
					<Button
						className="btn btn-primary btn-large mb-1 mr-1"
						type="button"
						children="lg"
						onClick={function () {
							setModalSize('lg')
							setShowModal(true)
						}}
					/>
					<Button
						className="btn btn-primary btn-large mb-1 mr-1"
						type="button"
						children="xl"
						onClick={function () {
							setModalSize('xl')
							setShowModal(true)
						}}
					/>
				</div>
				<div>
					<Button
						className="btn btn-primary btn-large mb-1 mr-1"
						children="Fullscreen Modal"
						type="button"
						onClick={() => {
							setModalSize('full')
							setShowModal(true)
						}}
					/>
					<Button
						className="btn btn-primary btn-large mb-1 mr-1"
						children="Customized Overlay Model"
						type="button"
						onClick={() => {
							setModalSize('xl')
							setShowModal(true)
							setOverLay('custom')
						}}
					/>
					<Button
						className="btn btn-primary btn-large mb-1 mr-1"
						children="Model With Scroll"
						type="button"
						onClick={() => {
							setModalSize('xl')
							setShowModal(true)
							setScrollable(true)
						}}
					/>
					<Button
						className="btn btn-primary btn-large mb-1 mr-1"
						children="Auto size Modal"
						type="button"
						onClick={() => {
							setModalSize('auto')
							setShowModal(true)
						}}
					/>
				</div>
				<Modal
					modalSize={modalSize}
					title={'Modal Title'}
					onClose={handleModalClose}
					body={xlBody}
					overLay={overLay}
					showModal={showModal}
					scrollable={scrollable}
				/>
			</div>
		</>
	)
}

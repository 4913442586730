import React from 'react'
import iconRegistry from './iconRegistry'
import DefaultIcon from '../../../assets/svg-icons/social/Google'
const Icon = ({
	name,
	width = '24px',
	height = '24px',
	fillColor = '',
	viewBox,
	className = 'icon-fill-primary300',
	...rest
}) => {
	const IconComponent = iconRegistry[name] || DefaultIcon

	return (
		<IconComponent
			width={width}
			height={height}
			fillColor={fillColor}
			className={className}
			viewbox={viewBox}
			{...rest}
		/>
	)
}
export default Icon

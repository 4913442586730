import React, { useState, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
function Editor({ onChange, value }) {
	const quillRef = useRef()
	const modules = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ color: [] }, { background: [] }]
		]
	}
	const formats = [
		'bold',
		'italic',
		'underline',
		'strike',
		'list',
		'bullet',
		'color',
		'background'
	]
	const handleKeyPress = (event) => {
		// console.log(event.key)
		// console.log(event)
		if (event.key === 'alt') {
			const isEmpty =
				quillRef.current.getLength() === 1 &&
				quillRef.current.getText().trim() === ''
			if (isEmpty) {
				const editorContent = quillRef.current.getContents()
				editorContent.ops[0].attributes = {
					class: 'empty-paragraph-style'
				}
				quillRef.current.setContents(editorContent)
			}
		}
	}
	const handleTextChange = (content) => {
		onChange(content)
		// setValue(content)
	}
	return (
		<ReactQuill
			theme="snow"
			modules={modules}
			formats={formats}
			value={value}
			onChange={handleTextChange}
			onKeyPress={handleKeyPress}
			ref={quillRef}
		/>
	)
}
export default Editor

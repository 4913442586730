import React from 'react'
import Icon from '../../components/ui/icon'
const SvgIconPage = () => {
	return (
		<div className="p-[30px]">
			<p className="small-text">ICONS</p>
			<h3>SVG COMPONENT</h3>
			<hr />

			{/* Social Icons */}
			<div className="mt-5 flex flex-wrap items-center gap-[3px]">
				<div className="small-text">SOCIAL ICONS</div>
			</div>
			<div className="mt-5 flex flex-wrap items-center gap-[3px]">
				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">GOOGLE</div>
					<Icon name="social-google" />
					<div className="smaller-text">social-google</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">MESSAGE</div>
					<Icon name="social-message" />
					<div className="smaller-text">social-message</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">WINDOW</div>
					<Icon name="social-window" />
					<div className="smaller-text">social-window</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">INSTAGRAM</div>
					<Icon name="social-instagram" />
					<div className="smaller-text">social-instagram</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">MESSENGER</div>
					<Icon name="social-messenger" />
					<div className="smaller-text">social-messenger</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">LINKEDIN</div>
					<Icon name="social-linkedin" />
					<div className="smaller-text">social-linkedin</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">LINKEDIN SQUARE</div>
					<Icon name="social-linkedin-square" />
					<div className="smaller-text">social-linkedin-square</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">GITHUB</div>
					<Icon name="social-github" />
					<div className="smaller-text">social-github</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">TWITTER</div>
					<Icon name="social-twitter" />
					<div className="smaller-text">social-twitter</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">TELEGRAM</div>
					<Icon name="social-telegram" />
					<div className="smaller-text">social-telegram</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">WHATSAPP</div>
					<Icon name="social-whatsapp" />
					<div className="smaller-text">social-whatsapp</div>
				</div>
			</div>

			{/* Line Icons */}
			<div className="mt-10 flex flex-wrap items-center gap-[3px]">
				<div className="small-text">LINE ICONS</div>
			</div>
			<div className="mt-5 flex flex-wrap items-center gap-[3px]">
				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">LOCK</div>
					<Icon name="line-lock" />
					<div className="smaller-text">line-lock</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">LOCK DOT</div>
					<Icon name="line-lock-dot" />
					<div className="smaller-text">line-lock-dot</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">PHONE LOCK</div>
					<Icon name="line-phone-lock" />
					<div className="smaller-text">line-phone-lock</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">SETTINGS</div>
					<Icon name="line-settings" />
					<div className="smaller-text">line-settings</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">USER SETTING</div>
					<Icon name="line-user-settings" />
					<div className="smaller-text">line-user-settings</div>
				</div>
				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">USER CONTACT</div>
					<Icon name="line-user-contact" />
					<div className="smaller-text">line-user-contact</div>
				</div>
				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">SIGN UP</div>
					<Icon name="line-signup" />
					<div className="smaller-text">line-signup</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">SIGN OUT</div>
					<Icon name="line-signout" />
					<div className="smaller-text">line-signout</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">TOGGLE</div>
					<Icon name="line-toggle" />
					<div className="smaller-text">line-toggle</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">SEARCH</div>
					<Icon name="line-search" />
					<div className="smaller-text">line-search</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">NOTIFICATION</div>
					<Icon name="line-bell" />
					<div className="smaller-text">line-bell</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">HELP</div>
					<Icon name="line-help" />
					<div className="smaller-text">line-help</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">PLUS SQUARE</div>
					<Icon name="line-add-square" />
					<div className="smaller-text">line-add-square</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">EDIT FILE</div>
					<Icon name="line-edit-file" />
					<div className="smaller-text">line-edit-file</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">DOWNLOAD FILE</div>
					<Icon name="line-download-file" />
					<div className="smaller-text">line-download-file</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">GALLERY</div>
					<Icon name="line-gallery" />
					<div className="smaller-text">line-gallery</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">PROFILE CARD</div>
					<Icon name="line-profile-card" />
					<div className="smaller-text">line-profile-card</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">BAR CHART</div>
					<Icon name="line-bar-chart" />
					<div className="smaller-text">line-bar-chart</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">PIE CHART</div>
					<Icon name="line-pie-chart" />
					<div className="smaller-text">line-pie-chart</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">BRIEF CASE</div>
					<Icon name="line-brief-case" />
					<div className="smaller-text">line-brief-case</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">WIFI</div>
					<Icon name="line-wifi" />
					<div className="smaller-text">line-wifi</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">TRASH</div>
					<Icon name="line-trash" />
					<div className="smaller-text">line-trash</div>
				</div>
			</div>

			{/* Color Icons */}
			<div className="mt-10 flex flex-wrap items-center gap-[3px]">
				<div className="small-text">COLOR ICONS</div>
			</div>
			<div className="mt-5 flex flex-wrap items-center gap-[3px]">
				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">LOCK</div>
					<Icon name="line-lock" className="icon-fill-primary500" />
					<div className="smaller-text">
						line-lock <br /> icon-fill-primary500
					</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">LOCK</div>
					<Icon name="line-lock" className="icon-fill-success500" />
					<div className="smaller-text">
						line-lock <br /> icon-fill-success500
					</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">LOCK</div>
					<Icon name="line-lock" className="icon-fill-warning500" />
					<div className="smaller-text">
						line-lock <br /> icon-fill-warning500
					</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">LOCK</div>
					<Icon name="line-lock" className="icon-fill-complete500" />
					<div className="smaller-text">
						line-lock <br /> icon-fill-complete500
					</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">LOCK</div>
					<Icon name="line-lock" className="icon-fill-danger500" />
					<div className="smaller-text">
						line-lock <br /> icon-fill-danger500
					</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">LOCK</div>
					<Icon name="line-lock" className="icon-fill-grey200" />
					<div className="smaller-text">
						line-lock <br /> icon-fill-grey200
					</div>
				</div>

				<div className="border-1 flex flex-col items-center justify-center gap-[3px] border-gray-500 text-center">
					<div className="small-text">LOCK</div>
					<Icon name="line-lock" className="fill-[#34C5BF]" />
					<div className="smaller-text">
						line-lock <br /> fill-[#34C5BF]
					</div>
				</div>
			</div>
		</div>
	)
}

export default SvgIconPage

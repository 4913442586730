import React, { useState } from 'react'
import Header from './header'
import Profile from './Profile'
import FormHeader from './FormHeader'
import PersonalInfoForm from './PersonalInfoForm'
import BusinessInfo from './BusinessInfo'
import SocialInfo from './SocialInfo'
import AdditionalInfo from './AdditionalInfo'
const ViewProfile = () => {
	const [showTab, setShowTab] = useState('01')
	const handleTabesStates = (id) => {
		setShowTab(id)
	}
	return (
		<>
			<div className="border">
				<Header onIdChange={handleTabesStates} />
				<div className="p-5">
					<div>
						<Profile />
					</div>
					<div className="divider divider-margin"></div>

					{showTab === '01' && (
						<div>
							<FormHeader
								heading="Personal Information"
								setdrawer="personalinfoedit"
							/>
							<PersonalInfoForm />
						</div>
					)}
					{showTab === '02' && (
						<div>
							<FormHeader
								heading="Business Information"
								setdrawer="busnessinfoedit"
							/>
							<BusinessInfo />
						</div>
					)}
					{showTab === '03' && (
						<div>
							<FormHeader
								heading="Social Information"
								setdrawer="socialinfoedit"
							/>
							<SocialInfo />
						</div>
					)}
					{showTab === '04' && (
						<div>
							<FormHeader
								heading="Additional Information"
								setdrawer="additionalinfoedit"
							/>
							<AdditionalInfo />
						</div>
					)}
				</div>
			</div>
		</>
	)
}
export default ViewProfile

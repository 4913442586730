import DataTableWrapper from '../../components/ui/DataTables'
import IconButton from '../../components/ui/Buttons/Icon-button'
import {
	faTrash,
	faFileExport,
	faPlus,
	faPen
} from '@fortawesome/free-solid-svg-icons'
import { PRODUCTS } from './PRODUCTS'
const DataTableDemo = () => {
	const dopdownOptions = ['All', 'Code', 'Name', 'Category']
	let COLUMNS = [
		{ style: { width: '5rem' }, expander: true },
		{
			selectionMode: 'multiple',
			headerStyle: { width: '3rem !important' },
			style: { width: '3rem !important' },
			className: 'chk-dt chk-primary',
			id: 'red'
		},
		{
			sortable: true,
			header: 'Name',
			field: 'name',
			style: { width: '18%' },
			headerStyle: { width: '18%' }
		},
		{
			sortable: true,
			header: 'Code',
			field: 'code',
			style: { width: '18%' },
			headerStyle: { width: '18%' }
		},
		{
			sortable: true,
			header: 'Category',
			field: 'category',
			style: { width: '18%' },
			headerStyle: { width: '18%' }
		},
		{
			sortable: true,
			header: ' Status',
			field: 'inventoryStatus',
			style: { width: '18%' },
			headerStyle: { width: '18%' }
		},
		{
			sortable: true,
			header: 'Rating',
			field: 'rating',
			style: { width: '18%' },
			headerStyle: { width: '18%' }
		}
	]
	const HandleData = (data) => {
		console.log(data)
	}
	//header buttons
	const HeaderButtons = ({ exportCSV }) => (
		<div className="flex items-center justify-start gap-[3px]">
			<IconButton
				Icon={faFileExport}
				size="sm"
				className="btn btn-large btn-primary flex gap-1.5"
				onClick={() => exportCSV()}
			>
				Export
			</IconButton>
			<IconButton
				Icon={faPlus}
				size={'sm'}
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Add
			</IconButton>
			<IconButton
				Icon={faPen}
				size={'sm'}
				className="btn btn-large btn-complete flex gap-1.5"
			>
				Edit
			</IconButton>
			<IconButton
				Icon={faTrash}
				size={'sm'}
				className="btn btn-large btn-danger flex gap-1.5"
			>
				Delete
			</IconButton>
		</div>
	)
	// main datatable component
	return (
		<DataTableWrapper
			products={PRODUCTS}
			HeaderButtons={HeaderButtons}
			options={dopdownOptions}
			columns={COLUMNS}
			onDataSelection={HandleData}
		/>
	)
}
export default DataTableDemo

import React, { useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
const MultiSelectdropdown = ({ Items, lists, onItemsRemove, onItemsAdd }) => {
	const [items, setItems] = useState(Items)
	const [showDropDown, setShowDropDown] = useState(false)
	const [value, setValue] = useState('')
	const DropDownRef = useRef()

	const handleShow = () => {
		setShowDropDown((prev) => !prev)
	}
	// pass the item to parent where it will be stored
	const HandleSelectedItem = (items) => {
		console.log(items)
		onItemsAdd(items)
		setShowDropDown((prev) => !prev)
	}
	// pass the item to parent where it will be removed
	const HandleRemovedItems = (items) => {
		onItemsRemove(items)
	}
	// store the user input and will be used for searching items
	const HandleInputChange = (event) => {
		setValue(event.target.value)
	}
	// remain in sync with data
	useEffect(() => {
		setItems(Items)
	}, [items, lists])
	// close the dropdown on outside click
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				DropDownRef.current &&
				!DropDownRef.current.contains(event.target)
			) {
				setShowDropDown(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div className="form-group ">
			<label className="label">Multi Select DropDown</label>
			<div className="input-outer">
				<div className="multiDrop" ref={DropDownRef}>
					<div className="multiDropInput">
						{lists.length > 0 &&
							lists.map((i) => (
								<div key={i.id} className="TagItem">
									<span>{i.name}</span>
									<p
										onClick={() => HandleRemovedItems(i)}
										className="TagItemRemove"
									>
										<FontAwesomeIcon icon={faXmark} />
									</p>
								</div>
							))}
						<input
							type="text"
							className="form-control form-control-1 multiInput"
							placeholder="Select"
							onClick={handleShow}
							value={value}
							onChange={(e) => HandleInputChange(e)}
						/>
					</div>
					{showDropDown && (
						<div className="dropBox ">
							<div className="listitem" data-index-id="0">
								{value.length === 0 &&
									items.length > 0 &&
									items.map((i) => (
										<div
											key={i.id}
											className="ms-Suggestions-item"
											onClick={() =>
												HandleSelectedItem(i)
											}
										>
											{i.name}
										</div>
									))}
								{value.length !== 0 &&
									items
										.filter((list) =>
											list.name
												.toLowerCase()
												.includes(value.toLowerCase())
										)
										.map((item) => (
											<div
												key={item.id}
												className="ms-Suggestions-item"
												data-id="0"
												onClick={() =>
													HandleSelectedItem(item)
												}
											>
												{item.name}
											</div>
										))}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default MultiSelectdropdown

import React from 'react'
const FormFooter = ({ ...props }) => {
	return (
		<div className="flex flex-col items-center justify-center px-[30px] pb-[30px]">
			<div className="footer-block text-[14px]  text-[#6e7985]">
				{props.message1}?&nbsp;
				<a href={props.link1} className="text-[#C70039]">
					{props.linktext1}
				</a>
			</div>
			<div className="mt-[7px] text-[14px]  text-[#6e7985]">
				{props.message2}?{' '}
				<a href={props.link2} className="text-[#C70039]">
					{props.linktext2}
				</a>
			</div>
		</div>
	)
}
export default FormFooter

import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
const AdvanceDropDown = ({
	labelName,
	lists,
	items,
	onItemsRemoved,
	onItemsSelect
}) => {
	const [show, setShow] = useState(false)
	const [value, setValue] = useState('')
	const [filteredItems, setFilteredItems] = useState([])
	const DropDownRef = useRef()

	const handleShow = () => {
		setShow((prev) => !prev)
	}
	// pass the selected item to parent component then it will be removed their
	const HandleRemovedItem = (item) => {
		onItemsRemoved(item)
	}
	// pass the selected item to parent component and it will be stored their
	const HandleSelectedItem = (item) => {
		onItemsSelect(item)
		setShow((prev) => !prev)
	}
	// start searching on user input
	useEffect(() => {
		if (value.length > 0) {
			setFilteredItems(
				items.filter((item) =>
					item.h6.toLowerCase().includes(value.toLowerCase())
				)
			)
		} else {
			setFilteredItems(items)
		}
	}, [value, items])

	// close dropdown on outside click
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				DropDownRef.current &&
				!DropDownRef.current.contains(event.target)
			) {
				setShow(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<>
			<div className="form-group">
				{labelName && <label className="label">{labelName}</label>}
				<div className="input-outer" ref={DropDownRef}>
					<div className="advDrop">
						<div className="advDropInput">
							{lists.length > 0 &&
								lists.map((list) => (
									<div
										key={list.id}
										className={`AdvanceTagItem ${list.id}`}
									>
										<div className="AdvancedItemInfo">
											<h6>{list.h6}</h6>
											<p style={{ color: list.color }}>
												{list.p}
											</p>
										</div>
										<span
											className="advancedItemRemove"
											onClick={() =>
												HandleRemovedItem(list)
											}
										>
											<FontAwesomeIcon icon={faXmark} />
										</span>
									</div>
								))}
							<input
								type="text"
								className="form-control form-control-1 advInput"
								placeholder="Select.."
								onClick={handleShow}
								value={value}
								onChange={(e) => setValue(e.target.value)}
							/>
						</div>
						{show && (
							<div className="advdropBox">
								{value.length === 0
									? items.map((item) => (
											<div
												key={item.id}
												className={item.class}
												onClick={() =>
													HandleSelectedItem(item)
												}
											>
												<h6>{item.h6}</h6>
												<p
													style={{
														color: item.color
													}}
												>
													{item.p}
												</p>
											</div>
										))
									: filteredItems.map((item) => (
											<div
												key={item.id}
												className={item.class}
												onClick={() =>
													HandleSelectedItem(item)
												}
											>
												<h6>{item.h6}</h6>
												<p>{item.p}</p>
											</div>
										))}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default AdvanceDropDown

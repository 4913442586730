const DATA = [
	{
		id: 'dashboard',
		Heading: 'Dashboard',
		children: [
			{
				title: 'Welcom Screen',
				icon: 'line-pie-chart'
			},
			{
				title: 'Risk Profile',
				icon: 'line-bar-chart'
			}
		]
	},
	{
		id: 'application',
		Heading: 'Application',
		children: [
			{
				id: 'riskmanagement',
				title: 'Risk Management',
				icon: 'line-gallery',
				data: [
					{ href: '/', name: '  Risk Dashboards' },
					{ href: '/', name: '  Risk Registers' },
					{ href: '/', name: ' All Risks' },
					{ href: '/', name: ' Top Risks' },
					{ href: '/', name: ' Strategic Risks' },
					{ href: '/', name: '  Operational Risks' },
					{ href: '/', name: ' Financial Risks' },
					{ href: '/', name: '   Legal and Regulatory Risks' },
					{ href: '/', name: '   Reputation Risks' },
					{ href: '/', name: '  ESG Risks' },
					{ href: '/', name: '   Risk Treatment Plans' },
					{ href: '/', name: '   Risk Taxonomy' },
					{ href: '/', name: '  Risk Reporting' },
					{ href: '/', name: '  KRI Library' },
					{ href: '/', name: ' Risk Survey' },
					{ href: '/', name: '   Risk Governance' }
				]
			},
			{
				id: 'internalcontrols',
				title: 'Internal Controls',
				icon: 'line-gallery',
				data: [
					{ href: '/', name: 'Controls Dashboards' },
					{ href: '/', name: '   Risk and Control Matrix' },
					{ href: '/', name: ' Controls Catalog' },
					{ href: '/', name: ' Control Execution' },
					{ href: '/', name: 'Control Test Plans' },
					{ href: '/', name: '  Control Self-Assessment' },
					{ href: '/', name: ' Control Assessments' },
					{ href: '/', name: ' Control Effectiveness' },
					{ href: '/', name: '  Control Deficiency' },
					{ href: '/', name: 'Authority Documents' }
				]
			},
			{
				id: 'issuemanagement',
				title: 'Issue Management',
				icon: 'line-gallery',
				data: [
					{ href: '/', name: 'Issues Dashboard' },
					{ href: '/', name: 'Loss Events' },
					{ href: '/', name: 'Incidents' }
				]
			},
			{
				id: 'actionmanagement',
				title: 'Action Management',
				icon: 'line-gallery',
				data: [
					{ href: '/', name: '  Actions Dashboard' },
					{ href: '/', name: '  Actions List' },
					{ href: '/', name: 'Open Actions' },
					{ href: '/', name: 'Overdue Actions' },
					{ href: '/', name: ' My Actions' }
				]
			}
		]
	},
	{
		id: 'settings',
		Heading: 'Settings',
		children: [
			{
				id: 'usermanagement',
				title: 'User Management',
				icon: 'line-settings',
				data: [
					{ href: '/', name: '  User Dashboard' },
					{ href: '/', name: 'User List' },
					{ href: '/', name: 'User Management' },
					{ href: '/', name: 'Group Management' },
					{ href: '/', name: 'Permissions' }
				]
			},
			{
				id: 'applicationsetting',
				title: 'Application Settings',
				icon: 'line-settings',
				data: [
					{ href: '/', name: ' General' },
					{ href: '/', name: 'Mail and Notifications' }
				]
			},
			{
				id: 'customizations',
				title: 'Customizations',
				icon: 'line-settings',
				data: [
					{ href: '/', name: ' Organization' },
					{ href: '/', name: ' Sites and Locations' }
				]
			},
			{
				id: 'knowledgebase',
				title: 'Knowledgebase',
				icon: 'line-settings',
				data: [
					{ href: '/', name: ' Risk Taxonomy' },
					{ href: '/', name: '  AGI Risk Identification' }
				]
			}
		]
	}
]
export default DATA

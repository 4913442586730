import React, { useState, useEffect } from 'react'
import rangeData from '../../components/Dropdowns/Rangedropdown-jsondata.js/range-data'
import RangeDropDown from '../../components/Dropdowns/Rangedropdown'
const RangeDropDownPage = () => {
	const [value, setValue] = useState('')
	const [color, setColor] = useState('')
	const [bgcolor, setBgcolor] = useState('#FFFFFF')
	const [placeHolderColor, setPlaceholderColor] = useState(null)
	const [checkboxStates, setCheckboxStates] = useState({
		checkbox1: false,
		checkbox2: false,
		checkbox3: false,
		checkbox4: false,
		checkbox5: false
	})
	const [disabled, setDisabled] = useState({
		1: false,
		2: true,
		3: true,
		4: true,
		5: true
	})
	const handleCheckboxChange = (name) => {
		setCheckboxStates((prevStates) => {
			const updatedStates = { ...prevStates, [name]: !prevStates[name] }
			if (updatedStates.checkbox1) {
				setDisabled((prev) => ({ ...prev, 2: false }))
			} else if (!updatedStates.checkbox1 && !updatedStates.checkbox2) {
				setDisabled((prev) => ({ ...prev, 1: false }))
			}
			if (updatedStates.checkbox1 && updatedStates.checkbox2) {
				setDisabled((prev) => ({ ...prev, 3: false }))
			}
			if (updatedStates.checkbox3) {
				setDisabled((prev) => ({ ...prev, 4: false }))
			}
			if (updatedStates.checkbox4) {
				setDisabled((prev) => ({ ...prev, 5: false }))
			}
			if (!updatedStates.checkbox1) {
				setDisabled((prev) => ({
					...prev,
					2: true,
					3: true,
					4: true,
					5: true
				}))
			}
			if (!updatedStates.checkbox2) {
				setDisabled((prev) => ({ ...prev, 3: true, 4: true, 5: true }))
			}
			if (!updatedStates.checkbox3) {
				setDisabled((prev) => ({ ...prev, 4: true, 5: true }))
			}
			if (!updatedStates.checkbox4) {
				setDisabled((prev) => ({ ...prev, 5: true }))
			}

			// update the checkboxe check state here
			if (!updatedStates.checkbox4) {
				updatedStates.checkbox5 = false
			}
			if (!updatedStates.checkbox3) {
				updatedStates.checkbox4 = false
				updatedStates.checkbox5 = false
			}
			if (!updatedStates.checkbox2) {
				updatedStates.checkbox3 = false
				updatedStates.checkbox4 = false
				updatedStates.checkbox5 = false
			}
			if (!updatedStates.checkbox1) {
				updatedStates.checkbox2 = false
				updatedStates.checkbox3 = false
				updatedStates.checkbox4 = false
				updatedStates.checkbox5 = false
			}

			return updatedStates
		})
	}
	useEffect(() => {
		if (checkboxStates.checkbox1) {
			setValue('Select')
			setBgcolor('')
			setPlaceholderColor(false)
			setColor('')
		}
		if (checkboxStates.checkbox2) {
			setValue('Select')
			setBgcolor('')
			setPlaceholderColor(false)
			setColor('')
		}
		if (checkboxStates.checkbox1 && checkboxStates.checkbox2) {
			setValue('Ineffective')
			setBgcolor('#ED0505')
			setPlaceholderColor(true)
			setColor('white')
		}
		if (
			(checkboxStates.checkbox1 && checkboxStates.checkbox4) ||
			(checkboxStates.checkbox1 && checkboxStates.checkbox5) ||
			(checkboxStates.checkbox2 && checkboxStates.checkbox4) ||
			(checkboxStates.checkbox2 && checkboxStates.checkbox5)
		) {
			setValue('Ineffective')
			setBgcolor('#ED0505')
			setPlaceholderColor(true)
			setColor('white')
		}
		if (
			(checkboxStates.checkbox1 &&
				checkboxStates.checkbox2 &&
				checkboxStates.checkbox3) ||
			(checkboxStates.checkbox1 && checkboxStates.checkbox3) ||
			(checkboxStates.checkbox2 && checkboxStates.checkbox3)
		) {
			setValue('Partially-Effective')
			setBgcolor('#FFD519')
			setColor('white')
		}
		const allUnchecked = Object.values(checkboxStates).every(
			(value) => !value
		)
		if (allUnchecked) {
			setValue('Select')
			setBgcolor('')
			setPlaceholderColor(false)
			setColor('')
		}
	}, [checkboxStates])

	return (
		<RangeDropDown
			labelName={'Range Dropdown'}
			RangeData={rangeData}
			onChange={(name) => handleCheckboxChange(name)}
			checkboxstates={checkboxStates}
			Disabled={disabled}
			placeHolderColor={placeHolderColor}
			value={value}
			color={color}
			bgcolor={bgcolor}
		/>
	)
}

export default RangeDropDownPage

import React from 'react'
const SelectImage = ({ onchange, image }) => {
	return (
		<div
			className=" flex h-[248px]  w-[200px] flex-col 
    items-center justify-between "
		>
			<div className="rounded-2 h-[200px] w-[200px] bg-[#f8f8f3]">
				{image && (
					<img
						src={image}
						alt="img"
						width={'100%'}
						height={'200px'}
						style={{ maxHeight: '200px', minHeight: '200px' }}
					/>
				)}
			</div>
			<div className="w-full ">
				<label
					htmlFor="image_Input"
					style={{ cursor: 'pointer' }}
					className="btn btn-large btn-primary w-full "
				>
					Select Image
				</label>
				<input
					type="file"
					id="image_Input"
					onChange={onchange}
					style={{ display: 'none' }}
				/>
			</div>
		</div>
	)
}
export default SelectImage

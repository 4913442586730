import React, { useEffect, useState } from 'react'
import Checkbox from '../Checkbox'
const CheckboxGroup = ({
	type = 'vertical',
	options,
	onChange,
	className,
	iconClass
}) => {
	const [checkedItems, setCheckedItems] = useState({})
	// create an object for keeping track of checked and unchecked items

	// this will get called whe the checked state changes
	const handleChange = (value) => {
		setCheckedItems((prev) => {
			const newCheckedItems = { ...prev, [value]: !prev[value] }
			onChange(newCheckedItems)
			return newCheckedItems
		})
	}

	return (
		<div
			className={`relative flex list-none  flex-wrap gap-[8px] whitespace-pre-line ${type == 'horizontal' ? '' : 'flex-col'}`}
		>
			{options.map((option) => (
				<Checkbox
					key={option.value}
					id={option.value}
					label={option.label}
					checked={!!checkedItems[option.value]}
					className={className}
					iconClass={iconClass}
					onChange={() => handleChange(option.value)}
					disabled={option.disabled}
				/>
			))}
		</div>
	)
}
export default CheckboxGroup

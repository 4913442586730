import React, { useState } from 'react'
import CheckboxGroup from '../../components/ui/Form/CheckboxGroup'
import Checkbox from '../../components/ui/Form/Checkbox'
import RadioGroup from '../../components/ui/Form/RadioGroup'
import Radio from '../../components/ui/Form/Radio'
const CheckmarkPage = () => {
	const [test, setTest] = useState(false)
	// Horizontal Checkbox Options
	const horizontalCheckOptions = [
		{ label: 'CHECKBOX 1', value: 'horizontal1', disabled: false },
		{ label: 'CHECKBOX 2', value: 'horizontal2', disabled: false },
		{ label: 'CHECKBOX 3', value: 'horizontal3', disabled: false },
		{ label: 'CHECKBOX 4', value: 'horizontal4', disabled: true }
	]
	const handleHorizontalCheckOptions = (checkedItems) => {
		console.log(checkedItems)
	}

	// Vertical Checkbox Options
	const verticalCheckOptions = [
		{ label: 'CHECKBOX 1', value: 'vertical1', disabled: false },
		{ label: 'CHECKBOX 2', value: 'vertical2', disabled: false },
		{ label: 'CHECKBOX 3', value: 'vertical3', disabled: false },
		{ label: 'CHECKBOX 4', value: 'vertical4', disabled: true }
	]
	const handleVerticalCheckOptions = (checkedItems) => {
		console.log(checkedItems)
	}

	// Inverse Checkbox Options
	const inverseCheckOptions = [
		{ label: 'CHECKBOX 1', value: 'inverse1', disabled: false },
		{ label: 'CHECKBOX 2', value: 'inverse2', disabled: false },
		{ label: 'CHECKBOX 3', value: 'inverse3', disabled: false },
		{ label: 'CHECKBOX 4', value: 'inverse4', disabled: true }
	]
	const handleInverseCheckOptions = (checkedItems) => {
		console.log(checkedItems)
	}

	// Inverse Primary Checkbox Options
	const inversePrimaryCheckOptions = [
		{ label: 'CHECKBOX 1', value: 'inverseprimary1', disabled: false },
		{ label: 'CHECKBOX 2', value: 'inverseprimary2', disabled: false },
		{ label: 'CHECKBOX 3', value: 'inverseprimary3', disabled: false },
		{ label: 'CHECKBOX 4', value: 'inverseprimary4', disabled: true }
	]
	const handleInversePrimaryCheckOptions = (checkedItems) => {
		console.log(checkedItems)
	}

	// Custom Checkbox Options
	const [greenCheck, setGreenCheck] = useState(false)
	const [crossCheck, setCrossCheck] = useState(false)
	const [exclamationCheck, setExclamationCheck] = useState(false)

	// Horizontal Radio Group Option
	const horizontalRadioOptions = [
		{ label: 'MALE', value: 'male', disabled: false },
		{ label: 'FEMALE', value: 'female', disabled: false },
		{ label: 'OTHER', value: 'other', disabled: false }
	]
	const handleHorizontalRadioOptions = (checkedItems) => {
		console.log(checkedItems)
	}

	// Vertical Radio Group Option
	const verticalRadioOptions = [
		{ label: 'PHP', value: 'php', disabled: false },
		{ label: 'JAVASCRIPT', value: 'js', disabled: false },
		{ label: 'UIUX', value: 'designer', disabled: false },
		{ label: 'QA', value: 'qa', disabled: false }
	]
	const handleVerticalRadioOptions = (checkedItems) => {
		console.log(checkedItems)
	}

	return (
		<div className="p-[30px]">
			<div className="mt-5">
				<p className="small-text">CHECKMARK</p>
				<h3>CHECKBOX COMPONENT</h3>
				<hr />

				{/* Horizontal Checkbox */}
				<div className="mt-5 grid grid-cols-3 gap-[3px] md:grid-cols-7 lg:grid-cols-7">
					<div className="small-text">CHECKBOX HORIZONTAL</div>
				</div>
				<div className="mt-3 grid grid-cols-1 gap-[3px] md:grid-cols-1 lg:grid-cols-1">
					<div className="col-span-1 flex flex-col gap-[10px]">
						<CheckboxGroup
							type="horizontal"
							options={horizontalCheckOptions}
							onChange={handleHorizontalCheckOptions}
						/>
					</div>
				</div>
				<hr className="mt-3" />

				{/* Vertical Checkbox */}
				<div className="mt-5 grid grid-cols-3 gap-[3px] md:grid-cols-7 lg:grid-cols-7">
					<div className="small-text">CHECKBOX VERTICAL</div>
				</div>
				<div className="mt-3 grid grid-cols-1 gap-[3px] md:grid-cols-1 lg:grid-cols-1">
					<div className="col-span-1 flex flex-col gap-[10px]">
						<CheckboxGroup
							type="vertical"
							options={verticalCheckOptions}
							onChange={handleVerticalCheckOptions}
						/>
					</div>
				</div>
				<hr className="mt-3" />

				{/* Inverse Checkbox */}
				<div className="mt-5 grid grid-cols-3 gap-[3px] md:grid-cols-7 lg:grid-cols-7">
					<div className="small-text">CHECKBOX INVERSE</div>
				</div>
				<div className="mt-3 grid grid-cols-1 gap-[3px] md:grid-cols-1 lg:grid-cols-1">
					<div className="col-span-1 flex gap-[30px]">
						<CheckboxGroup
							type="vertical"
							options={inverseCheckOptions}
							className="inverse-check-square cursor-pointer"
							iconClass="inverse-checkmark"
							onChange={handleInverseCheckOptions}
						/>

						<CheckboxGroup
							type="vertical"
							options={inversePrimaryCheckOptions}
							className="inverse-check-square-purple cursor-pointer"
							iconClass="inverse-checkmark"
							onChange={handleInversePrimaryCheckOptions}
						/>
					</div>
				</div>
				<hr className="mt-3" />

				{/* Custom Checkbox */}
				<div className="mt-5 grid grid-cols-3 gap-[3px] md:grid-cols-7 lg:grid-cols-7">
					<div className="small-text">CHECKBOX CUSTOM</div>
				</div>
				<div className="mt-3 grid grid-cols-1 gap-[3px] md:grid-cols-1 lg:grid-cols-1">
					<div className="col-span-1 flex flex-col gap-[8px]">
						<Checkbox
							id="green-check"
							label="CHECKBOX 1"
							checked={greenCheck}
							onChange={() => setGreenCheck(!greenCheck)}
							className="check-circle check-circle-green"
							iconClass="circle-tick-checkmark top-[10px] left-[10px]"
						/>

						<Checkbox
							id="cross-check"
							label="CHECKBOX 2"
							checked={crossCheck}
							onChange={() => setCrossCheck(!crossCheck)}
							className="check-circle check-circle-red"
							iconClass="circle-cross-checkmark top-[10px] left-[10px]"
						/>

						<Checkbox
							id="exclamation-check"
							label="CHECKBOX 2"
							checked={exclamationCheck}
							onChange={() =>
								setExclamationCheck(!exclamationCheck)
							}
							className="check-circle check-circle-dark"
							iconClass="circle-not-checkmark"
						/>
					</div>
				</div>
				<hr className="mt-3" />

				{/* Horizontal RadioGroup */}
				<div className="mt-5 grid grid-cols-3 gap-[3px] md:grid-cols-7 lg:grid-cols-7">
					<div className="small-text">RADIO HORIZONTAL</div>
				</div>
				<div className="mt-3 grid grid-cols-1 gap-[3px] md:grid-cols-1 lg:grid-cols-1">
					<div className="col-span-1 flex flex-col gap-[8px]">
						<RadioGroup
							name="gender"
							type="horizontal"
							options={horizontalRadioOptions}
							onChange={handleHorizontalRadioOptions}
							className="radio-circle"
						/>
					</div>
				</div>
				<hr className="mt-3" />

				{/* Vertical RadioGroup */}
				<div className="mt-5 grid grid-cols-3 gap-[3px] md:grid-cols-7 lg:grid-cols-7">
					<div className="small-text">RADIO VERTICAL</div>
				</div>
				<div className="mt-3 grid grid-cols-1 gap-[3px] md:grid-cols-1 lg:grid-cols-1">
					<div className="col-span-1 flex flex-col gap-[8px]">
						<RadioGroup
							name="employment"
							type="vertical"
							options={verticalRadioOptions}
							onChange={handleVerticalRadioOptions}
							className="radio-circle"
						/>
					</div>
				</div>
				<hr className="mb-5 mt-3" />
			</div>
		</div>
	)
}

export default CheckmarkPage

import React from 'react'
import Icon from '../../components/ui/icon'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
const ProfileSection = () => {
	const navigate = useNavigate()
	const HandleLogout = () => {
		let email = localStorage.getItem('email')
		if (email) {
			localStorage.removeItem('email')
			navigate('/pages/login')
		}
		// console.log(email)
	}
	return (
		<>
			<nav className="nav">
				<Link to="view-profile" className="text-sm">
					<Icon
						className="icon-fill-grey200 mr-3"
						name="line-profile-card"
						width={'20px'}
						height={'20px'}
						viewbox={'0 0 27 23'}
					/>{' '}
					View Profile
				</Link>
				<Link to="/pages/change-password" className="text-sm">
					<Icon
						className="icon-fill-grey200 mr-3"
						name="line-lock-dot"
						width={'20px'}
						height={'20px'}
						viewbox={'0 0 27 23'}
					/>
					Change Password
				</Link>
				<a className="text-sm">
					<Icon
						className="icon-fill-grey200 mr-3"
						name="line-user-settings"
						width={'20px'}
						height={'20px'}
						viewbox={'0 0 27 23'}
					/>
					Account Settings
				</a>
			</nav>

			<nav className="nav">
				<Link to="/pages/contact-us" className="text-sm">
					<Icon
						className="icon-fill-grey200 mr-3"
						name="line-help"
						width={'20px'}
						height={'20px'}
						viewbox={'0 0 27 23'}
					/>
					Help Center
				</Link>
				<a className="text-sm" onClick={HandleLogout}>
					<Icon
						className="icon-fill-grey200 mr-3"
						name="line-signout"
						width={'20px'}
						height={'20px'}
						viewbox={'0 0 27 23'}
					/>{' '}
					Log Out
				</a>
			</nav>
		</>
	)
}

export default ProfileSection

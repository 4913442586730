import { useState } from 'react'
import Signature from '../../components/ui/Signature'
const Signatures = () => {
	const [images, setImages] = useState({
		drawimage: null
	})
	return (
		<>
			<Signature
				labelName={'Draw Signature'}
				getDrawnSignatureUrl={(url) =>
					setImages({ ...images, drawimage: url })
				}
				getTypedSignatureUrl={(url) => console.log('typed url ', url)}
				getSelectedSignatureUrl={(url) => console.log(url)}
				getUploadedSignatureUrl={(url) => console.log(url)}
			/>
			
		</>
	)
}

export default Signatures

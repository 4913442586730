import React from 'react'

const AddSquare = ({ fillColor, width, height, ...rest }) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		viewBox="0 0 23 23"
		{...rest}
	>
		<path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5ZM11 11V7H13V11H17V13H13V17H11V13H7V11H11Z" />
	</svg>
)
export default AddSquare

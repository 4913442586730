import React, { useState, useEffect, useRef } from 'react'
import { CountriesData } from './CountriesData'
const CountrySelector = ({ label, onChange }) => {
	const [countries, setCountries] = useState(CountriesData) //get the countries list
	const [selectedCountry, setselectedCountry] = useState(countries[110]) //default country
	const [filterText, setFilterText] = useState('')
	const [focus, setFocus] = useState(false)
	const [show, setShow] = useState(false)
	const divRef = useRef(null)
	const CloseRef = useRef(null)

	//store the userinput
	const handleContentChange = (event) => {
		// console.log(event.target.innerText)
		setFilterText(event.target.innerText)
	}

	const handleShow = () => {
		setShow((prev) => !prev)
	}

	// on country select/click this function will pass that country to the callback function
	const handleCountryClick = (country) => {
		setselectedCountry(country)
		onChange(country) //pass the selected country to the component
		setFocus(true)
		setFilterText('')
		setShow(false)
	}
	//close the dropdown even if the user clicks outside it
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (CloseRef.current && !CloseRef.current.contains(event.target)) {
				setShow(false)
				setFocus(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])
	return (
		<div className="form-group relative">
			<label className="label">{label}</label>
			<div ref={CloseRef} style={{ position: 'relative' }}>
				<div
					className={`form-control font-interui flex items-center focus:border-[#A3A2A0] ${focus ? '!border !border-solid !border-black' : ''}`}
					onClick={handleShow}
					tabIndex={0}
				>
					<div className="relative flex w-full items-center gap-[6px]">
						<div className="flex !h-6 w-6 items-center">
							<img
								src={selectedCountry.flag}
								alt="image"
								width={'20.5px'}
								height={'20px'}
							/>
						</div>
						<div className="flex w-full items-center focus:border-none focus:outline-none">
							<div
								className="w-full  focus:border-none focus:outline-none"
								contentEditable={true}
								ref={divRef}
								onInput={handleContentChange}
								suppressContentEditableWarning={true}
							>
								{selectedCountry.country}
							</div>
							<div className="relative right-[0px] top-[-1px] h-[9px] w-[9px] origin-center rotate-45 border-2 border-l-0 border-t-0 border-[#555]"></div>
						</div>
					</div>
				</div>
				{show && (
					<div className="absolute top-[31px] z-50 max-h-[200px] w-full overflow-x-hidden overflow-y-scroll border border-solid border-[#a3a2a0] bg-white">
						{countries
							.filter((country) =>
								country.country
									.toLowerCase()
									.includes(filterText.toLowerCase())
							)
							.map((country, index) => (
								<div
									key={index}
									onClick={() => handleCountryClick(country)}
									className="font-interui flex h-8 w-full cursor-pointer items-center px-[10px] py-[5px] hover:bg-[#0000000d]"
								>
									<div className="font-interui flex items-center gap-[6px]">
										<div className="flex h-5 w-6 items-center">
											<img
												src={country.flag}
												alt={country.country}
												width={'20.5px'}
												height={'20px'}
												style={{
													height: '20px !important'
												}}
											/>
										</div>
										<span className="text-[14px] font-normal text-[#41505f]">
											{country.country}
										</span>
									</div>
								</div>
							))}
					</div>
				)}
			</div>
		</div>
	)
}

export default CountrySelector

import React, { useState } from 'react'
import { Calendar } from 'primereact/calendar'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/saga-blue/theme.css'
const DatePicker = ({ labelName, onDateChange, currentDate }) => {
	//pass the selected date to callback function (onDateChange)
	const HandleDateChange = (event) => {
		// console.log(event.value)
		onDateChange(event.value)
	}
	return (
		<div className="form-group">
			<label className="label">{labelName}</label>
			<div className="input-outer">
				<div className="input-container !font-interui relative">
					<Calendar
						value={currentDate}
						onChange={HandleDateChange}
						dateFormat="dd M yy"
						placeholder="Choose date"
						showIcon
					/>
				</div>
			</div>
		</div>
	)
}

export default DatePicker

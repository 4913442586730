import { useState, useEffect, useRef } from 'react'
import Checkbox from '../../ui/Form/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
const RangeDropDown = ({
	labelName,
	RangeData,
	checkboxstates,
	Disabled,
	value,
	placeHolderColor,
	bgcolor,
	color,
	onChange
}) => {
	const [showItems, setShowItems] = useState(false)
	const [checkboxStates, setCheckboxStates] = useState(checkboxstates)
	const [disabled, setDisabled] = useState(Disabled)
	// it will get called on checkbox state-change and will be passed to parent component
	const handleCheckboxChange = (name) => {
		onChange(name)
	}
	// remain in sync with data changes
	useEffect(() => {
		setCheckboxStates(checkboxstates)
		setDisabled(Disabled)
	}, [checkboxstates, Disabled])

	const HandleshowItems = () => {
		setShowItems((prev) => !prev)
	}
	const dropdownRef = useRef(null)
	// close dropdow on outside click
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setShowItems(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])
	// map data
	const RangeLists = (
		<ul>
			{RangeData.map((data) => {
				return (
					<div className="listitem" key={data.id}>
						<span className="list-dropdown-headings">
							{' '}
							{data.category}
						</span>
						{data.label.map((label) => (
							<li key={label.labelId + '1'}>
								<Checkbox
									key={label.labelId + '++'}
									disabled={disabled[label.labelId]}
									id={label.labelId}
									label={label.text}
									checked={
										checkboxStates[
											`checkbox${label.labelId}`
										] === true
									}
									onChange={() =>
										handleCheckboxChange(
											'checkbox' + label.labelId
										)
									}
									className="check-square"
									iconClass="simple-checkmark"
								/>
							</li>
						))}
					</div>
				)
			})}
		</ul>
	)
	return (
		<>
			<div className="form-group relative">
				{<label className="label">{labelName} </label>}
				<div className="input-outer" ref={dropdownRef}>
					<div className="dropsearch">
						<div className="dropsearchinput">
							<input
								type="text"
								placeholder="Select"
								readOnly
								value={value}
								onClick={HandleshowItems}
								className={`form-control  searchInput ${placeHolderColor ? 'placeholdercolor' : ''}`}
								style={{
									backgroundColor: bgcolor,
									color: color
								}}
							/>
							<FontAwesomeIcon
								icon={faAngleDown}
								className="drop-icons"
								onClick={HandleshowItems}
								style={{ color: color }}
							/>
						</div>
						{showItems && (
							<div className="listbox multiple-range absolute">
								{/* render mapped data */}
								{RangeLists}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default RangeDropDown

import React, { useState } from 'react'

import AuthLayout from '../../Auth-Layout'

import FormHeader from '../../components/Common/FormHeader'
import Formfooter from '../../components/Common/FormFooter'
import FormBody from '../../components/Common/FormBody'
import AlertWrapper from '../../components/ui/Alerts/AlertWrapper'
import Button from '../../components/ui/Buttons/Button'
import TextBox from '../../components/ui/TextBox'
import Icon from '../../components/ui/icon'
import Alert from '../../components/ui/Alerts'

import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'

const SendMagicLink = () => {
	const [email, setEmail] = useState('')
	const [notifications, setNotifications] = useState([])

	const HandleChange = (data) => {
		setEmail(data)
	}

	const addNotification = (type, message, icon) => {
		setNotifications((prevNotifications) => [
			{ id: new Date().getTime(), type, message, icon },
			...prevNotifications
		])
	}
	const handleSubmit = (event) => {
		event.preventDefault()
		if (!email) {
			addNotification(
				'danger',
				'Please insert your email address.',
				faTriangleExclamation
			)
		} else {
			const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			let isValid = EMAIL_REGEXP.test(email)
			if (!isValid) {
				addNotification(
					'danger',
					'Please enter a valid email address.',
					faTriangleExclamation
				)
			} else {
				addNotification(
					'success',
					'Congratulation! Magic link generated and shared with your email address.',
					faCheck
				)
			}
		}
	}

	return (
		<>
			<AlertWrapper>
				{notifications
					.sort((a, b) => b.id - a.id)
					.map((notification, index) => (
						<div key={notification.id}>
							<Alert
								autoClose={3000}
								id={index + 1}
								className={`alert-${notification.type}`}
								message={notification.message}
								Icon={notification.icon}
								onClose={() => {
									setNotifications((prevNotifications) =>
										prevNotifications.filter(
											(n) => n.id !== notification.id
										)
									)
								}}
							/>
						</div>
					))}
			</AlertWrapper>

			<AuthLayout>
				<FormHeader
					title="Send Magic Link"
					message="Welcome! Please submit to Sign-in"
				/>
				<FormBody>
					<TextBox
						onChange={HandleChange}
						labelName="Email Address"
						type={'text'}
						className={'mb-4'}
					/>
					<div className="mb-3">
						<p className="small-text ">
							By clicking <strong>Submit</strong> below, you agree
							to our Terms and Conditions and Privacy Policy.
						</p>
					</div>
					<Button
						className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
						isIconBtn={true}
						onClick={handleSubmit}
					>
						<Icon
							name="line-lock"
							width={'16px'}
							height={'16px'}
							className={'icon-fill-white'}
							viewbox={'0 0 24 24'}
						/>{' '}
						Submit
					</Button>
				</FormBody>
				<Formfooter
					message1="Don't have an account"
					linktext1="Create an Account"
					link1="register"
					message2="Need assistance or have questions"
					linktext2="Contact Support"
					link2="contact-us"
				/>
			</AuthLayout>
		</>
	)
}

export default SendMagicLink

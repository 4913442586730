import React, { useState } from 'react'

const Accordion = ({
	labelName,
	Component1,
	Component2,
	Component3,
	Component4
}) => {
	const [first, setFirst] = useState(false)
	const [second, setSecond] = useState(false)
	const [third, setThird] = useState(false)
	const [forth, setForth] = useState(false)

	// this function will be responsible for displaying the content of first Accordion and hide others
	const handleFirst = () => {
		setFirst((prv) => !prv)
		setSecond(false)
		setThird(false)
		setForth(false)
	}

	// this function will be responsible for displaying the content of second Accordion and hide others
	const handleSecond = () => {
		setSecond((prv) => !prv)
		setFirst(false)
		setThird(false)
		setForth(false)
	}

	// this function will be responsible for displaying the content of third Accordion and hide others
	const handleThird = () => {
		setThird((prv) => !prv)
		setFirst(false)
		setSecond(false)
		setForth(false)
	}

	// this function will be responsible for displaying the content of fourth Accordion and hide others
	const handleForth = () => {
		setForth((prev) => !prev)
		setThird(false)
		setFirst(false)
		setSecond(false)
	}
	const labelClass =
		'relative mb-0 inline-flex items-center py-[5px] text-[14px] font-semibold [#323130] text-opacity-100 text-[#323130] '
	return (
		<div className={`form-group `}>
			{labelName && <label className="label">{labelName} </label>}
			<div className="input-outer">
				<div className="align-center  w-ful flex h-full">
					<div className="main-shadow flex  w-full flex-col  items-center">
						<div
							className={`${first ? ' activeee pb-4  shadow-sm ' : 'shadow-sm '}  !w-full rounded-sm  px-5`}
						>
							<div
								className="flex  h-[48px] cursor-pointer items-center   justify-between"
								onClick={handleFirst}
							>
								<div className="flex flex-1">
									<div className="mr-4 flex flex-grow items-center ">
										<label className={`${labelClass}`}>
											{' '}
											Create New layer{' '}
										</label>
									</div>
									<div className="flex-grow-[2]">
										<label className={`${labelClass}`}>
											{' '}
											Create a new Empty Layer{' '}
										</label>
									</div>
								</div>

								<div
									className={`${first ? 'active' : ''} toggle`}
								></div>
							</div>
							{/* inner card  */}
							<div
								className={`expand ${first ? 'activeee block px-6 pb-4' : 'hide-show-element'}`}
							>
								{Component1}
							</div>
						</div>

						<div
							className={`${second ? 'activeee  shadow-sm ' : 'shadow-sm'}  !w-full rounded-sm px-5`}
						>
							<div
								className="flex  h-[48px] cursor-pointer items-center  justify-between font-semibold"
								onClick={handleSecond}
							>
								<div className="flex flex-1">
									<div className="mr-4 flex flex-grow items-center ">
										<label className={`${labelClass}`}>
											{' '}
											Create New layer{' '}
										</label>
									</div>
									<div className="flex-grow-[2]">
										<label className={`${labelClass}`}>
											{' '}
											Create a new Empty Layer{' '}
										</label>
									</div>
								</div>

								<div
									className={`${second ? 'active' : ''} toggle`}
								></div>
							</div>
							{/* inner card  */}
							<div
								className={`expand ${second ? 'activeee block px-6 pb-4' : 'hide-show-element'}`}
							>
								{Component2}
							</div>
						</div>

						<div
							className={` ${third ? 'activeee shadow-sm ' : ''} !w-full  rounded-sm px-5 shadow-sm`}
						>
							<div
								className="flex  h-[48px] cursor-pointer items-center  justify-between font-semibold"
								onClick={handleThird}
							>
								<div className="flex flex-1">
									<div className="mr-4 flex flex-grow items-center ">
										<label className={`${labelClass}`}>
											{' '}
											Create New layer{' '}
										</label>
									</div>
									<div className="flex-grow-[2]">
										<label className={`${labelClass}`}>
											{' '}
											Create a new Empty Layer{' '}
										</label>
									</div>
								</div>

								<div
									className={` ${third ? 'active' : ''} toggle`}
								></div>
							</div>
							{/* inner card  */}
							<div
								className={`expand ${third ? 'activeee block pb-4' : 'hide-show-element'}`}
							>
								{Component3}
							</div>
						</div>

						<div
							className={`${forth ? '  activeee ' : ''} !w-full rounded-sm px-5 shadow-sm `}
						>
							<div
								className="flex  h-[48px] cursor-pointer items-center  justify-between font-semibold"
								onClick={handleForth}
							>
								<div className="flex flex-1">
									<div className="mr-4 flex flex-grow items-center ">
										<label className={`${labelClass}`}>
											{' '}
											Create New layer{' '}
										</label>
									</div>
									<div className="flex-grow-[2]">
										<label className={`${labelClass}`}>
											{' '}
											Create a new Empty Layer{' '}
										</label>
									</div>
								</div>

								<div
									className={` ${forth ? 'active' : ''} toggle`}
								></div>
							</div>
							{/* inner card  */}

							<div
								className={`expand ${forth ? 'block pb-4' : 'hide-show-element'}`}
							>
								{Component4}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Accordion

import React from 'react'

const PieChart = ({ fillColor, width, height, ...rest }) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		viewBox="0 0 23 23"
		{...rest}
	>
		<path d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM4 5V19H20V5H4ZM16.9 13C16.4367 15.2822 14.419 17 12 17C9.23858 17 7 14.7614 7 12C7 9.58104 8.71776 7.56329 11 7.10002V13H16.9ZM16.9 11H13V7.10002C14.9591 7.4977 16.5023 9.04087 16.9 11Z" />
	</svg>
)
export default PieChart

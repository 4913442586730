export const PRODUCTS = [
	{
		id: '1000',
		code: 'f230fh0g2',
		name: 'Amboo Watch2',
		description: 'Product Description',
		image: 'bamboo-watch.jpg',
		price: 65,
		category: 'Accessories',
		test: [
			{
				quantity: 48,
				asca: 84,
				test: 32,
				aaa: 'TIGER'
			}
		],
		inventoryStatus: 'INSTOCK',
		rating: 4.4
	},
	{
		id: '1008',
		code: 'f230fh0g2',
		name: 'Amboo Watch',
		description: 'Product Description',
		image: 'bamboo-watch.jpg',
		price: 65,
		category: 'Accessories',
		test: [
			{
				quantity: 24
			}
		],
		inventoryStatus: 'INSTOCK',
		rating: 4.2
	},
	{
		id: '1010',
		code: 'f230fh0g3',
		name: 'Amboo Watch',
		description: 'Product Description',
		image: 'bamboo-watch.jpg',
		price: 65,
		category: 'Accessories',
		test: [
			{
				quantity: 24
			}
		],
		inventoryStatus: 'INSTOCK',
		rating: 4.8
	},
	{
		id: '1011',
		code: 'f230fh0g3',
		name: 'Amboo Watch',
		description: 'Product Description',
		image: 'bamboo-watch.jpg',
		price: 65,
		category: 'Accessories',
		test: [
			{
				quantity: 24
			}
		],
		inventoryStatus: 'INSTOCK',
		rating: 4.3
	},
	{
		id: '1012',
		code: 'f230fh0g3',
		name: 'Amboo Watch',
		description: 'Product Description',
		image: 'bamboo-watch.jpg',
		price: 65,
		category: 'Accessories',
		test: [
			{
				quantity: 24
			}
		],
		inventoryStatus: 'INSTOCK',
		rating: 5
	},
	{
		id: '1013',
		code: 'f230fh0g3',
		name: 'Amboo Watch',
		description: 'Product Description',
		image: 'bamboo-watch.jpg',
		price: 65,
		category: 'Accessories',
		test: [
			{
				quantity: 24
			}
		],
		inventoryStatus: 'INSTOCK',
		rating: 5
	},
	{
		id: '1014',
		code: 'f230fh0g3',
		name: 'Amboo Watch',
		description: 'Product Description',
		image: 'bamboo-watch.jpg',
		price: 65,
		category: 'Accessories',
		test: [
			{
				quantity: 24
			}
		],
		inventoryStatus: 'INSTOCK',
		rating: 5
	},
	{
		id: '1015',
		code: 'f230fh0g3',
		name: 'Amboo Watch',
		description: 'Product Description',
		image: 'bamboo-watch.jpg',
		price: 65,
		category: 'Accessories',
		test: [
			{
				quantity: 24
			}
		],
		inventoryStatus: 'INSTOCK',
		rating: 4.5
	},
	{
		id: '1016',
		code: 'f230fh0g3',
		name: 'Amboo Watch',
		description: 'Product Description',
		image: 'bamboo-watch.jpg',
		price: 65,
		category: 'Accessories',
		test: [
			{
				quantity: 24,
				asca: 24,
				test: 24,
				aaa: 24
			}
		],
		inventoryStatus: 'INSTOCK',
		rating: 4
	},
	{
		id: '1001',
		code: 'Chamboo',
		name: 'Bamboo Watch',
		description: 'Product Description',
		image: 'bamboo-watch.jpg',
		price: 65,
		category: 'Accessories',
		test: [
			{
				quantity: 28
			}
		],
		inventoryStatus: 'INSTOCK',
		rating: 3.5
	},
	{
		id: '1002',
		code: 'f230fh0g4',
		name: 'Chamboo Watch',
		description: 'Product Description',
		image: 'bamboo-watch.jpg',
		price: 65,
		category: 'Accessories',
		test: [
			{
				quantity: 39
			}
		],
		inventoryStatus: 'INSTOCK',
		rating: 4.7
	}
]

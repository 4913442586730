import React from 'react'

const Checkbox = ({
	id,
	label,
	checked,
	onChange,
	disabled,
	className = 'cursor-pointer simple-check-square ',
	iconClass = 'simple-checkmark',
	...props
}) => {
	// console.log(checked)
	const disabledClass = '!cursor-not-allowed !text-[grey]'
	const disabledInputClass =
		'!cursor-not-allowed !border-gray-400 !bg-gray-100'

	return (
		<div
			className={`chk relative flex flex-row gap-[10px] overflow-hidden  whitespace-pre-line ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
		>
			<input
				type="checkbox"
				id={id}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
				className={`${className} ${disabled ? disabledInputClass : ''}`}
				{...props}
			/>
			{label && (
				<label
					htmlFor={id}
					className={`cursor-pointer select-none !text-[14px] ${disabled ? disabledClass : ''}`}
				>
					{label}
					<span
						className={`${checked && !disabled ? iconClass : ''}`}
					></span>
				</label>
			)}
		</div>
	)
}
export default Checkbox

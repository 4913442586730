import React from 'react'
import ImageEditor from '../../components/ui/ImageEditor'
const ImageCropper = () => {
	const HandleImage = (image) => {
		console.log(image)
	}
	return (
		<ImageEditor
			labelName={'Select User Image'}
			onImageCrop={(image) => HandleImage(image)}
		/>
	)
}

export default ImageCropper

import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/Drawer'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import ElasticTextBox from '../../../components/ui/Elastic-textbox'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
const AdditionalInfoDrawer = ({ show, setShow }) => {
	const [showDrawer, setShowDrawer] = useState(false)
	const [drawerPosition, setDrawerPosition] = useState('right')
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(false)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)

	const [Formdata, setFormdata] = useState({
		field1: '',
		field2: '',
		field3: '',
		field4: ''
	})
	const HANDLEFORMDATA = (field, value) => {
		console.log(field, value)
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	useEffect(() => {
		setBody(FORM)
	}, [FormData])

	const FORM = (
		<>
			<div className="flex w-[350px] flex-col justify-start pr-1">
				<ElasticTextBox
					labelName={'Field1'}
					onChange={(data) => HANDLEFORMDATA('field1', data)}
				/>
				<ElasticTextBox
					labelName={'Field2'}
					onChange={(data) => HANDLEFORMDATA('field2', data)}
				/>
				<ElasticTextBox
					labelName={'Field3'}
					onChange={(data) => HANDLEFORMDATA('field3', data)}
				/>
				<ElasticTextBox
					labelName={'field4'}
					onChange={(data) => HANDLEFORMDATA('field4', data)}
				/>
			</div>
		</>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setShow(false)
	}

	useEffect(() => {
		setShowDrawer(show)
		setScrollable(show)
		setBody(FORM)
		document.body.classList.toggle('no-scroll', show)
	}, [show])

	useEffect(() => {
		setShowDrawer(show)
		setScrollable(show)
	}, [])

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">Additional Text</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Learn more
				</IconButton>
				<IconButton
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Get access
				</IconButton>
			</div>
		</>
	)
	return (
		<Drawer
			label="Additiona Info"
			size={size}
			body={body}
			drawerFooterBody={drawerFooterBody}
			scrollable={scrollable}
			onClose={handleDrawerClose}
			overlay={overlay}
			scrollableFooter={scrollableFooter}
			drawerPosition={drawerPosition}
			showDrawer={showDrawer}
		/>
	)
}
export default AdditionalInfoDrawer

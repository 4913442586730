import React from 'react'
const Button = ({ className, onClick, children, type, style, disabled }) => {
	return (
		<button
			className={className}
			type={type}
			onClick={onClick}
			style={style}
			disabled={disabled}
		>
			{children}
		</button>
	)
}
export default Button

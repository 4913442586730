import React from 'react'

const BarChart = ({ fillColor, width, height, ...rest }) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		viewBox="0 0 23 23"
		{...rest}
	>
		<path d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM4 5V19H20V5H4ZM7 13H9V17H7V13ZM11 7H13V17H11V7ZM15 10H17V17H15V10Z" />
	</svg>
)
export default BarChart

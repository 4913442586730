import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthLayout from '../../Auth-Layout'

import FormHeader from '../../components/Common/FormHeader'
import Formfooter from '../../components/Common/FormFooter'
import FormBody from '../../components/Common/FormBody'
import AlertWrapper from '../../components/ui/Alerts/AlertWrapper'
import TextBox from '../../components/ui/TextBox'
import Icon from '../../components/ui/icon'
import Button from '../../components/ui/Buttons/Button'
import Alert from '../../components/ui/Alerts'

import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'

const Register = () => {
	const navigate = useNavigate()
	const [notifications, setNotifications] = useState([])
	const [userData, setUserData] = useState({
		email: null,
		password: null,
		fullname: null
	})
	const addNotification = (type, message, icon) => {
		setNotifications((prevNotifications) => [
			{ id: new Date().getTime(), type, message, icon },
			...prevNotifications
		])
	}
	const HandleChange = (key, value) => {
		setUserData((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const HandleSubmit = (event) => {
		event.preventDefault()
		const PSW_REGEXP =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
		const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

		let isValidEmail = EMAIL_REGEXP.test(userData.email ?? '')
		let isValidPassword = PSW_REGEXP.test(userData.password ?? '')

		if (
			!userData.email ||
			!isValidEmail ||
			!userData.password ||
			!isValidPassword ||
			!userData.fullname
		) {
			if (!userData.email) {
				addNotification(
					'danger',
					'Please enter your email address.',
					faTriangleExclamation
				)
			} else if (!isValidEmail) {
				addNotification(
					'danger',
					'Please enter valid email address.',
					faTriangleExclamation
				)
			} else if (!userData.password) {
				addNotification(
					'danger',
					'Please enter your password.',
					faTriangleExclamation
				)
			} else if (!isValidPassword) {
				addNotification(
					'danger',
					'Please enter a valid password.',
					faTriangleExclamation
				)
			} else if (!userData.fullname) {
				addNotification(
					'danger',
					'Please enter your full name.',
					faTriangleExclamation
				)
			} else {
				addNotification(
					'danger',
					'Something went wrong. Please try again after sometime.',
					faTriangleExclamation
				)
			}
		} else {
			addNotification(
				'success',
				'Registration completed successfully. You can use your credentials to login.',
				faCheck
			)
			setTimeout(() => {
				navigate('/')
			}, 3000)
		}
	}
	return (
		<>
			<AlertWrapper>
				{notifications
					.sort((a, b) => b.id - a.id)
					.map((notification, index) => (
						<div key={notification.id}>
							<Alert
								autoClose={3000}
								id={index + 1}
								className={`alert-${notification.type}`}
								message={notification.message}
								Icon={notification.icon}
								onClose={() => {
									setNotifications((prevNotifications) =>
										prevNotifications.filter(
											(n) => n.id !== notification.id
										)
									)
								}}
							/>
						</div>
					))}
			</AlertWrapper>
			<AuthLayout>
				<FormHeader
					title="Sign Up"
					message="It's free to signup and only takes a minute."
				/>
				<FormBody>
					<TextBox
						onChange={(data) => HandleChange('email', data)}
						labelName={'Email Address'}
						type={'text'}
						className={'mb-4'}
					/>
					<TextBox
						onChange={(data) => HandleChange('password', data)}
						labelName={'Password'}
						type="password"
						className={'mb-4'}
					/>
					<TextBox
						onChange={(data) => HandleChange('fullname', data)}
						labelName={'Full Name'}
						type={'text'}
						className={'mb-4'}
					/>

					<div class="mb-4">
						<p className="small-text ">
							By clicking <strong>Create Account</strong> below,
							you agree to our Terms and Conditions and Privacy
							Policy.
						</p>
					</div>
					<Button
						className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
						isIconBtn={true}
						onClick={HandleSubmit}
					>
						<Icon
							name="line-signup"
							width={'16px'}
							height={'16px'}
							className={'icon-fill-white'}
							viewbox={'0 0 24 24'}
						/>{' '}
						Create Account
					</Button>
				</FormBody>
				<Formfooter
					message1="Already have an account"
					linktext1="Sign In"
					link1="login"
					message2="Need assistance or have questions"
					linktext2="Contact Support"
					link2="contact"
				/>
			</AuthLayout>
		</>
	)
}
export default Register

import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import TextBox from '../TextBox'
const TypeSignature = ({ onChange }) => {
	const [color, setColor] = useState('#444')
	const [text, setText] = useState(null)
	const [font, setFont] = useState('')
	const [show, setShow] = useState(false)
	const [value, setValue] = useState('')
	const [cropedImage, setCropedImage] = useState(null)
	const [canvasWidth, setCanvasWidth] = useState(null)
	const [canvasHeight, setCanvasHeight] = useState(null)
	const dynamicDivRef = useRef(null)
	const fonts = [
		{ name: 'Guest G', fontfamily: 'Lihataja Brush' },
		{ name: 'Guest G', fontfamily: 'Aerotis' },
		{ name: 'Guest G', fontfamily: 'Adinda Melia' },
		{ name: 'Guest G', fontfamily: 'Holligate Signature Demo' },
		{ name: 'Guest G', fontfamily: 'Bathilda' },
		{ name: 'Guest G', fontfamily: 'Allison Tessa' },
		{ name: 'Guest G', fontfamily: 'Honeymoon Avenue Script' },
		{ name: 'Guest G', fontfamily: 'Rhesmanisa' },
		{ fontfamily: 'Julietta Messie', name: 'Guest G' },
		{ fontfamily: 'Broetown Signature', name: 'Guest G' },
		{ fontfamily: 'Bimbo', name: 'Guest G' },
		{ fontfamily: 'Prestige Signature Script  Demo', name: 'Guest G' },
		{ fontfamily: 'Allison Script', name: 'Guest G' },
		{ name: 'Guest G', fontfamily: 'Jayadhira LILA EE 0.1' },
		{ name: 'Guest G', fontfamily: 'Beastform' }
	]
	// collect user input
	const HandleInputChange = (name) => {
		console.log(name)
		const newText = name
		setText(newText)
	}
	const ShowHandler = () => {
		setShow((prev) => !prev)
	}
	// select fonts
	const handleFonts = (selectedFont) => {
		setValue(selectedFont.name)
		setFont(selectedFont.fontfamily)
		ShowHandler()
	}
	// select colors for typing
	const FontColorHandler = (selectedColor) => {
		setColor(selectedColor)
	}
	//clear the canvas
	const clearHandler = () => {
		setText('')
		setCropedImage(null)
	}
	// crop the user typed signature and create url
	const HandleCrop = (txt) => {
		const divWidth = dynamicDivRef.current.offsetWidth
		console.log('Div width:', divWidth)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const canvasWidth = divWidth + 20
		const canvasHeight = 90
		canvas.width = canvasWidth
		setCanvasWidth(canvas.width)
		canvas.height = canvasHeight
		setCanvasHeight(canvas.height)
		ctx.clearRect(0, 0, canvas.width, canvas.height)
		ctx.globalAlpha = 1.0
		ctx.fillStyle = 'white'
		ctx.fillRect(0, 0, canvas.width, canvas.height)
		ctx.font = `48px '${font}'`
		ctx.fillStyle = color
		ctx.textBaseline = 'middle'
		const textMetrics = ctx.measureText(txt)
		const x = (canvas.width - textMetrics.width) / 2
		ctx.fillText(txt, x, canvas.height / 2)
		const dataUrl = canvas.toDataURL('image/png')
		if (text) {
			setCropedImage(dataUrl)
			onChange(dataUrl)
		}
	}
	const active = 'border-solid border-[1px] border-black'
	return (
		<>
			<div className="flex min-h-[329px] flex-col justify-between  gap-4 ">
				<div className="flex h-[250px] min-w-[150px] items-center justify-center bg-white">
					<div
						ref={dynamicDivRef}
						id="canvas-container"
						style={{
							fontFamily: `'${font}'`,
							fontSize: '48px',
							color: color
						}}
					>
						{text}
					</div>
				</div>
				<div className="flex w-full flex-wrap items-end gap-4 ">
					<div className="form-group mb-0 mt-0 ">
						<label className="label">Select Color</label>
						<div className="flex h-[32px] items-end justify-center">
							<div className="flex gap-[5px]">
								<div
									className={`flex h-6  w-6 items-center justify-center rounded-full ${color === '#444' ? active : ''}`}
								>
									<div
										className={`h-4 w-4 cursor-pointer rounded-full bg-black`}
										onClick={() => FontColorHandler('#444')}
									></div>
								</div>
								<div
									className={`flex h-6  w-6 items-center justify-center rounded-full ${color === '#0000FF' ? active : ''}`}
								>
									<div
										className={`h-4 w-4 cursor-pointer rounded-full  bg-blue-500`}
										onClick={() =>
											FontColorHandler('#0000FF')
										}
									></div>
								</div>
								<div
									className={`flex h-6  w-6 items-center justify-center rounded-full ${color === '#FF0000' ? active : ''}`}
								>
									<div
										className={`bg-red h-4 w-4 cursor-pointer rounded-full`}
										onClick={() =>
											FontColorHandler('#FF0000')
										}
									></div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex flex-1 gap-[3px]">
						<div className="relative flex gap-[3px]  ">
							<TextBox
								labelName={'Select Text'}
								className="mb-0 w-[140px] "
								type="text"
								placeholder="Select Font"
								onClick={ShowHandler}
								value={value}
								readOnly={true}
								style={{
									fontFamily: `'${font}'`,
									padding: '0px !important'
								}}
							/>
							<FontAwesomeIcon
								className="absolute right-1.5 top-[40px] cursor-pointer text-[#555]"
								icon={faAngleDown}
								onClick={ShowHandler}
							/>
							{show && (
								<div className="bg-dark absolute top-[63px] z-10 h-[200px] w-full overflow-auto rounded-b rounded-t-none bg-[#fff] shadow ">
									<ul>
										{fonts.map((font) => (
											<li
												className="px-[7.5px] py-[8px] text-[14px] hover:bg-[#e1dfdd]"
												style={{
													fontFamily: `'${font.fontfamily}'`
												}}
												onClick={() =>
													handleFonts(font)
												}
											>
												{font.name}
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
						<div>
							<TextBox
								labelName={'Enter Name'}
								className=" mb-0 w-[170px]  "
								type="text"
								placeholder="Enter Name"
								onChange={HandleInputChange}
								value={text}
							/>
						</div>
					</div>
					<div className="flex gap-[3px]">
						<button
							className="btn btn-primary btn-large "
							onClick={() => HandleCrop(text)}
						>
							Crop
						</button>
						<button
							className="btn btn-large btn-default  "
							onClick={clearHandler}
						>
							Clear
						</button>
					</div>
				</div>
			</div>
			{/* {cropedImage && <img src={cropedImage} alt='Cropped Signature' width={canvasWidth} height={canvasHeight} />} */}
		</>
	)
}

export default React.memo(TypeSignature)

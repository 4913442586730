import React, { useState } from 'react'
import Alert from '../../components/ui/Alerts'
import AlertWrapper from '../../components/ui/Alerts/AlertWrapper'
import Button from '../../components/ui/Buttons/Button'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

export default function NotificationEmoji() {
	const [notifications, setNotifications] = useState([])

	const onClose = (id) => {
		setNotifications((prevNotifications) => {
			return prevNotifications.filter(
				(notification) => notification.id !== id
			)
		})
	}

	const handleClick = (type) => {
		setNotifications((prevNotifications) => {
			const newNotification = {
				id: Date.now(),
				type
			}
			return [newNotification, ...prevNotifications]
		})
	}

	return (
		<>
			<div className="p-[30px]">
				<div className="mt-80">
					<AlertWrapper>
						{notifications.map((notification) => (
							<Alert
								key={notification.id}
								id={notification.id}
								className={`alert-${notification.type}`}
								Icon={faTriangleExclamation}
								message="This notification looks so perfect!"
								onClose={() => onClose(notification.id)}
							/>
						))}
					</AlertWrapper>

					<div className="flex flex-col gap-[3px]">
						<h2>Notifications With Emoji Only </h2>
						<div className="flex flex-row gap-[3px]">
							<Button
								onClick={() => handleClick('danger')}
								className="btn btn-large btn-danger"
								children="Danger Notification"
							/>
							<Button
								onClick={() => handleClick('warning')}
								className="btn btn-large btn-warning"
								children="Warning Notification"
							/>
							<Button
								onClick={() => handleClick('success')}
								className="btn btn-large btn-success"
								children="Success Notification"
							/>
							<Button
								onClick={() => handleClick('info')}
								className="btn btn-large btn-complete"
								children="Info Notification"
							/>
							<Button
								onClick={() => handleClick('default')}
								className="btn btn-large"
								children="Default Notification"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

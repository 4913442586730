const rangeData = [
	{
		Id: 1,
		category: 'Ineffective',
		label: [
			{
				labelId: 1,
				text: 'No action to address control weaknesses or absence',
				value: 0,
				response: 'text',
				question: 'text',
				report: 'text',
				dependency: [
					{
						operator: null,
						dependencyValue: null
					}
				]
			},
			{
				labelId: 2,
				text: 'Controls are implemented in an ad-hoc manner',
				value: 0,
				response: 'text',
				question: 'text',
				report: 'text',
				dependency: [
					{
						operator: null,
						dependencyValue: null
					}
				]
			}
		]
	},
	{
		Id: 2,
		category: 'Partially Effective',
		label: [
			{
				labelId: 3,
				text: 'Controls are documented, including policies and procedures',
				value: 3,
				response: 'text',
				question: 'text',
				report: 'text',
				dependency: [
					{
						operator: null,
						dependencyValue: null
					}
				]
			},
			{
				labelId: 4,
				text: 'Controls are implemented consistently and have good coverage',
				value: 1,
				response: 'text',
				question: 'text',
				report: 'text',
				dependency: [
					{
						operator: null,
						dependencyValue: null
					}
				]
			},
			{
				labelId: 5,
				text: 'Roles and Responsibilities are clearly communicated and Training is provided',
				value: 1,
				response: 'text',
				question: 'text',
				report: 'text',
				dependency: [
					{
						operator: null,
						dependencyValue: null
					}
				]
			}
		]
	}
	// {
	//     "Id": 3,
	//     "category": "Effective",
	//     "label": [
	//         {
	//             "labelId": 6,
	//             "text": "Controls are regularly tested for effectiveness",
	//             "value": 1,
	//             "response": "text",
	//             "question": "text",
	//             "report": "text",
	//             "dependency": [
	//                 {
	//                     "operator": null,
	//                     "dependencyValue": null
	//                 }
	//             ]
	//         },
	//         {
	//             "labelId": 7,
	//             "text": "Controls are actively monitored",
	//             "value": 1,
	//             "response": "text",
	//             "question": "text",
	//             "report": "text",
	//             "dependency": [
	//                 {
	//                     "operator": null,
	//                     "dependencyValue": null
	//                 }
	//             ]
	//         },
	//         {
	//             "labelId": 8,
	//             "text": "Control performance is measured and regularly reported",
	//             "value": 1,
	//             "response": "text",
	//             "question": "text",
	//             "report": "text",
	//             "dependency": [
	//                 {
	//                     "operator": null,
	//                     "dependencyValue": null
	//                 }
	//             ]
	//         },
	//         {
	//             "labelId": 9,
	//             "text": "Continuous improvement efforts are in place",
	//             "value": 1,
	//             "response": "text",
	//             "question": "text",
	//             "report": "text",
	//             "dependency": [
	//                 {
	//                     "operator": null,
	//                     "dependencyValue": null
	//                 }
	//             ]
	//         }
	//     ]
	// },
	// {
	//     "Id": 4,
	//     "category": "Optimized",
	//     "label": [
	//         {
	//             "labelId": 10,
	//             "text": "Controls are highly automated",
	//             "value": 1,
	//             "response": "text",
	//             "question": "text",
	//             "report": "text",
	//             "dependency": [
	//                 {
	//                     "operator": null,
	//                     "dependencyValue": null
	//                 }
	//             ]
	//         },
	//         {
	//             "labelId": 11,
	//             "text": "Strong governance structure is in place",
	//             "value": 1,
	//             "response": "text",
	//             "question": "text",
	//             "report": "text",
	//             "dependency": [
	//                 {
	//                     "operator": null,
	//                     "dependencyValue": null
	//                 }
	//             ]
	//         }
	//     ]
	// }
]

export default rangeData

import React from 'react'
import IconButton from '../Buttons/Icon-button'
import { faClose } from '@fortawesome/free-solid-svg-icons'
export default function DrawerHeader(props) {
	return (
		<>
			<div className="drawer-header z-50   flex items-center justify-between border-b">
				<h5 id="drawer-label" className="inline-flex items-center ">
					{props.label ? props.label : 'Info'}
				</h5>
				<IconButton
					type="button"
					className="relative top-[3px] flex h-5 w-6 items-center justify-center rounded-sm bg-transparent text-sm text-gray-400 hover:bg-transparent hover:text-gray-500"
					onClick={() => props.onClose()}
					children=""
					Icon={faClose}
					size={'lg'}
				/>
			</div>
		</>
	)
}

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const List = ({
	headingClass = 'h5',
	subheadingClass,
	wrapperstyle,
	items,
	listType = 'ul',
	title,
	description
}) => {
	const ListTag = listType === 'ol' ? 'ol' : 'ul'
	let listClass = 'ml-[18px]' // Base margin class
	if (listType === 'ol') {
		listClass += ' list-decimal'
	} else if (listType === 'ul') {
		listClass += ' list-disc'
	} else {
		listClass += ' ml-0 list-none'
	}

	const renderListItem = (item, index) => {
		let listItemContent

		switch (item.type) {
			case 'tick':
				listItemContent = (
					<span className="relative ml-[5px] flex items-center">
						<span className="h-[12px] w-[7px] rotate-45 border-2 border-l-0 border-t-0 border-solid border-green-700"></span>
						<span className="ml-[8px]">{item.text}</span>
					</span>
				)
				break
			case 'cross':
				listItemContent = (
					<span className="relative flex items-center">
						<span className="circle-cross-checkmark !left-[6.5px]"></span>
						<span className="ml-[18px]">{item.text}</span>
					</span>
				)
				break
			case 'icon':
				listItemContent = (
					<span className="relative flex items-center">
						<FontAwesomeIcon icon={item.icon} />
						<span className="ml-[8px]">{item.text}</span>
					</span>
				)
				break
			default:
				listItemContent = <span>{item.text}</span>
		}

		return (
			<li key={index} className={listClass}>
				{listItemContent}
			</li>
		)
	}

	return (
		<div className={`${wrapperstyle} list-wrapper`}>
			{title && <h5 className={description && 'mb-0'}>{title}</h5>}
			{description && (
				<p className={`${subheadingClass}`}>{description}</p>
			)}
			<ListTag>{items.map(renderListItem)}</ListTag>
		</div>
	)
}

export default List

import React from 'react'

const SidebarHeader = ({ link = '' }) => {
	return (
		<div className=" flex h-[72px] items-center border-b border-b-[#f3f5f9]  px-5 ">
			<a href={link} className="sidebar-logo !text-[24px]">
				Risk Wise
			</a>
		</div>
	)
}
export default SidebarHeader

import React from 'react'

const SidebarFooter = () => {
	return (
		<div className=" ">
			<div className="flex h-[64px] max-w-[300px] items-center border-t pl-[15px] pr-3  ">
				<div className="relative flex-1">
					<h6 className="pl-[18px] text-sm font-semibold text-[#212830] before:absolute before:left-0 before:top-1  before:m-auto before:h-[10px] before:w-[10px] before:rounded-md before:bg-[#0cb785]">
						Online
					</h6>
					<p className="mb-0 pt-[2px] !text-xs text-[#6e7985]">
						Support expires in 61 days.
					</p>
				</div>
			</div>
		</div>
	)
}

export default SidebarFooter

export default function DrawerFooter(props) {
	return (
		<>
			<div className="drawer-footer absolute bottom-0  z-50 flex min-h-[46px] w-full flex-wrap items-center justify-between gap-2 border-t border-t-[#e5e7eb]">
				{props.drawerFooterBody}
			</div>
			{/* {!props.scrollableFooter ? (
          <div className="z-50 drawer-footer flex justify-between py-2 items-center border-t border-t-[#e5e7eb] gap-2 absolute bottom-0 w-full flex-wrap bg-white">
            {props.drawerFooterBody}
          </div>
        ) : (
          <div className="drawer-footer flex justify-between py-2 items-center border-t border-t-[#e5e7eb] gap-2 absolute bottom-0 w-full flex-wrap z-50">
            {props.drawerFooterBody}
          </div>
        )} */}
		</>
	)
}

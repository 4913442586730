import React from 'react'
import Verified from '../../assets/img/check.png'
const PersonalInfoForm = () => {
	return (
		<div>
			<div className="bg-[#f7f7f7] px-[15px] py-[10px]">
				<div className="multipleinputs grid grid-cols-2  md:grid-cols-4 ">
					<div className="col-span-4 sm:col-span-1 md:col-span-1 md:w-3/4 lg:w-full">
						<div className="form-group">
							<label className="label">Title</label>
							<div className="text-display">
								<p>Mr. </p>
							</div>
						</div>
					</div>

					<div className=" col-span-4 sm:col-span-3 md:col-span-3 md:w-1/2 lg:w-full">
						<div className="form-group">
							<label className="label">Full Name</label>
							<div className="text-display">
								<p>John Fernandis</p>
							</div>
						</div>
					</div>
					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Date of Birth</label>
							<div className="text-display">
								<p>25 June 1980</p>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Age</label>
							<div className="text-display">
								<p>42 Year(s)</p>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Status</label>
							<div className="text-display">
								<p>Married</p>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Gender</label>
							<div className="text-display">
								<p>Male</p>
							</div>
						</div>
					</div>
					<div className="col-span-4 sm:col-span-2  md:col-span-2 lg:w-full">
						<div className="form-group">
							<label className="label">Personal eMail ID</label>
							<div className="text-display">
								<p>johnfernandis@gmail.com</p>
								<div className="verfied">
									<img src={Verified} alt="" />
									<span>Verified</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-4 sm:col-span-2  md:col-span-2 lg:w-full">
						<div className="form-group">
							<label className="label">
								Personal Mobile Number
							</label>
							<div className="text-display">
								<p>+91 9999848369</p>
								<div className="verfied">
									<img src={Verified} alt="" />
									<span>Verified</span>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Citizenship</label>
							<div className="text-display">
								<p>United Arab Emirates</p>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Residency</label>
							<div className="text-display">
								<p>United Arab Emirates</p>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Dual Citizenship</label>
							<div className="text-display">
								<p>Yes</p>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Second Citizenship</label>
							<div className="text-display">
								<p>India</p>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">
								Identification Document
							</label>
							<div className="text-display">
								<p>Passport</p>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-2   md:col-span-2 lg:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">ID Number</label>
							<div className="text-display">
								<p>+91 9999848369</p>
								<div className="verfied">
									<img src={Verified} alt="" />
									<span>Verified</span>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Expiry Date</label>
							<div className="text-display">
								<p>10 June 2033</p>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Issue Location</label>
							<div className="text-display">
								<p>India</p>
							</div>
						</div>
					</div>
					<div className="col-span-4 sm:col-span-2 md:col-span-2 lg:w-full">
						<div className="form-group">
							<label className="label">Home Address</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>H, No-6, Panchsheel Shopping Center</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-2 md:col-span-2 lg:w-full">
						<div className="form-group">
							<label className="label">Permenant Address</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>H, No-6, Panchsheel Shopping Center</p>
								</div>
							</div>
						</div>
					</div>

					<div className=" col-span-4 sm:col-span-2 md:col-span-1  lg:w-full">
						<div className="form-group">
							<label className="label">City</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-4 sm:col-span-2 md:col-span-1  lg:w-full">
						<div className="form-group">
							<label className="label">Pin Code</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-2 md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">City</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-4 sm:col-span-2 md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Pin Code</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-2 md:col-span-1  lg:w-full">
						<div className="form-group">
							<label className="label">State</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-4 sm:col-span-2 md:col-span-1  lg:w-full">
						<div className="form-group">
							<label className="label">Country</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>India</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-2 md:col-span-1  lg:w-full">
						<div className="form-group">
							<label className="label">State</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Delhi</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-4 sm:col-span-2 md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Country</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>India</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-span-4 sm:col-span-2 md:col-span-1 ">
						<div className="form-group">
							<label className="label">
								Emergency Contact Name
							</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Male</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-4 sm:col-span-2 md:col-span-1 ">
						<div className="form-group">
							<label className="label">Relationship</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Single</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-4 sm:col-span-2 md:col-span-1 ">
						<div className="form-group">
							<label className="label">
								Emergency Contact Number
							</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>Indian</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-4 sm:col-span-2 md:col-span-1 ">
						<div className="form-group">
							<label className="label">
								Emergency Contact Email
							</label>
							<div className="text-display">
								<div className="text-display-budge">
									<p>01-02-1987</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PersonalInfoForm

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconButton = ({ className, Icon, children, onClick, size }) => {
	return (
		<button className={className} onClick={onClick}>
			<FontAwesomeIcon icon={Icon} size={size} />
			{children}
		</button>
	)
}

export default IconButton

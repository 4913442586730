import React, { useState, useRef, useEffect } from 'react'
import ProfileSection from './ProfileSection'
import image from '../../assets/img/dumyprofilepic.jpeg'
import Avatar from '../ui/Avatar'
const Profile = () => {
	const [showProfile, setShowProfile] = useState(false)
	const closeref = useRef()
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (
				showProfile &&
				closeref.current &&
				!closeref.current.contains(event.target)
			) {
				setShowProfile(false)
			}
		}
		document.addEventListener('mousedown', handleOutsideClick)
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [showProfile])
	return (
		<>
			<div
				className="dropdown dropdown-profile ml-6 cursor-pointer"
				ref={closeref}
			>
				<div
					className="avatar online"
					onClick={() => setShowProfile((prev) => !prev)}
				>
					<Avatar
						className={`h-full w-full rounded-full`}
						image={image}
					/>
				</div>
				{showProfile && (
					<div className="dropdown-menu dropdown-menu-end mt-10-f absolute right-5 top-20 bg-[#fff]">
						<div className="dropdown-menu-body">
							<div className="avatar avatar-xl online mb-3">
								<Avatar
									className={`h-full w-full rounded-full`}
									image={image}
								/>
							</div>
							<h5 className="mb-1">Shaira Diaz</h5>
							<p className="fs-sm small-text">
								Vice President Enterprise Risk and Assurance
							</p>
							<ProfileSection />
						</div>
					</div>
				)}
			</div>
		</>
	)
}
export default Profile

import React, { useState } from 'react'
import Radio from '../Radio'
const RadioGroup = ({
	type = 'vertical',
	options,
	name,
	onChange,
	className,
	iconClass
}) => {
	const [selectedOption, setSelectedOption] = useState(null)

	const handleChange = (value) => {
		setSelectedOption(value)
		onChange(value)
	}

	return (
		<div
			className={`relative flex list-none flex-wrap gap-[8px] ${type == 'horizontal' ? '' : 'flex-col'}`}
		>
			{options.map((option) => (
				<Radio
					key={option.value}
					id={option.value}
					name={name}
					label={option.label}
					checked={selectedOption === option.value}
					className={className}
					iconClass={iconClass}
					onChange={() => handleChange(option.value)}
					disabled={option.disabled}
				/>
			))}
		</div>
	)
}
export default RadioGroup

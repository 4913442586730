import React from 'react'
import MENU_DATA from './MENU_DATA'
import Layout from '../../../Dashboard-Layout'
import FontPage from '../FontPage'
import ButtonPage from '../ButtonPage'
import ColorPage from '../ColorPage'
import SvgIconPage from '../SvgIconPage'
import CheckmarkPage from '../CheckmarkPage'
import BadgePage from '../BadgePage'
import NotificationTimer from '../NotificationTimer'
import NotificationEmoji from '../NotificationsEmoji'
import NotificationSimple from '../NotificationsSimple'
import FormPage from '../Form'
import DataTableDemo from '../../DataTableDemo'
import Modals from '../../../../src/components/ui/Modals'
import Drawers from '../../../../src/components/ui/Drawers'
import ViewProfile from '../../viewprofile'
import UserList from '../../UserList'
import { Route, Routes } from 'react-router-dom'
import Pages from './Pages'
import Accordion from '../../../components/ui/Accordion/Accordion'
const Menus = () => {
	const HandleLink = (name) => {
		// console.log(name);
	}
	let userEmail = localStorage.getItem('email')
	return (
		<Layout
			onClick={HandleLink}
			menudata={MENU_DATA}
			headerTitle={`Welcome!  ${userEmail}`}
			headerDescription="The dashboard below provides a comprehensive and personalized view, tailored to your needs. It offers a wealth of insights and information that empowers you to make informed and effective decisions. Unlock the power of data-driven decision-making and seize new opportunities with confidence."
			pageName="Welcome Screen"
		>
			<Routes>
				<Route path="/fonts" element={<FontPage />} />
				<Route path="/colors" element={<ColorPage />} />
				<Route path="/buttons" element={<ButtonPage />} />
				<Route path="/svgs" element={<SvgIconPage />} />
				<Route path="/checkmarks" element={<CheckmarkPage />} />
				<Route path="/badges" element={<BadgePage />} />
				<Route path="/fonts" element={<FontPage />} />
				<Route
					path="/notification-simple"
					element={<NotificationSimple />}
				/>
				<Route
					path="/notification-timer"
					element={<NotificationTimer />}
				/>
				<Route
					path="/notification-emoji"
					element={<NotificationEmoji />}
				/>
				<Route path="/form" element={<FormPage />} />
				<Route path="/data-tables" element={<DataTableDemo />} />
				<Route path="/drawers" element={<Drawers />} />
				<Route path="/modals" element={<Modals />} />
				<Route path="/view-profile" element={<ViewProfile />} />
				<Route path="/pages" element={<Pages />} />
				<Route path="/userlist" element={<UserList />} />
			</Routes>
		</Layout>
	)
}
export default Menus

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faCheck,
	faPencilAlt,
	faFillDrip,
	faHeart
} from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
import ToggleButtons from '../../components/ui/ButtonComponent'
import Button from '../../components/ui/Buttons/Button'
import Icon from '../../components/ui/icon'
const ButtonPage = () => {
	return (
		<div className="p-[30px]">
			<div className="mt-5">
				<p className="small-text">UI ELEMENT</p>
				<h3>BUTTON COMPONENT</h3>
				<hr />

				{/* Default Buttons */}
				<div className="mt-5 grid grid-cols-3 gap-[3px] md:grid-cols-7 lg:grid-cols-7">
					<div className="small-text">COLOR BUTTON</div>
				</div>
				<div className="mt-3 grid grid-cols-3 gap-[3px] md:grid-cols-7 lg:grid-cols-7">
					<div className="col-span-1 flex flex-col">
						<p>Primary Button</p>
						<Button
							type="button"
							className="btn-primary btn-xlarge"
						>
							Primary
						</Button>
						<span className="small-text">
							Refer to class .btn-primary to get "Primary" button
							styles
						</span>
					</div>
					<div className="col-span-1 flex flex-col">
						<p>Success Button</p>
						<Button
							type="button"
							className="btn-success btn-xlarge"
						>
							Success
						</Button>
						<span className="small-text">
							Refer to class .btn-success to get "Success" button
							styles
						</span>
					</div>
					<div className="col-span-1 flex flex-col">
						<p>Edit Button</p>
						<Button type="button" className="btn-edit btn-xlarge">
							Edit
						</Button>
						<span className="small-text">
							Refer to class .btn-edit to get "Edit" button styles
						</span>
					</div>
					<div className="col-span-1 flex flex-col">
						<p>Danger Button</p>
						<Button type="button" className="btn-danger btn-xlarge">
							Danger
						</Button>
						<span className="small-text">
							Refer to class .btn-danger to get "Danger" button
							styles
						</span>
					</div>
					<div className="col-span-1 flex flex-col">
						<p>Complete Button</p>
						<Button
							type="button"
							className="btn-complete btn-xlarge"
						>
							Complete
						</Button>
						<span className="small-text">
							Refer to class .btn-complete to get "Complete"
							button styles
						</span>
					</div>
					<div className="col-span-1 flex flex-col">
						<p>Warning Button</p>
						<Button
							type="button"
							className="btn-warning btn-xlarge"
						>
							Warning
						</Button>
						<span className="small-text">
							Refer to class .btn-warning to get "Warning" button
							styles
						</span>
					</div>
					<div className="col-span-1 flex flex-col">
						<p>Default Button</p>
						<Button type="button" className="btn-xlarge">
							Default
						</Button>
						<span className="small-text">
							"Default" button styles without any class
						</span>
					</div>
				</div>
			</div>

			<div className="mt-5 grid grid-cols-1 gap-[3px] md:grid-cols-3 lg:grid-cols-3">
				<div className="col-span-1">
					{/* Size Buttons */}
					<div className="mt-5">
						<div className="small-text">SIZE OPTIONS</div>
					</div>
					<div className="mt-3 flex items-start justify-start gap-[3px]">
						<Button type="button" className="btn-xlarge">
							Extra Large
						</Button>

						<Button type="button" className="btn-large">
							Large
						</Button>

						<Button type="button" className="btn-default">
							Default
						</Button>

						<Button type="button" className="btn-small">
							Small
						</Button>

						<Button type="button" className="btn-tiny">
							Tiny
						</Button>
					</div>
					<div className="small-text">
						Add .btn-xlarge .btn-large .btn-default .btn-small for
						additional sizes.
					</div>
					<div className="mt-2 flex items-start justify-start gap-[3px]">
						<Button
							type="button"
							className="btn-primary btn-xlarge"
						>
							Extra Large
						</Button>

						<Button type="button" className="btn-primary btn-large">
							Large
						</Button>

						<Button
							type="button"
							className="btn-primary btn-default"
						>
							Default
						</Button>

						<Button type="button" className="btn-primary btn-small">
							Small
						</Button>

						<Button type="button" className="btn-primary btn-tiny">
							Tiny
						</Button>
					</div>
					<div className="mt-2 flex items-start justify-start gap-[3px]">
						<Button
							type="button"
							className="btn-complete btn-xlarge"
						>
							Extra Large
						</Button>

						<Button
							type="button"
							className="btn-complete btn-large"
						>
							Large
						</Button>

						<Button
							type="button"
							className="btn-complete btn-default"
						>
							Default
						</Button>

						<Button
							type="button"
							className="btn-complete btn-small"
						>
							Small
						</Button>

						<Button type="button" className="btn-complete btn-tiny">
							Tiny
						</Button>
					</div>
					<div className="mt-2 flex items-start justify-start gap-[3px]">
						<Button
							type="button"
							className="btn-warning btn-xlarge"
						>
							Extra Large
						</Button>

						<Button type="button" className="btn-warning btn-large">
							Large
						</Button>

						<Button
							type="button"
							className="btn-warning btn-default"
						>
							Default
						</Button>

						<Button type="button" className="btn-warning btn-small">
							Small
						</Button>

						<Button type="button" className="btn-warning btn-tiny">
							Tiny
						</Button>
					</div>
					<div className="mt-2 flex items-start justify-start gap-[3px]">
						<Button
							type="button"
							className="btn-success btn-xlarge"
						>
							Extra Large
						</Button>

						<Button type="button" className="btn-success btn-large">
							Large
						</Button>

						<Button
							type="button"
							className="btn-success btn-default"
						>
							Default
						</Button>

						<Button type="button" className="btn-success btn-small">
							Small
						</Button>

						<Button type="button" className="btn-success btn-tiny">
							Tiny
						</Button>
					</div>
					<div className="mt-2 flex items-start justify-start gap-[3px]">
						<Button type="button" className="btn-edit btn-xlarge">
							Extra Large
						</Button>

						<Button type="button" className="btn-edit btn-large">
							Large
						</Button>

						<Button type="button" className="btn-edit btn-default">
							Default
						</Button>

						<Button type="button" className="btn-edit btn-small">
							Small
						</Button>

						<Button type="button" className="btn-edit btn-tiny">
							Tiny
						</Button>
					</div>
					<div className="mt-2 flex items-start justify-start gap-[3px]">
						<Button type="button" className="btn-danger btn-xlarge">
							Extra Large
						</Button>

						<Button type="button" className="btn-danger btn-large">
							Large
						</Button>

						<Button
							type="button"
							className="btn-danger btn-default"
						>
							Default
						</Button>

						<Button type="button" className="btn-danger btn-small">
							Small
						</Button>

						<Button type="button" className="btn-danger btn-tiny">
							Tiny
						</Button>
					</div>
					<div className="mt-2 flex items-start justify-start gap-[3px]">
						<Button type="button" className=" btn-xlarge">
							Extra Large
						</Button>

						<Button type="button" className=" btn-large">
							Large
						</Button>

						<Button type="button" className="btn-default">
							Default
						</Button>

						<Button type="button" className=" btn-small">
							Small
						</Button>

						<Button type="button" className=" btn-tiny">
							Tiny
						</Button>
					</div>
				</div>

				<div className="col-span-1">
					{/* Icon Buttons */}
					<div className="mt-5">
						<div className="small-text">ICON BUTTONS</div>
					</div>
					<div className="mt-3 flex items-start justify-start gap-[3px]">
						<Button
							type="button"
							className="btn-default btn-primary"
						>
							<FontAwesomeIcon
								icon={faCheck}
								className="btn-icon"
							/>
							Submit
						</Button>

						<Button
							type="button"
							className="btn-default btn-success"
						>
							<FontAwesomeIcon
								icon={faFillDrip}
								className="btn-icon"
							/>
							Style
						</Button>

						<Button type="button" className="btn-default btn-edit">
							<FontAwesomeIcon
								icon={faPencilAlt}
								className="btn-icon"
							/>
							Edit
						</Button>

						<Button
							type="button"
							className="btn-default btn-danger"
						>
							<Icon
								name="line-trash"
								className="mr-[5px] fill-white"
								width="12px"
								height="12px"
							/>
							Delete
						</Button>

						<Button
							type="button"
							className="btn-default btn-default"
						>
							<FontAwesomeIcon
								icon={faHeart}
								className="btn-icon"
							/>
							Like
						</Button>
					</div>
					<div className="mt-1 flex items-start justify-start gap-[3px]">
						<Button
							type="button"
							className="btn-xlarge btn-primary"
						>
							<FontAwesomeIcon
								icon={faCheck}
								className="btn-icon"
							/>
							Submit
						</Button>

						<Button
							type="button"
							className="btn-xlarge btn-success"
						>
							<FontAwesomeIcon
								icon={faFillDrip}
								className="btn-icon"
							/>
							Style
						</Button>

						<Button type="button" className="btn-xlarge btn-edit">
							<FontAwesomeIcon
								icon={faPencilAlt}
								className="btn-icon"
							/>
							Edit
						</Button>

						<Button type="button" className="btn-xlarge btn-danger">
							<Icon
								name="line-trash"
								className="mr-[5px] fill-white"
								width="14px"
								height="14px"
							/>
							Delete
						</Button>

						<Button
							type="button"
							className="btn-xlarge btn-default"
						>
							<FontAwesomeIcon
								icon={faHeart}
								className="btn-icon"
							/>
							Like
						</Button>
					</div>

					{/* Social Buttons */}
					<div className="mt-5">
						<div className="small-text">SOCIAL BUTTONS</div>
					</div>
					<div className="mt-3 flex flex-col items-start justify-start gap-[3px]">
						<Button
							type="button"
							className="btn-xlarge btn-edit btn-social"
						>
							<FontAwesomeIcon
								className="social-icons"
								icon={faFacebookF}
							/>
							Login with Facebook
						</Button>

						<Button
							type="button"
							className="btn-xlarge btn-primary btn-social"
						>
							<FontAwesomeIcon
								className="social-icons"
								icon={faTwitter}
							/>
							Login with Twitter
						</Button>

						<Button
							type="button"
							className="btn-xlarge btn-danger btn-social"
						>
							<Icon
								name="social-google"
								className="social-icons mr-[5px] fill-white"
								width="14px"
								height="14px"
							/>
							Login with Google+
						</Button>
					</div>
				</div>

				<div className="col-span-1">
					{/* Toggle Buttons */}
					<div className="mt-5">
						<div className="small-text">TOGGLE BUTTONS</div>
					</div>
					<div className="mt-3 flex flex-col items-start justify-start gap-[3px]">
						<ToggleButtons
							classFirst={
								'  rounded-tr-none rounded-br-none btn-large active:border '
							}
							classSecond={
								'-ml-[1px]  btn-large !rounded-none active:border-solid active:border-[#c7c6c6]'
							}
							classThird={
								' btn-large rounded-tl-none rounded-bl-none border-l-0 active:border-solid active:border-[#c7c6c6] active:border active:border-l-0'
							}
							child1={'Like'}
							child2={'Share'}
							child3={'Comment'}
						/>
						<ToggleButtons
							classFirst={
								'  btn-xlarge rounded-tr-none rounded-br-none  active:border '
							}
							classSecond={
								' btn-xlarge -ml-[1px]   !rounded-none active:border-solid active:border-[#c7c6c6]'
							}
							classThird={
								'btn-xlarge rounded-tl-none rounded-bl-none border-l-0 active:border-solid active:border-[#c7c6c6] active:border active:border-l-0'
							}
							child1={'Like'}
							child2={'Share'}
							child3={'Comment'}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ButtonPage

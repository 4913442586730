import React, { useState, useRef } from 'react'
import CanvasDraw from 'react-canvas-draw'
const DrawSignature = ({ onChange }) => {
	const [color, setColor] = useState('#444')
	const [canvasData, setCanvasData] = useState(null)
	const [drawingstate, setdrawingState] = useState(null)
	const canvasRef = useRef(null)

	const handleOnChange = () => {
		setdrawingState('drawing')
	}
	//changes brush color
	const brushColor = (newColor) => {
		setColor(newColor)
	}
	//clear drawen signature
	const clearCanvas = () => {
		setCanvasData(null)
		setdrawingState(null)
		canvasRef.current.clear()
	}
	// create the url of selected image when user clicks crop buttton
	const handleCrop = () => {
		const canvas = canvasRef.current.canvasContainer.children[1]
		const ctx = canvas.getContext('2d')
		const boundingBox = getNonTransparentBoundingBox(canvas, ctx)
		const tempCanvas = document.createElement('canvas')
		tempCanvas.width = boundingBox.width
		tempCanvas.height = boundingBox.height
		const tempCtx = tempCanvas.getContext('2d')
		tempCtx.drawImage(
			canvas,
			boundingBox.minX,
			boundingBox.minY,
			boundingBox.width,
			boundingBox.height,
			0,
			0,
			boundingBox.width,
			boundingBox.height
		)
		const canvasDataUrl = tempCanvas.toDataURL()
		if (drawingstate) {
			setCanvasData(canvasDataUrl)
			onChange(canvasDataUrl)
		}
	}
	// calculatre the width and height of drawn portion
	const getNonTransparentBoundingBox = (canvas, ctx) => {
		const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height).data
		let minX = canvas.width
		let minY = canvas.height
		let maxX = -1
		let maxY = -1
		for (let y = 0; y < canvas.height; y++) {
			for (let x = 0; x < canvas.width; x++) {
				const alpha = pixels[(y * canvas.width + x) * 4 + 3]
				if (alpha > 0) {
					minX = Math.min(minX, x)
					minY = Math.min(minY, y)
					maxX = Math.max(maxX, x)
					maxY = Math.max(maxY, y)
				}
			}
		}
		const width = maxX - minX + 1
		const height = maxY - minY + 1
		return { minX, minY, width, height }
	}

	return (
		<>
			<div className="flex min-h-[329px] flex-col justify-between  gap-4">
				<div className="width-full ">
					<CanvasDraw
						hideInterface
						canvasHeight={250}
						style={{ width: '100%' }}
						hideGrid
						brushRadius={1}
						brushColor={color}
						ref={canvasRef}
						onChange={handleOnChange}
					/>
				</div>
				<div className="flex flex-col gap-3 ">
					<div className=" form-group mb-0">
						<p className="!mb-[9px]  !text-[14px] font-semibold text-[#323130] ">
							Select Color
						</p>
						<div className="flex items-end justify-between">
							<div className="flex gap-[5px]">
								<div
									className={`flex h-6 w-6 items-center justify-center rounded-full ${
										color === '#444'
											? 'border-[1px] border-solid border-black'
											: ''
									}`}
								>
									<div
										className="h-4 w-4 cursor-pointer rounded-full bg-black"
										onClick={() => brushColor('#444')}
									></div>
								</div>
								<div
									className={`flex h-6 w-6 items-center justify-center rounded-full ${
										color === '#0000FF'
											? 'border-[1px] border-solid border-black'
											: ''
									}`}
								>
									<div
										className="h-4 w-4 cursor-pointer rounded-full bg-blue-500"
										onClick={() => brushColor('#0000FF')}
									></div>
								</div>
								<div
									className={`flex h-6 w-6 items-center justify-center rounded-full ${
										color === '#FF0000'
											? 'border-[1px] border-solid border-black'
											: ''
									}`}
								>
									<div
										className="bg-red h-4 w-4 cursor-pointer rounded-full"
										onClick={() => brushColor('#FF0000')}
									></div>
								</div>
							</div>
							<div className="flex gap-[3px]">
								<button
									className="btn-primary btn-large "
									onClick={handleCrop}
								>
									Crop
								</button>
								<button
									className="btn-large btn-default "
									onClick={clearCanvas}
								>
									Clear
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* {canvasData && (
        <img
          src={canvasData}
          alt="CanvasImage"
          style={{ maxHeight: "100px",maxWidth:'200px' }}
        />
      )} */}
		</>
	)
}

export default React.memo(DrawSignature)

import React from 'react'
const ColorPage = () => {
	return (
		<div className="p-[30px]">
			<div className="mt-5">
				<p className="small-text">TINTS AND SHADES</p>
				<h3>COLOR PALATTE</h3>
				<hr />

				<div className="mt-5 grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3">
					{/* Primary Color */}
					<div className="col-span-1">
						<div className="overflow-hidden shadow-lg">
							<div className="card-body">
								<div className="bg-primary300 p-[15px]">
									<div className="float-left">
										<p className="mb-0 text-white">
											Primary Color - Pages Purple
										</p>
										<p className="mb-0 text-white">
											color-primary
										</p>
										<p className="mb-0 text-white">
											.bg-primary300
										</p>
									</div>
									<div className="float-right text-white">
										0
									</div>
									<div className="clear-both" />
								</div>

								<div className="flex">
									<div className="bg-primary100 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px]">
											-2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px]">
											.bg-primary100
										</span>
									</div>
									<div className="bg-primary200 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											-1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-primary200
										</span>
									</div>
									<div className="bg-primary400 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-primary400
										</span>
									</div>
									<div className="bg-primary500 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-primary500
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Complete Color */}
					<div className="col-span-1">
						<div className="overflow-hidden shadow-lg">
							<div className="card-body">
								<div className="bg-complete300 p-[15px]">
									<div className="float-left">
										<p className="mb-0 text-white">
											Complete Color - Pages Blue
										</p>
										<p className="mb-0 text-white">
											color-complete
										</p>
										<p className="mb-0 text-white">
											.bg-complete300
										</p>
									</div>
									<div className="float-right text-white">
										0
									</div>
									<div className="clear-both" />
								</div>

								<div className="flex">
									<div className="bg-complete100 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px]">
											-2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px]">
											.bg-complete100
										</span>
									</div>
									<div className="bg-complete200 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											-1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-complete200
										</span>
									</div>
									<div className="bg-complete400 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-complete400
										</span>
									</div>
									<div className="bg-complete500 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-complete500
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Success Color */}
					<div className="col-span-1">
						<div className="overflow-hidden shadow-lg">
							<div className="card-body">
								<div className="bg-success300 p-[15px]">
									<div className="float-left">
										<p className="mb-0 text-white">
											Success Color - Pages Green
										</p>
										<p className="mb-0 text-white">
											color-success
										</p>
										<p className="mb-0 text-white">
											.bg-success300
										</p>
									</div>
									<div className="float-right text-white">
										0
									</div>
									<div className="clear-both" />
								</div>

								<div className="flex">
									<div className="bg-success100 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px]">
											-2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px]">
											.bg-success100
										</span>
									</div>
									<div className="bg-success200 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											-1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-success200
										</span>
									</div>
									<div className="bg-success400 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-success400
										</span>
									</div>
									<div className="bg-success500 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-success500
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Warning Color */}
					<div className="col-span-1">
						<div className="overflow-hidden shadow-lg">
							<div className="card-body">
								<div className="bg-warning300 p-[15px]">
									<div className="float-left">
										<p className="mb-0 text-black">
											Warning Color - Pages Yellow
										</p>
										<p className="mb-0 text-black">
											color-warning
										</p>
										<p className="mb-0 text-black">
											.bg-warning300
										</p>
									</div>
									<div className="float-right text-black">
										0
									</div>
									<div className="clear-both" />
								</div>

								<div className="flex">
									<div className="bg-warning100 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-black">
											-2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-black">
											.bg-warning100
										</span>
									</div>
									<div className="bg-warning200 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-black">
											-1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-black">
											.bg-warning200
										</span>
									</div>
									<div className="bg-warning400 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-black">
											1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-black">
											.bg-warning400
										</span>
									</div>
									<div className="bg-warning500 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-black">
											2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-black">
											.bg-warning500
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Danger Color */}
					<div className="col-span-1">
						<div className="overflow-hidden shadow-lg">
							<div className="card-body">
								<div className="bg-danger300 p-[15px]">
									<div className="float-left">
										<p className="mb-0 text-white">
											Danger Color - Pages Red
										</p>
										<p className="mb-0 text-white">
											color-danger
										</p>
										<p className="mb-0 text-white">
											.bg-danger300
										</p>
									</div>
									<div className="float-right text-white">
										0
									</div>
									<div className="clear-both" />
								</div>

								<div className="flex">
									<div className="bg-danger100 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px]">
											-2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px]">
											.bg-danger100
										</span>
									</div>
									<div className="bg-danger200 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											-1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-danger200
										</span>
									</div>
									<div className="bg-danger400 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-danger400
										</span>
									</div>
									<div className="bg-danger500 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-danger500
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Info Color */}
					<div className="col-span-1">
						<div className="overflow-hidden shadow-lg">
							<div className="card-body">
								<div className="bg-grey300 p-[15px]">
									<div className="float-left">
										<p className="mb-0 text-white">
											Info Color - Pages Grey
										</p>
										<p className="mb-0 text-white">
											color-info
										</p>
										<p className="mb-0 text-white">
											.bg-grey300
										</p>
									</div>
									<div className="float-right text-white">
										0
									</div>
									<div className="clear-both" />
								</div>

								<div className="flex">
									<div className="bg-grey100 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px]">
											-2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px]">
											.bg-grey100
										</span>
									</div>
									<div className="bg-grey200 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											-1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-grey200
										</span>
									</div>
									<div className="bg-grey400 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											1
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-grey400
										</span>
									</div>
									<div className="bg-grey500 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-white">
											2
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-grey500
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Menu Color */}
					<div className="col-span-1">
						<div className="overflow-hidden shadow-lg">
							<div className="card-body">
								<div className="bg-menu300 p-[15px]">
									<div className="float-left">
										<p className="mb-0 text-white">
											Menu Color - Pages Dark Gray
										</p>
										<p className="mb-0 text-white">
											color-menu
										</p>
										<p className="mb-0 text-white">
											.bg-menu300
										</p>
									</div>
									<div className="float-right text-white"></div>
									<div className="clear-both" />
								</div>

								<div className="flex">
									<div className="bg-menu100 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-transparent">
											-
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px]">
											.bg-menu100
										</span>
									</div>
									<div className="bg-menu500 relative flex-1">
										<span className="float-right pb-[35px] pr-[15px] pt-[10px] text-transparent">
											-
										</span>
										<span className="small-text absolute bottom-0 float-left pb-[5px] pl-[10px] text-white">
											.bg-menu500
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-5">
				<h3>GREY SCALE</h3>
				<hr />
				<div className="mt-5 flex">
					<div className="flex-1 bg-contrast-lowest">
						<div className="p-[15px]">
							<div className="float-left">
								<p className="mb-0">color-contrast-lowest</p>
								<p className="mb-0">Background</p>
								<p className="mb-0">.bg-contrast-lowest</p>
							</div>
							<div className="small-text clear-both mt-1 text-[red]">
								100
							</div>
						</div>
					</div>
					<div className="flex-1 bg-contrast-lower">
						<div className="p-[15px]">
							<div>
								<div className="float-left">
									<p className="mb-0">color-contrast-lower</p>
									<p className="mb-0">Background light</p>
									<p className="mb-0">.bg-contrast-lower</p>
								</div>
							</div>
							<div className="small-text clear-both mt-1 text-[red]">
								{' '}
								200
							</div>
						</div>
					</div>
					<div className="flex-1 bg-contrast-low">
						<div className="p-[15px]">
							<div className="float-left">
								<p className="mb-0">color-contrast-low</p>
								<p className="mb-0">Borderds</p>
								<p className="mb-0">.bg-contrast-low</p>
							</div>
							<div className="small-text clear-both mt-1 text-[red]">
								300
							</div>
						</div>
					</div>
					<div className="flex-1 bg-contrast-medium">
						<div className="p-[15px]">
							<div className="float-left">
								<p className="mb-0 text-white">
									color-contrast-medium
								</p>
								<p className="mb-0 text-white">
									Muted/helper text
								</p>
								<p className="mb-0 text-white">
									.bg-contrast-medium
								</p>
							</div>
							<div className="small-text clear-both mt-1 text-[red]">
								400
							</div>
						</div>
					</div>
					<div className="flex-1 bg-contrast-high">
						<div className="p-[15px]">
							<div className="float-left">
								<p className="mb-0 text-white">
									color-contrast-high
								</p>
								<p className="mb-0 text-white">
									Regular paragraphs
								</p>
								<p className="mb-0 text-white">
									.bg-contrast-high
								</p>
							</div>
							<div className="small-text clear-both mt-1 text-[red]">
								500
							</div>
						</div>
					</div>
					<div className="flex-1 bg-contrast-higher">
						<div className="p-[15px]">
							<div className="float-left">
								<p className="mb-0 text-white">
									color-contrast-higher
								</p>
								<p className="mb-0 text-white">Headings</p>
								<p className="mb-0 text-white">
									.bg-contrast-higher
								</p>
							</div>
							<div className="small-text clear-both mt-1 text-[red]">
								600
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default ColorPage

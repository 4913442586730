import React from 'react'

export default function GeneralFooter() {
	return (
		<>
			<div className="main-footer !z-[1111] !flex max-w-[100%] !items-end py-6 !pb-[9.5px] !pt-[33.5px]">
				<span>© 2023. Risk Wise. All Rights Reserved.</span>
				<span>
					Created by:{' '}
					<a href="#" target="_blank" className="text-link">
						ThinkFirm
					</a>
				</span>
			</div>
		</>
	)
}

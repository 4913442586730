import React, { useState } from 'react'
const UploadSignature = ({ onChange }) => {
	const [selected_Image, setSelected_Image] = useState(null)
	const [croopedImage, setCroppedImage] = useState(null)
	const [imageCoardinates, setImageCoardinates] = useState({
		width: '',
		height: ''
	})
	// collect user seletced image
	const handleImageChange = (e) => {
		const file = e.target.files[0]
		if (file) {
			const reader = new FileReader()
			reader.onloadend = () => {
				setSelected_Image(reader.result)
			}
			reader.readAsDataURL(file)
		}
	}
	// create url of selected image
	const HandleCrop = () => {
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')

		if (selected_Image) {
			const img = new Image()
			img.onload = () => {
				const aspectRatio = img.width / img.height

				// Calculate new dimensions to fit within the canvas while maintaining aspect ratio
				let newWidth = canvas.width
				let newHeight = canvas.width / aspectRatio

				if (newHeight > canvas.height) {
					newHeight = canvas.height
					newWidth = canvas.height * aspectRatio
				}

				canvas.width = newWidth
				canvas.height = newHeight

				ctx.clearRect(0, 0, canvas.width, canvas.height)
				ctx.drawImage(img, 0, 0, newWidth, newHeight)

				// Access width and height of the new image
				const newImageWidth = newWidth
				const newImageHeight = newHeight
				setImageCoardinates({
					width: newImageWidth,
					height: newImageHeight
				})

				const dataUrl = canvas.toDataURL()
				// console.log('dataUrl')
				setCroppedImage(dataUrl)
				onChange(dataUrl)
			}
			img.src = selected_Image
		}
	}
	// remove the previous selected image
	const CancelHandler = () => {
		setSelected_Image(null)
		setCroppedImage(null)
	}
	return (
		<>
			<div className="flex min-h-[329px] flex-col justify-between  gap-4 ">
				<div className="width-full  flex h-[250px] items-center justify-center bg-white">
					{selected_Image && (
						<div className="flex h-[150px] w-[190px] items-center justify-center">
							<img
								src={selected_Image}
								alt="selected-image"
								style={{ maxWidth: '100%', maxHeight: '100%' }}
							/>
						</div>
					)}
				</div>
				<div className="flex items-end justify-between gap-1 ">
					<div className="form-group mb-0">
						<div>
							<p className="label !pt-0">Upload Signature</p>
						</div>
						<div>
							<label
								htmlFor="imageInput"
								style={{ cursor: 'pointer' }}
								className="btn btn-primary btn-large  px-3"
							>
								Upload Signature
							</label>
							<input
								type="file"
								id="imageInput"
								onChange={handleImageChange}
								style={{ display: 'none' }}
							/>
						</div>
					</div>
					<div className="flex flex-wrap-reverse gap-[3px] ">
						<button
							className="btn btn-primary btn-large "
							onClick={HandleCrop}
						>
							Crop
						</button>
						<button
							className="btn btn-large btn-default"
							onClick={CancelHandler}
						>
							Clear
						</button>
					</div>
				</div>
			</div>
			{/* {croopedImage && (
        <div >
        <img src={croopedImage} alt="Crop Signature" style={{ width: imageCoardinates.width, height:imageCoardinates.height }} />
        </div>
      )} */}
		</>
	)
}

export default React.memo(UploadSignature)
